import { TabsType } from "src/types";

import { PanelNameType } from "~/utils/accessControl/crudAcl";
import getTabsPathDependent from "~/utils/getTabsPathDependant";

import { labels } from "~/modules/panel-system/vehicle-panel/locale";

type TabTitleKeys = keyof typeof labels;

// all the tabs including create and update
const tabs: TabsType<TabTitleKeys> = {
  generalInfo: {
    id: "generalInfo",
    title: "panel.tab.generalInfo",
  },
  specifications: {
    id: "specifications",
    title: "panel.tab.specifications",
  },
  statusOwner: {
    id: "statusOwner",
    title: "panel.tab.statusOwner",
  },
  driver: {
    id: "driver",
    title: "panel.tab.driver",
  },
};

export const panelConfig: PanelConfigType = {
  id: PanelNameType.VEHICLE,
  name: "panel.title",
  createTabs: [
    tabs.generalInfo,
    tabs.specifications,
    tabs.statusOwner,
    tabs.driver,
  ],
  updateTabs: [
    tabs.generalInfo,
    tabs.specifications,
    tabs.statusOwner,
    tabs.driver,
  ],
};

export const vehicleActionsTabs = getTabsPathDependent(
  panelConfig.createTabs,
  panelConfig.updateTabs
);
export type PanelConfigType = {
  id: PanelNameType;
  name: string;
  createTabs: (typeof tabs)[keyof typeof tabs][];
  updateTabs: (typeof tabs)[keyof typeof tabs][];
};
