import { useCallback, useRef } from "react";

export default function useDebounceCallback(
  callback: (...args: any[]) => void,
  delay: number
) {
  const timeoutRef = useRef<any>(null);

  const debouncedCallback = useCallback(
    (...args: any[]) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [delay, callback]
  );
  return debouncedCallback;
}
