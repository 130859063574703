import { TabsType } from "src/types";

import { PanelNameType } from "~/utils/accessControl/crudAcl";
import getTabsPathDependent from "~/utils/getTabsPathDependant";

import { labels } from "~/modules/panel-system/service-panel/locale";

type TabTitleKeys = keyof typeof labels;

// all the tabs including create and update
const tabs: TabsType<TabTitleKeys> = {
  serviceDetails: {
    id: "serviceDetails",
    title: "panel.tab.serviceDetails",
  },
  operatingZones: {
    id: "operatingZones",
    title: "panel.tab.operatingZones",
  },
  acceptedSchools: {
    id: "acceptedSchools",
    title: "panel.tab.acceptedSchools",
  },
  servicePricing: {
    id: "servicePricing",
    title: "panel.tab.priceConfiguration",
  },
  serviceEligibilities: {
    id: "serviceEligibilities",
    title: "panel.tab.serviceEligibility",
  },
  paymentPlansPeriods: {
    id: "paymentPlansPeriods",
    title: "panel.tab.installmentPlans",
  },
  serviceModifiers: {
    id: "serviceModifiers",
    title: "panel.tab.serviceModifiers",
  },
};

export const panelConfig: PanelConfigType = {
  id: PanelNameType.SERVICE,
  name: "panel.title",
  tabs,
  createTabsDistance: [
    tabs.serviceDetails,
    tabs.servicePricing,
    tabs.paymentPlansPeriods,
    tabs.serviceEligibilities,
  ],
  createTabsZone: [
    tabs.serviceDetails,
    tabs.operatingZones,
    tabs.acceptedSchools,
    tabs.serviceEligibilities,
    tabs.paymentPlansPeriods,
    tabs.serviceModifiers,
  ],
  updateTabsDistance: [
    tabs.serviceDetails,
    tabs.servicePricing,
    tabs.serviceEligibilities,
    tabs.paymentPlansPeriods,
  ],
  updateTabsZone: [
    tabs.serviceDetails,
    tabs.servicePricing,
    tabs.acceptedSchools,
    tabs.paymentPlansPeriods,
    tabs.serviceEligibilities,
    tabs.operatingZones,
    tabs.serviceModifiers,
  ],
};

export const serviceActionsTabs = getTabsPathDependent(
  [...panelConfig.createTabsDistance, ...panelConfig.createTabsZone],
  [...panelConfig.updateTabsDistance, ...panelConfig.updateTabsZone]
);

export type PanelConfigType = {
  id: PanelNameType;
  name: string;
  tabs: typeof tabs;
  createTabsDistance: (typeof tabs)[keyof typeof tabs][];
  createTabsZone: (typeof tabs)[keyof typeof tabs][];
  updateTabsDistance: (typeof tabs)[keyof typeof tabs][];
  updateTabsZone: (typeof tabs)[keyof typeof tabs][];
};
