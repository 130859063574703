import isEqual from "react-fast-compare";
import { useTranslation } from "react-i18next";
import { TOptions } from "i18next";
import isObject from "lodash/isObject";
import memoize from "memoizee";
import { Labels } from "src/types";

import i18n from "~/i18n/i18next";
import { locales } from "~/i18n/settings";

import { dayInMilliSeconds } from "~/utils/constants/dateTime";

const memoizedTranslator = memoize(
  (translate, key, options) => {
    return translate(key, options);
  },
  { max: 1000, maxAge: dayInMilliSeconds, preFetch: true }
);

// This hook to get the translation getter in all the React components.
export const useLocalization = <T>(nameSpace = "core") => {
  // eslint-disable-next-line id-length
  const { t, i18n } = useTranslation(nameSpace);

  const translate = (
    key: T extends true ? T : string,
    options?: TOptions | string
  ) => {
    return memoizedTranslator(t, key, options);
  };

  return { translate, i18n };
};

// This function to get the translation getter in any place not inside react component or hook.
export const translate = <T>(
  key: T extends true ? T : string,
  options?: TOptions | string
) => {
  const caller = i18n ? i18n?.t : () => "";
  return memoizedTranslator(caller, key, options);
};

// This event callback just to make sure that we cleared the cache after every time we change the language
i18n?.on("languageChanged", () => {
  memoizedTranslator.clear();
});

export const getSeparateLanguageLabels = (labels: Labels) => {
  const en: { [x: string]: string } = {};
  const ar: { [x: string]: string } = {};
  if (isObject(labels)) {
    Object.keys(labels).forEach((label) => {
      en[label] = labels[label].en;
      ar[label] = labels[label].ar;
    });
  }
  return { en, ar };
};

export const addNameSpaceResources = (
  nameSpaceKey: string,
  translations: Labels
) => {
  const { en: enTranslation, ar: arTranslation } =
    getSeparateLanguageLabels(translations);
  // NOTE: performance wise, is it worth doing deep equality check between before and after to check if there is any change to the translation labels
  // or should we keep adding new resource every time?
  const prevEnResource = i18n?.getResourceBundle(locales.en, nameSpaceKey);
  const prevArResource = i18n?.getResourceBundle(locales.ar, nameSpaceKey);
  const shouldAddNewEnResource = !isEqual(prevEnResource, enTranslation);
  const shouldAddNewArResource = !isEqual(prevArResource, arTranslation);
  if (shouldAddNewEnResource) {
    i18n?.addResourceBundle(locales.en, `${nameSpaceKey}`, enTranslation);
  }
  if (shouldAddNewArResource) {
    i18n?.addResourceBundle(locales.ar, `${nameSpaceKey}`, arTranslation);
  }
};
