import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";

export const createEmotionCache = (nonce?: string) => {
  return createCache({
    key: "ops-dash-style-ltr",
    nonce: nonce ?? undefined,
    prepend: true,
  });
};

export const createEmotionCacheRtl = (nonce?: string) => {
  return createCache({
    key: "ops-dash-style-rtl",
    stylisPlugins: [rtlPlugin],
    nonce: nonce ?? undefined,
    prepend: true,
  });
};
