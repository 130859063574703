/* eslint-disable @typescript-eslint/naming-convention */
import AccessControl, { PanelNameType } from "~/utils/accessControl/crudAcl";
import AccessControlCreateUpdateTabs from "~/utils/accessControl/modalTabsAcl";
import { panelTabsDependentFields } from "~/utils/accessControl/panelsInterfaceMapper";

import { store } from "~/state/store";

/* import {
  FieldsPathPermissionType,
  panelActionPaths,
  panelTabsDependentFields,
  PermissionsPerPanelAction,
} from "~/utils/accessControl/panelsInterfaceMapper"; */
/* import { store } from "~/state/store";
 */
import { PermissionAction } from "~/gql/main/types.generated";

import {
  GetMyPermissionsQuery,
  useGetMyPermissionsLazyQuery as useGetMyPermissions,
} from "~/core/generated/main/get-permissions.generated";
import { setIsSuperUser } from "~/core/state/coreSlice";

import { PanelNameTypeAcl } from "~/modules/panel-system/base-panel/types";

export type MutationAction = PermissionAction.WRITE | PermissionAction.UPDATE;

const manageAll = "manage.all";

export type PermissionsType = {
  [panel in PanelNameType]?: {
    [action in PermissionAction]?:
      | {
          [field: string]: boolean;
        }
      | boolean;
  };
};

export type PermissionsTabs = {
  [key in PanelNameType]?: {
    [key in MutationAction]?:
      | {
          [tab: string]: boolean;
        }
      | boolean;
  };
};

type PermissionTableNameMapping = keyof typeof PanelNameTypeAcl;

type QueryPermissionsType = GetMyPermissionsQuery["getMyPermissions"];

const inheritedPermissionsMappings: Partial<
  Record<PanelNameType, PermissionTableNameMapping>
> = {
  tripPanel: "TripList",
  vehiclePanel: "FLEET",
};

const getPanelNameFromAclSubject = (
  aclSubject: PermissionTableNameMapping
): PanelNameType => {
  return PanelNameTypeAcl[aclSubject] as unknown as PanelNameType;
};

const inheritPermissions = (
  permissions: GetMyPermissionsQuery["getMyPermissions"],
  mapping: Record<string, string>
) => {
  const newPermissions: GetMyPermissionsQuery["getMyPermissions"] = [];
  permissions.forEach((permission) => {
    const panelName = getPanelNameFromAclSubject(
      permission.subject as PermissionTableNameMapping
    );
    if (panelName in mapping) {
      newPermissions.push({
        ...permission,
        subject: mapping[panelName],
      });
    }
  });
  return newPermissions;
};

/* const getFieldsPaths = (
  // NOTE: this take subject name we fetched from BE
  aclSubject: PermissionTableNameMapping,
  action: PermissionAction,
  superAccess = false,
  frontendPaths: FieldsPathPermissionType
): Record<string, string> => {
  // get panel name by checking mapping of BE subject name and PanelNameType
  const panelName = getPanelNameFromAclSubject(aclSubject);
  if (action === PermissionAction.MANAGE && superAccess) return {};

  return frontendPaths?.[panelName] || {};
}; */

const normalizePermissionData = (data: QueryPermissionsType) => {
  type SortedDataItem = QueryPermissionsType[0] & {
    remainingActions?: string[];
  };
  let allPanels: SortedDataItem[] = [];
  // set isSuperUser flag on redux and initialize with false
  store.dispatch(setIsSuperUser(false));

  const allSubjectIndex = data.findIndex((item) => item.subject === "all");

  if (allSubjectIndex !== -1) {
    // set isSuperUser flag
    store.dispatch(setIsSuperUser(true));
    Object.keys(PanelNameTypeAcl).forEach((panel) => {
      allPanels = allPanels.concat({
        ...data[allSubjectIndex],
        subject: panel,
        actions: [PermissionAction.MANAGE],
      });
    });
  } else {
    // grouped actions based on subject
    const groupedSubjectsActions = data.reduce(
      (acc, item) => {
        const newAcc = { ...acc };
        if (!acc[item.subject]) {
          newAcc[item.subject] = item;
        } else {
          const newActions = [
            ...(acc[item.subject].actions || []),
            ...(item.actions || []),
          ];
          newAcc[item.subject] = {
            ...acc[item.subject],
            actions: newActions,
          };
        }
        return newAcc;
      },
      {} as Record<string, SortedDataItem>
    );
    allPanels = Object.values(groupedSubjectsActions);
  }
  return allPanels;
};

const generateCrudPermissions = (
  actions: PermissionAction[] | undefined | null,
  isSuper: boolean
) => {
  // exclude manage action from the CRUD
  const allActions = Object.values(PermissionAction).filter(
    (action) => action !== PermissionAction.MANAGE
  );
  const isManageAction = actions?.find(
    (action) => action === PermissionAction.MANAGE
  );
  const isViewAction = actions?.find(
    (action) => action === PermissionAction.VIEW
  );
  const crudActions = isManageAction
    ? [
        PermissionAction.ARCHIVE,
        PermissionAction.READ,
        PermissionAction.UPDATE,
        PermissionAction.WRITE,
        PermissionAction.DELETE,
        PermissionAction.BULKUPDATE,
        (isSuper || isViewAction) && PermissionAction.VIEW,
      ]
    : actions;
  const permissionsActions = allActions.reduce(
    (result, currentAction) => ({
      ...result,
      [currentAction]: crudActions?.includes(currentAction as PermissionAction),
    }),
    {}
  );
  return permissionsActions;
};

const generateTabsPermissions = (
  crudPermissions: PermissionsType
): PermissionsTabs => {
  const tabPermissions: PermissionsTabs = {};
  Object.keys(crudPermissions).forEach((panel) => {
    const panelName = panel as PanelNameType;
    tabPermissions[panelName] = {
      [PermissionAction.WRITE]: crudPermissions[panelName]?.[
        PermissionAction.WRITE
      ]
        ? panelTabsDependentFields[panelName]?.write
        : false,
      [PermissionAction.UPDATE]: crudPermissions[panelName]?.[
        PermissionAction.UPDATE
      ]
        ? panelTabsDependentFields[panelName]?.update
        : false,
    };
  });
  return tabPermissions;
};
export const generateMyPermission = (
  queryPermissions: QueryPermissionsType
) => {
  let crudPermissions: PermissionsType = {};
  // loop on BE permissions
  const normalizedPermissions = normalizePermissionData(queryPermissions);

  normalizedPermissions?.forEach((panelPermissions) => {
    // get panel name by checking mapping of BE subject name and PanelNameType
    const panelName = getPanelNameFromAclSubject(
      panelPermissions.subject as PermissionTableNameMapping
    );
    // bail out if panelName was not defined
    if (!panelName) return;
    const isSuper = panelPermissions.name === manageAll;
    const permissions = generateCrudPermissions(
      panelPermissions.actions,
      isSuper
    );
    crudPermissions = {
      ...crudPermissions,
      [panelName]: {
        ...(crudPermissions?.[panelName] || {}),
        ...(permissions || {}),
      },
    };
  });
  const tabsPermissions = generateTabsPermissions(crudPermissions);
  AccessControl.setPermissions(crudPermissions);
  AccessControlCreateUpdateTabs.setPermissions(tabsPermissions);
};

const useGenerateMyPermission = () => {
  const [getMyPermissions, { loading: isLoading }] = useGetMyPermissions({
    fetchPolicy: "no-cache",
    nextFetchPolicy: "standby",
  });
  const generateUserPermissions = () => {
    getMyPermissions({
      onCompleted: (data) => {
        const permissions = data?.getMyPermissions;
        /**
         * Add tripList to permissions with the same permissions as trip.
         * This is a workaround to inherit permissions from Trip to TripList without BE changes
         */
        // Add fleet to permissions with the same permissions as vehicle panel as done on the BE

        const inheritedPermissions = inheritPermissions(
          permissions,
          inheritedPermissionsMappings
        );
        generateMyPermission([...permissions, ...inheritedPermissions]);
      },
    });
  };
  return { generateUserPermissions, isLoading };
};

export default useGenerateMyPermission;
