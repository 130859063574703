// src/App.tsx
import { FC, Suspense } from "react";
import { useRoutes } from "react-router-dom";
import { wrapUseRoutes } from "@sentry/react";

import { useLocalization } from "~/utils/i18n/localization";

import ErrorBoundary from "~/core/components/ErrorBoundary";
import PageLoader from "~/core/components/PageLoader";
import Providers from "~/core/Layouts/Providers/Providers";

import "~/config/sentry.config";

// eslint-disable-next-line import/no-unresolved
import routes from "~react-pages";

const useSentryRoutes = wrapUseRoutes(useRoutes);

const App: FC = () => {
  const routing = useSentryRoutes(routes);
  const nonce = undefined;
  const { i18n } = useLocalization();

  // Load the appropriate font based on the language
  if (i18n?.language === "ar") {
    import("@fontsource/almarai/300.css");
    import("@fontsource/almarai/400.css");
    import("@fontsource/almarai/700.css");
  } else {
    import("@fontsource/asap/300.css");
    import("@fontsource/asap/400.css");
    import("@fontsource/asap/500.css");
    import("@fontsource/asap/700.css");
  }

  return (
    <Providers nonce={nonce}>
      <Suspense fallback={<PageLoader />}>
        <ErrorBoundary>{routing}</ErrorBoundary>
      </Suspense>
    </Providers>
  );
};

export default App;
