import { graphqlMainService } from "~/config/appConfig";

import { getEnvironment } from "~/utils/environment";
import { composeUrl } from "~/utils/url/composeUrl";

import { clientFactory } from "~/gql/clientFactory";

export const httpUrl = composeUrl({
  hostname: graphqlMainService.hostname,
  protocol: graphqlMainService.protocolHttp,
  port: graphqlMainService.port,
  apiPrefix: graphqlMainService.apiPrefix,
});

export const wsUrl = composeUrl({
  hostname: graphqlMainService.hostname,
  protocol: graphqlMainService.protocolWs,
  port: graphqlMainService.port,
  apiPrefix: graphqlMainService.apiPrefix,
});

export const client = clientFactory({ httpUrl, wsUrl, disableAuth: false });
export const clientNoAuth = clientFactory({
  httpUrl,
  wsUrl,
  disableAuth: true,
  connectToDevTools: getEnvironment().DEV,
});
