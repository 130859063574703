import { TabsType } from "src/types";

import { PanelNameType } from "~/utils/accessControl/crudAcl";
import getTabsPathDependent from "~/utils/getTabsPathDependant";

import { labels } from "~/modules/panel-system/guardians-panel/locale";

type TabTitleKeys = keyof typeof labels;

// all the tabs including create and update
const tabs: TabsType<TabTitleKeys> = {
  parentInfo: {
    id: "parentInfo",
    title: "panel.tab.parentInfo",
  },
  creditNote: {
    id: "creditNote",
    title: "panel.tab.creditNote",
  },
  cashOutRequests: {
    id: "cashOutRequests",
    title: "panel.tab.cashOutRequests",
  },
};

export const panelConfig: PanelConfigType = {
  id: PanelNameType.GUARDIANS,
  name: "panel.title",
  createTabs: [],
  updateTabs: [tabs.parentInfo, tabs.creditNote, tabs.cashOutRequests],
};

export const guardiansActionsTabs = getTabsPathDependent(
  panelConfig.createTabs,
  panelConfig.updateTabs
);

export type PanelConfigType = {
  id: PanelNameType;
  name: string;
  createTabs: (typeof tabs)[keyof typeof tabs][];
  updateTabs: (typeof tabs)[keyof typeof tabs][];
};
