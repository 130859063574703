import { TabsType } from "src/types";

import { PanelNameType } from "~/utils/accessControl/crudAcl";
import getTabsPathDependent from "~/utils/getTabsPathDependant";

import { labels } from "~/modules/panel-system/school-registration-panel/locale";

type TabTitleKeys = keyof typeof labels;

// all the tabs including create and update
const tabs: TabsType<TabTitleKeys> = {
  eligibilityCheck: {
    id: "eligibilityCheck",
    title: "panel.tab.eligibilityCheck",
  },
  studentInfo: {
    id: "studentInfo",
    title: "panel.tab.studentInfo",
  },
  status: {
    id: "status",
    title: "panel.tab.status",
  },
  school: {
    id: "school",
    title: "panel.tab.school",
  },
  serviceInfo: {
    id: "serviceInfo",
    title: "panel.tab.serviceInfo",
  },
  plan: {
    id: "plan",
    title: "panel.tab.plan",
  },
  payment: {
    id: "payment",
    title: "panel.tab.payment",
  },
};

export const panelConfig: PanelConfigType = {
  id: PanelNameType.SCHOOL_REGISTRATION,
  name: "panel.title",
  createTabs: [
    tabs.eligibilityCheck,
    tabs.studentInfo,
    tabs.status,
    tabs.school,
    tabs.serviceInfo,
    tabs.plan,
    tabs.payment,
  ],
  updateTabs: [
    tabs.studentInfo,
    tabs.status,
    tabs.school,
    tabs.serviceInfo,
    tabs.plan,
    tabs.payment,
  ],
  tabs,
  settings: {
    maxBulkUpdateCount: 50,
    // Distance from one point to another to consider them as the same location
    homeLocationComparisonRange: 100,
  },
};

export const schoolRegistrationActionsTabs = getTabsPathDependent(
  panelConfig.createTabs,
  panelConfig.updateTabs
);

export type PanelConfigType = {
  id: PanelNameType;
  name: string;
  createTabs: (typeof tabs)[keyof typeof tabs][];
  updateTabs: (typeof tabs)[keyof typeof tabs][];
  tabs: typeof tabs;
  settings: {
    maxBulkUpdateCount: number;
    homeLocationComparisonRange: number;
  };
};
