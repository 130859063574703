import { createTheme, Theme, ThemeOptions } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";

// Create a theme instance.
export default function theme(theme: Theme) {
  // Include the overridden styles only. No need to destructure the outer theme
  const styles: Partial<ThemeOptions> = {
    components: {
      // Add custom styles here to override the global theme
    },
  };

  const mergedStyles = deepmerge(styles, theme);

  return createTheme(mergedStyles);
}
