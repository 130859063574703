import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "~/state/store";

import {
  // BasePanelConfig,
  // BasePanelsConfig,
  // BasePanelState,
  // PanelEvent,
  // PanelNameType,
  // PanelsEvents,
  // PanelsSubscriptions,
  // PanelSubscription,
  WorkspaceBasePanelConfig,
  WorkspaceBasePanelState,
} from "~/modules/panel-system/base-panel/types";

const REDUCER_NAME = "workspacePanels";

export const workspaceBasePanelSlice = createSlice({
  name: "workspaceBasePanels",
  initialState: {
    // testPanel: {
    //   panels: {} as BasePanelsConfig,
    //   panelsEvents: {} as PanelsEvents,
    //   subscriptions: {} as PanelsSubscriptions,
    // } as BasePanelState,
  } as WorkspaceBasePanelState,
  reducers: {
    // mange base panel config info
    // addPanel: (state, action: PayloadAction<BasePanelConfig>) => {
    //   // eslint-disable-next-line no-param-reassign
    //   state.panels[action.payload.id] = {
    //     ...state.panels[action.payload.id],
    //     ...action.payload,
    //     id: action.payload.id,
    //   };
    // },
    // removePanel: (state, action: PayloadAction<BasePanelConfig>) => {
    //   // eslint-disable-next-line no-param-reassign
    //   state.panels[action.payload.id] = {} as BasePanelConfig;
    //   console.log(action.payload.id);
    // },
    // editPanel: (state, action: PayloadAction<BasePanelConfig>) => {
    //   // eslint-disable-next-line no-param-reassign
    //   state.panels[action.payload.id] = {
    //     ...state.panels[action.payload.id],
    //     ...action.payload,
    //     id: action.payload.id,
    //   };
    // },

    // manage panel events
    // fireEvent: (state, action: PayloadAction<PanelEvent>) => {
    //   // generate uuid4. redux complains if I use uuid4
    //   const eventId = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    //     /[xy]/g,
    //     function (char) {
    //       const rand = (Math.random() * 16) | 0;
    //       const value = char === "x" ? rand : (rand & 0x3) | 0x8;
    //       return value.toString(16);
    //     }
    //   );

    //   // eslint-disable-next-line no-param-reassign
    //   state.panelsEvents[action.payload.panelId] = {
    //     ...action.payload,
    //     eventId,
    //     date: new Date().toString(),
    //   };
    // },
    // subscribe: (
    //   state,
    //   action: PayloadAction<{
    //     subscriberPanelId: string;
    //     subscribePanelId: string;
    //   }>
    // ) => {
    //   // eslint-disable-next-line no-param-reassign
    //   state.subscriptions[action.payload.subscriberPanelId] = {
    //     ...state.subscriptions[action.payload.subscriberPanelId],
    //     [action.payload.subscribePanelId]: {
    //       panelId: action.payload.subscribePanelId,
    //       subscribedAt: new Date().toString(),
    //       isActive: true,
    //       lastEventId: "",
    //     } as PanelSubscription,
    //   };
    // },
    // unsubscribe: (
    //   state,
    //   action: PayloadAction<{
    //     subscriberPanelId: string;
    //     subscribePanelId: string;
    //   }>
    // ) => {
    //   // eslint-disable-next-line no-param-reassign
    //   state.subscriptions[action.payload.subscriberPanelId] = {
    //     ...state.subscriptions[action.payload.subscriberPanelId],
    //     [action.payload.subscribePanelId]: {
    //       panelId: action.payload.subscribePanelId,
    //       isActive: false,
    //       lastEventId: "",
    //     } as PanelSubscription,
    //   };
    // },
    // updateSubLastEventId: (
    //   state,
    //   action: PayloadAction<{
    //     subscriberPanelId: string;
    //     subscribePanelId: string;
    //     eventId?: string;
    //   }>
    // ) => {
    //   // eslint-disable-next-line no-param-reassign
    //   state.subscriptions[action.payload.subscriberPanelId] = {
    //     ...state.subscriptions[action.payload.subscriberPanelId],
    //     [action.payload.subscribePanelId]: {
    //       ...state.subscriptions[action.payload.subscriberPanelId][
    //         action.payload.subscribePanelId
    //       ],
    //       lastEventId: action.payload.eventId,
    //     } as PanelSubscription,
    //   };
    // },

    // manage workspace panels
    createWorkspacePanel: (
      state,
      action: PayloadAction<WorkspaceBasePanelConfig>
    ) => {
      const { workspaceId, id: panelId, ...restPayload } = action.payload;

      // eslint-disable-next-line no-param-reassign
      state[workspaceId] = {
        ...state[workspaceId],
        panels: {
          ...(state[workspaceId]?.panels || {}),
          [panelId]: {
            ...(state[workspaceId]?.panels?.[panelId] || {}),
            ...restPayload,
            id: panelId,
          },
        },
      };
    },
    removeWorkspacePanel: (
      state,
      action: PayloadAction<WorkspaceBasePanelConfig>
    ) => {
      const { workspaceId, id: panelId } = action.payload;
      const workspacePanels = { ...state[workspaceId] };

      if (workspacePanels.panels?.[panelId])
        delete workspacePanels.panels[panelId];

      // eslint-disable-next-line no-param-reassign
      state[workspaceId] = workspacePanels;
    },
    updateWorkspacePanel: (
      state,
      action: PayloadAction<WorkspaceBasePanelConfig>
    ) => {
      const { workspaceId, id: panelId, ...restPayload } = action.payload;

      // eslint-disable-next-line no-param-reassign
      state[workspaceId] = {
        ...state[workspaceId],
        panels: {
          ...state[workspaceId].panels,
          [panelId]: {
            ...state[workspaceId].panels[panelId],
            ...restPayload,
            id: panelId,
          },
        },
      };
    },

    // manage workspaces
    removeWorkspacePanels: (
      state,
      action: PayloadAction<Omit<WorkspaceBasePanelConfig, "id">>
    ) => {
      const { workspaceId } = action.payload;
      const workspacePanels = { ...state };

      if (workspacePanels[workspaceId]) delete workspacePanels[workspaceId];

      // console.log({
      //   state: { ...state },
      //   workspacePanels,
      //   workspaceId,
      // });
      // eslint-disable-next-line no-param-reassign
      return workspacePanels;
    },
  },
});

export const {
  createWorkspacePanel,
  removeWorkspacePanel,
  updateWorkspacePanel,
  removeWorkspacePanels,
} = workspaceBasePanelSlice.actions;

export const selectWorkspacePanels =
  (workspaceId: string | undefined) => (state: RootState) => {
    if (workspaceId) return state?.[REDUCER_NAME]?.[workspaceId]?.panels;
  };

const reducer = { [REDUCER_NAME]: workspaceBasePanelSlice.reducer };

export default reducer;
