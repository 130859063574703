import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { setCurrentUserData } from "~/utils/accessControl/currentUser";
import tokenExpiration from "~/utils/accessControl/tokenExpiration";
import useGenerateMyPermission from "~/utils/accessControl/userPermissions";
import { configureSentryUser } from "~/utils/sentry";
import { setCookie } from "~/utils/storage/cookiesHelpers";

import { useAppDispatch } from "~/state/hooks";

// eslint-disable-next-line import/no-unresolved
import routes from "~/routes/main";

import Image from "~/core/components/Image";
import {
  useWhoAmILazyQuery as useGetLazyQuery,
  WhoAmIQuery,
} from "~/core/generated/main/get-one.generated";
import useCopy from "~/core/locale";
import { setAccessToken } from "~/core/state/coreSlice";

import GoogleSignInButton from "~/modules/login/chunks/GoogleSignInButton";
import {
  LoginWithGoogleMutation,
  useLoginWithGoogleMutation,
} from "~/modules/login/generated/main/login-with-google-mutation.generated";

import EmushrifLogo from "~/assets/emushrifLogo.svg";

const GoogleLoginForm = () => {
  const { translate } = useCopy();
  const [googleClientId, setGoogleClientId] = useState<string | undefined>();
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [getWhoAmI] = useGetLazyQuery({
    fetchPolicy: "no-cache",
    nextFetchPolicy: "standby",
  });

  const getUserDetails = () => {
    getWhoAmI({
      onCompleted: (data: WhoAmIQuery) => {
        setCurrentUserData(dispatch, data);

        const { email, id } = data.whoAmI;
        configureSentryUser(email, id);

        navigate(routes.panels);
      },
      onError(error: any) {
        setIsError(true);
        setErrorMessage(error.message);
      },
    });
  };
  const { generateUserPermissions } = useGenerateMyPermission();

  const [createJwtMutation] = useLoginWithGoogleMutation({
    variables: {
      loginWithGoogleInput: {
        idToken: googleClientId ?? "",
      },
    },
    onCompleted(data: LoginWithGoogleMutation) {
      const { accessToken, refreshToken } = data.loginWithGoogle.jwtObj;
      const refreshExp = tokenExpiration(refreshToken);
      setCookie("refreshToken", refreshToken, { expires: refreshExp });
      dispatch(setAccessToken(accessToken));
      generateUserPermissions();
      getUserDetails();
    },
    onError(error: any) {
      setIsError(true);
      setErrorMessage(error.message);
    },
  });

  const setGoogleClientIdHandler = (googleClientId: string | undefined) => {
    setGoogleClientId(googleClientId);
    createJwtMutation();
  };
  return (
    <Box>
      <Paper
        sx={{
          width: 520,
          paddingX: 6.3,
          paddingY: 7.5,
          borderRadius: 6,
          boxShadow:
            "0px 5px 6px 0px rgba(173, 184, 204, 0.2), 0px 3px 16px 0px rgba(173, 184, 204, 0.12),0px 9px 12px 0px rgba(173, 184, 204, 0.14)",
          "& > *": {
            paddingBottom: 4.4,
          },
        }}
      >
        <Box>
          <Image
            src={EmushrifLogo}
            alt="eMushrif logo"
            width={77}
            height={78}
          />
        </Box>
        <Box>
          <Typography
            color="#3E414F"
            fontSize={32}
            fontWeight={500}
            lineHeight="37px"
          >
            {translate("label.letsGetStarted")}
          </Typography>
          <Typography variant="body1" color="#5B5E6F">
            {translate("label.loginWithEmushrifEmailAccount")}
          </Typography>
        </Box>
        {isError && (
          <Alert
            sx={{
              marginBottom: (theme) => theme.spacing(1.5),
            }}
            onClose={() => setIsError(false)}
            severity="error"
          >
            {errorMessage}
          </Alert>
        )}
        {!googleClientId || isError ? (
          <GoogleSignInButton
            setGoogleClientIdHandler={setGoogleClientIdHandler}
          />
        ) : (
          <Typography variant="body1" color="#5B5E6F">
            {translate("label.loading")}
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default GoogleLoginForm;
