import { CreateUpdateTabsType } from "~/utils/accessControl/modalTabsAcl";
import { PermissionsType } from "~/utils/accessControl/userPermissions";

import { WhoAmIQuery } from "~/core/generated/main/get-one.generated";
// declaring the types for our state
type UserState = {
  currentUser: {
    accessToken: string;
    data: WhoAmIQuery["whoAmI"] | undefined;
    roles: [] | undefined;
    aclPermissions?: PermissionsType;
    aclPermissionsTabs?: CreateUpdateTabsType;
    isSuperUser?: boolean;
  };
};
const initialState: UserState = {
  currentUser: {
    accessToken: "",
    data: undefined,
    roles: undefined,
    aclPermissions: undefined,
    aclPermissionsTabs: undefined,
  },
};
export default initialState;
