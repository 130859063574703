/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "~/state/store";

import initialState from "~/core/state/coreSlice/initState";

export const coreSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser.data = action.payload;
    },
    removeCurrentUser: (state) => {
      state.currentUser.data = undefined;
    },
    setRoles: (state, action) => {
      state.currentUser.roles = action.payload;
    },
    removeRoles: (state) => {
      state.currentUser.roles = undefined;
    },
    setAccessToken: (state, action) => {
      state.currentUser.accessToken = action.payload;
    },
    removeAccessToken: (state) => {
      state.currentUser.accessToken = "";
    },
    setAclPermissionsTabs: (state, action) => {
      state.currentUser.aclPermissionsTabs = action.payload;
    },
    removeAclPermissionsTabs: (state) => {
      state.currentUser.aclPermissionsTabs = undefined;
    },
    setAclPermissions: (state, action) => {
      state.currentUser.aclPermissions = action.payload;
    },
    removeAclPermissions: (state) => {
      state.currentUser.aclPermissions = undefined;
    },
    setIsSuperUser: (state, action) => {
      state.currentUser.isSuperUser = action.payload;
    },
  },
});

export const getAccessToken = (state: RootState) =>
  state?.user?.currentUser?.accessToken;

export const getAclPermissionsTabs = (state: RootState) =>
  state?.user?.currentUser?.aclPermissionsTabs;

export const getAclPermissions = (state: RootState) =>
  state?.user?.currentUser?.aclPermissions;
export const getCurrentUser = (state: RootState) =>
  state?.user?.currentUser?.data;

export const getCurrentUserRoles = (state: RootState) =>
  state?.user?.currentUser?.roles as string[];

export const getIsSuperUser = (state: RootState) =>
  state?.user?.currentUser?.isSuperUser as boolean;

export const {
  setCurrentUser,
  removeCurrentUser,
  setAccessToken,
  removeAccessToken,
  setRoles,
  removeRoles,
  setAclPermissions,
  setAclPermissionsTabs,
  removeAclPermissionsTabs,
  removeAclPermissions,
  setIsSuperUser,
} = coreSlice.actions;

// exporting the reducer here, as we need to add this to the store
export default coreSlice.reducer;
