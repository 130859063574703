import { useEffect, useState } from "react";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const LinearProgressComp = ({
  isError,
  intervalTime,
}: {
  isError: boolean;
  intervalTime: number;
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        return oldProgress >= 100 ? 100 : oldProgress + 10;
      });
    }, intervalTime);
    return () => {
      clearInterval(timer);
    };
  }, [intervalTime]);

  return (
    <LinearProgress
      sx={{
        backgroundColor: "white",
        borderRadius: "10px",
        [`& .${linearProgressClasses.barColorPrimary}`]: {
          backgroundColor: isError ? "error.dark" : "primary.medium",
        },
      }}
      variant="determinate"
      value={progress}
    />
  );
};

export default LinearProgressComp;
