/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "~/state/store";

import initialState from "~/modules/login-provider/state/otpConfigsSlice/initState";

const REDUCER_NAME = "loginProvider";

export const otpConfigsSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setLoginAttemptData: (state, action) => {
      state.loginAttemptData = action.payload;
    },
  },
});

export const getLoginAttemptData = (state: RootState) =>
  state?.[REDUCER_NAME]?.loginAttemptData || initialState.loginAttemptData;

export const { setLoginAttemptData } = otpConfigsSlice.actions;

const reducer = { [REDUCER_NAME]: otpConfigsSlice.reducer };

export default reducer;
