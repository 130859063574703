/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "~/state/store";

import { VehicleSafetyEvent } from "~/gql/main/types.generated";

import initialState from "~/core/state/workspaceSlice/initState";

const REDUCER_NAME = "workspace";

export const workspaceSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {
    togglePopup: (state) => {
      state.notificationCentre.shouldShowNotificationAlerts =
        !state.notificationCentre.shouldShowNotificationAlerts;
    },
    createWorkspace: (state, action) => {
      state.pages = {
        ...state.pages,
        [action.payload.id]: {
          ...action.payload,
        },
      };
    },
    updateWorkspace: (state, action) => {
      const workspaces = { ...state.pages };

      workspaces[action.payload.id] = {
        ...action.payload,
        id: action.payload.id,
      };

      state.pages = workspaces;
    },
    removeWorkspace: (state, action) => {
      const workspaces = { ...state.pages };
      // console.log({ workspaces, payload: action.payload });

      if (workspaces?.[action.payload.id]) delete workspaces[action.payload.id];

      state.pages = workspaces;
    },
    updateNotificationAlerts: (state, action) => {
      if (action.payload.items.length === 0) return;

      const allAlertIds = action.payload.items.map(
        (item: VehicleSafetyEvent) => item.id
      );

      const readNotifications =
        state.notificationCentre && state.notificationCentre.readNotifications
          ? { ...state.notificationCentre.readNotifications }
          : { ...initialState.notificationCentre.readNotifications };

      // filter out the Read alerts which are not part of the ALL alerts
      Object.keys(readNotifications).forEach((id) => {
        if (!allAlertIds.includes(id))
          delete readNotifications[id as keyof typeof readNotifications];
      });

      state.notificationCentre.readNotifications = readNotifications;
    },
    readNotificationAlerts: (state, action) => {
      const readNotifications =
        state.notificationCentre && state.notificationCentre.readNotifications
          ? { ...state.notificationCentre.readNotifications }
          : { ...initialState.notificationCentre.readNotifications };

      // Add alert id to the read notifications if it wasn't already there
      if (!readNotifications[action.payload.alertItem.id]) {
        readNotifications[action.payload.alertItem.id] = Date.now();

        state.notificationCentre.readNotifications = readNotifications;
      }
    },
    readAllNotificationAlerts: (state, action) => {
      const allAlertIds = action.payload.items.map(
        (item: VehicleSafetyEvent) => item.id
      );
      const readNotifications: { [key: string]: number } = {};

      // Add all alerts from the ALL tab to be in the Read tab
      allAlertIds.forEach((id: string) => {
        readNotifications[id] = Date.now();
      });

      state.notificationCentre.readNotifications = readNotifications;
    },
  },
});

export const {
  createWorkspace,
  removeWorkspace,
  updateWorkspace,
  updateNotificationAlerts,
  readNotificationAlerts,
  readAllNotificationAlerts,
  togglePopup,
} = workspaceSlice.actions;

export const selectWorkspacePages = (state: RootState) =>
  state?.[REDUCER_NAME]?.pages || {};

export const selectWorkSpaceReadNotifications = (state: RootState) =>
  state?.[REDUCER_NAME]?.notificationCentre.readNotifications || {};

export const selectWorkSpacePopupState = (state: RootState) =>
  state?.[REDUCER_NAME]?.notificationCentre.shouldShowNotificationAlerts;

const reducer = { [REDUCER_NAME]: workspaceSlice.reducer };

// exporting the reducer here, as we need to add this to the store
export default reducer;
