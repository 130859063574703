import LinearProgress from "@mui/material/LinearProgress";
import ThemeProvider from "@mui/system/ThemeProvider";

import theme from "~/styles/theme";

const PageLoader = () => {
  const customTheme = theme({});

  return (
    <ThemeProvider theme={customTheme}>
      <LinearProgress color="primary" />
    </ThemeProvider>
  );
};

export default PageLoader;
