export const utilityObject = {
  rowFlex: {
    display: "flex",
    flexDirection: "row",
  },
  columnFlex: {
    display: "flex",
    flexDirection: "column",
  },
  rowFlexCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  rowTopCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  rowFlexTopEnd: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  columnFlexTopCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "start",
  },
  columnFlexTopEnd: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  columnFlexCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
} as const;
