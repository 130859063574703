// This component is a copy of the FeedbackMessage component.
import { forwardRef } from "react";
import Alert, { alertClasses } from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { paperClasses } from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { closeSnackbar, CustomContentProps, SnackbarContent } from "notistack";

import { useLocalization } from "~/utils/i18n/localization";

import LinearProgressComp from "~/core/components/FeedBackSnackbarNotistack/components/LinearProgressComp";
import Image, { StaticImageData } from "~/core/components/Image";

import ErrorFeedbackMessage from "~/assets/errorFeedbackMessage.svg";
import SuccessFeedbackMessage from "~/assets/successFeedbackMessage.svg";

interface FeedbackMessageProps extends CustomContentProps {
  isError: boolean;
  successIcon?: string | StaticImageData;
  message?: string;
  className?: string;
}

const IMAGE_HEIGHT = 63;
const IMAGE_WIDTH = 63;
const AUTO_HIDE_DURATION = 6000;

// eslint-disable-next-line react/display-name
const FeedbackSnackbarNotistack = forwardRef<
  HTMLDivElement,
  FeedbackMessageProps
>((props, ref) => {
  const { id, isError, successIcon, message, className } = props;

  const { translate } = useLocalization();

  return (
    <SnackbarContent ref={ref} role="alert" className={className}>
      <Box
        sx={{
          [`& .${paperClasses.root}`]: {
            borderRadius: 2,
            backgroundColor: "background.default",
            color: isError ? "error.dark" : "#141922",
            alignItems: "center",
          },
        }}
      >
        <Alert
          onClose={() => closeSnackbar(id)}
          severity={isError ? "error" : "success"}
          iconMapping={{
            success: (
              <Image
                src={successIcon || SuccessFeedbackMessage}
                alt="success icon"
                width={IMAGE_WIDTH}
                height={IMAGE_HEIGHT}
                style={{
                  height: "auto",
                }}
              />
            ),
            error: (
              <Image
                src={ErrorFeedbackMessage}
                alt="error icon"
                width={IMAGE_WIDTH}
                height={IMAGE_HEIGHT}
                style={{
                  height: "auto",
                }}
              />
            ),
          }}
          elevation={6}
          sx={{
            [`& .${alertClasses.icon}`]: {
              height: IMAGE_HEIGHT,
            },
          }}
        >
          <Typography variant="body2">
            {isError && !message
              ? translate("label.feedback.connectionErrorOccurred")
              : message}
          </Typography>
        </Alert>
        <Box
          sx={{
            width: "99%",
            position: "relative",
            bottom: "4px",
          }}
        >
          <LinearProgressComp
            isError={isError}
            intervalTime={AUTO_HIDE_DURATION / 10 - 50}
          />
        </Box>
      </Box>
    </SnackbarContent>
  );
});

export default FeedbackSnackbarNotistack;
