import { TabsType } from "src/types";

import { PanelNameType } from "~/utils/accessControl/crudAcl";
import { getEnvironment } from "~/utils/environment";
import getTabsPathDependent from "~/utils/getTabsPathDependant";

import { labels } from "~/modules/panel-system/trip-panel/locale";

const isInDev = getEnvironment().DEV;

type TabTitleKeys = keyof typeof labels;

// all the tabs including create and update
const tabs: TabsType<TabTitleKeys> = {
  // generalInfo: {
  //   id: "generalInfo",
  //   title: "panel.tab.generalInfo",
  // },
  assignments: {
    id: "assignments",
    title: "panel.tab.assignments",
  },
};

export const panelConfig: PanelConfigType = {
  id: PanelNameType.TRIP,
  name: "panel.title",
  createTabs: [],
  // updateTabs: [tabs.generalInfo, tabs.assignments],
  updateTabs: [tabs.assignments],
  settings: {
    realtimeKeepAliveInterval: 1000 * 50, // In seconds
    maxFiltersShown: 3,
    showRemainingFilters: false,
    maxSchoolStopStudent: 3, // number of students avatar shown
    // Override default rows per page due to the row grouping
    rowsPerPageOptions: [...(isInDev ? [1, 2] : []), 20, 50, 100, 150],
    getTripsPollInterval: 20000,
    perPageLimit: 100,
  },
};

export const tripActionsTabs = getTabsPathDependent(
  panelConfig.createTabs,
  panelConfig.updateTabs
);

export type PanelConfigType = {
  id: PanelNameType;
  name: string;
  createTabs: (typeof tabs)[keyof typeof tabs][];
  updateTabs: (typeof tabs)[keyof typeof tabs][];
  settings: {
    realtimeKeepAliveInterval: number;
    maxFiltersShown: number;
    showRemainingFilters: boolean;
    maxSchoolStopStudent: number;
    rowsPerPageOptions: number[];
    getTripsPollInterval: number;
    perPageLimit: number;
    mapInternalId?: string;
  };
};
