import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import { GridCloseIcon } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
import { ApolloProvider } from "@apollo/client";
import { CacheProvider } from "@emotion/react";
import { APIProvider as MapProvider } from "@vis.gl/react-google-maps";
import { closeSnackbar, SnackbarProvider } from "notistack";
import { PersistGate } from "redux-persist/integration/react";

import appConfig, { settings } from "~/config/appConfig";

import { useLocalization } from "~/utils/i18n/localization";

import { persistor, store } from "~/state/store";

import { client } from "~/gql/main/apolloClient";

import {
  createEmotionCache,
  createEmotionCacheRtl,
} from "~/styles/createEmotionCache";

import FeedbackSnackbar from "~/core/components/FeedBackSnackbarNotistack";
import ForceUpdate from "~/core/components/ForceUpdate";
import Image, { StaticImageData } from "~/core/components/Image";
import NetworkStatusDetection from "~/core/components/NetworkStatusDetection";
import CustomThemeProvider from "~/core/Layouts/Providers/ThemeProvider";

import ErrorFeedbackMessage from "~/assets/errorFeedbackMessage.svg";
import SuccessFeedbackMessage from "~/assets/successFeedbackMessage.svg";

import "~/styles/globals.css";

LicenseInfo.setLicenseKey(appConfig.keys.muiXProLicense);

declare module "notistack" {
  interface VariantOverrides {
    // "extra" props it takes in options of `enqueueSnackbar`
    feedbackComp: {
      isError: boolean;
      successIcon?: string | StaticImageData;
      message?: string;
      className?: string;
    };
  }
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
const clientSideEmotionCacheRtl = createEmotionCacheRtl();

const Providers = ({
  children,
}: {
  children: React.ReactNode;
  nonce: string | undefined;
}) => {
  const { i18n } = useLocalization();

  const IMAGE_HEIGHT = 45;
  const IMAGE_WIDTH = 45;
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CacheProvider
          value={
            i18n?.dir() === "rtl"
              ? clientSideEmotionCacheRtl
              : clientSideEmotionCache
          }
        >
          <MapProvider apiKey={appConfig.keys.googleMapsApi}>
            <CustomThemeProvider>
              <SnackbarProvider
                // className="notiStackSnackbar"
                maxSnack={3}
                Components={{
                  feedbackComp: FeedbackSnackbar,
                }}
                variant="feedbackComp"
                autoHideDuration={settings.defaultSnackBarAutoHideDuration}
                action={(key) => (
                  <IconButton
                    onClick={() => closeSnackbar(key)}
                    className="SnackbarCloseIcon"
                  >
                    <GridCloseIcon color="inherit" fontSize="small" />
                  </IconButton>
                )}
                iconVariant={{
                  success: (
                    <Image
                      src={SuccessFeedbackMessage}
                      alt="success icon"
                      width={IMAGE_WIDTH}
                      height={IMAGE_HEIGHT}
                      style={{
                        height: "auto",
                      }}
                    />
                  ),
                  error: (
                    <Image
                      src={ErrorFeedbackMessage}
                      alt="error icon"
                      width={IMAGE_WIDTH}
                      height={IMAGE_HEIGHT}
                      style={{
                        height: "auto",
                      }}
                    />
                  ),
                }}
              >
                <ApolloProvider client={client}>
                  {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build
upon. */}
                  <CssBaseline />

                  <div dir={i18n.dir()}>
                    {children}
                    <NetworkStatusDetection />
                    <ForceUpdate />
                  </div>
                </ApolloProvider>
              </SnackbarProvider>
            </CustomThemeProvider>
          </MapProvider>
        </CacheProvider>
      </PersistGate>
    </Provider>
  );
};

export default Providers;
