export type StateType = {
  loginAttemptData: {
    timeToExpire: number;
  };
};

const initialState: StateType = {
  loginAttemptData: {
    timeToExpire: 0,
  },
};
export default initialState;
