import { TabsType } from "src/types";

import { PanelNameType } from "~/utils/accessControl/crudAcl";
import getTabsPathDependent from "~/utils/getTabsPathDependant";

import { labels } from "~/modules/panel-system/school-panel/locale";

type TabTitleKeys = keyof typeof labels;

// all the tabs including create and update
const tabs: TabsType<TabTitleKeys> = {
  schoolDetails: {
    id: "schoolDetails",
    title: "panel.tab.schoolDetails",
  },
  address: {
    id: "address",
    title: "panel.tab.address",
  },
  schedules: {
    id: "schedules",
    title: "panel.tab.schedules",
  },
};

export const panelConfig: PanelConfigType = {
  id: PanelNameType.SCHOOL,
  name: "panel.title",
  createTabs: [tabs.schoolDetails, tabs.address, tabs.schedules],
  updateTabs: [tabs.schoolDetails, tabs.address, tabs.schedules],
};

export const schoolActionsTabs = getTabsPathDependent(
  panelConfig.createTabs,
  panelConfig.updateTabs
);

export type PanelConfigType = {
  id: PanelNameType;
  name: string;
  createTabs: (typeof tabs)[keyof typeof tabs][];
  updateTabs: (typeof tabs)[keyof typeof tabs][];
};
