import { useLocation, useNavigate } from "react-router-dom";

import { serviceProviderLogin } from "~/config/appConfig";

import AccessControl from "~/utils/accessControl/crudAcl";
import AccessControlCreateUpdateTabs from "~/utils/accessControl/modalTabsAcl";
import { removeCookie } from "~/utils/storage/cookiesHelpers";
import { composeUrl } from "~/utils/url/composeUrl";

import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { AppDispatch } from "~/state/store";

// eslint-disable-next-line import/no-unresolved
import routes from "~/routes/main";

import { getCurrentUser, removeAccessToken } from "~/core/state/coreSlice";

export const clearSessionData = () => {
  AccessControl.removeCurrentUserData();
  AccessControl.removeRoles();
  AccessControl.removePermission();
  AccessControlCreateUpdateTabs.removePermission();
};

export const logout = (
  dispatch: AppDispatch,
  navigate?: (path: string) => void,
  currentPath?: string,
  isEmushrifMember = true
) => {
  removeCookie("refreshToken");
  dispatch(removeAccessToken());

  if (!isEmushrifMember && serviceProviderLogin.hostname) {
    window.location.replace(
      composeUrl({
        hostname: serviceProviderLogin.hostname,
        protocol: serviceProviderLogin.protocolHttp,
      })
    );
  } else if (navigate) {
    const loginUrl = isEmushrifMember ? routes.login : routes.loginProvider;

    if (currentPath && currentPath.indexOf(`${loginUrl.substring(1)}`) <= -1) {
      navigate(`${loginUrl}?redirect=${currentPath}`);
    } else {
      navigate(loginUrl);
    }
  }
};

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useAppSelector(getCurrentUser);
  const isEmushrifMember =
    userData?.isEmushrifMember === undefined || userData?.isEmushrifMember;

  return () => {
    logout(dispatch, navigate, location.pathname, isEmushrifMember);
  };
};
