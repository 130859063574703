/* eslint-disable no-param-reassign */
import { DataGridProProps, GridColDef } from "@mui/x-data-grid-pro";
import { jwtDecode } from "jwt-decode";
import isObject from "lodash/isObject";

import { PermissionsType } from "~/utils/accessControl/userPermissions";

// import {
//   getItemLocal,
//   removeItemLocal,
//   setItemLocal,
// } from "~/utils/storageHelpers";
import { store } from "~/state/store";

import { PermissionAction } from "~/gql/main/types.generated";

import { Mode } from "~/types/common";

import { PanelDataGridBaseProps } from "~/core/components/PanelDataGridBase";
import {
  getAclPermissions,
  removeAclPermissions,
  removeCurrentUser,
  removeRoles,
  setAclPermissions,
  setRoles,
} from "~/core/state/coreSlice";

import { PanelNameType } from "~/modules/panel-system/base-panel/types";

export { PanelNameType } from "~/modules/panel-system/base-panel/types";

export enum FieldAccess {
  SHOW = "show",
  HIDE = "hide",
  DISABLE = "disable",
}

class AccessControl {
  private _getAclPermissions() {
    return getAclPermissions(store.getState());
  }

  private _permissionPanel(panelName: PanelNameType) {
    return this._getAclPermissions()?.[panelName];
  }

  setPermissions(permissions: PermissionsType) {
    if (typeof window === "undefined") return;
    store.dispatch(setAclPermissions(permissions));
  }

  removePermission() {
    if (typeof window === "undefined") return;
    store.dispatch(removeAclPermissions());
    // return removeItemLocal("Permissions");
  }
  checkAction(panelName: PanelNameType, action: PermissionAction): boolean {
    const permission = this._permissionPanel(panelName)?.[action];
    // console.log({
    //   panelName,
    //   action,
    //   isAccessGranted1: this.permissionPanel(panelName)?.[action] !== false,
    //   isAccessGranted2:
    //     Object.keys(this.permissionPanel(panelName)?.[action] || [])?.length >
    //     0,
    // });
    if (typeof permission === "boolean") {
      return permission !== false;
    } else if (typeof permission === "object") {
      return Object.keys(permission || []).length > 0;
    } else {
      return false;
    }
  }

  getTableColumns(
    panelName: PanelNameType,
    columns: PanelDataGridBaseProps["columns"]
  ): DataGridProProps["columns"] {
    const readPermission =
      this._permissionPanel(panelName)?.[PermissionAction.READ];
    const permissableColumns: GridColDef[] = [];

    // console.log({
    //   panelPermission: this.permissionPanel(panelName),
    //   readPermission,
    //   panelName,
    //   permissions: this.getPermissions(),
    // });

    columns?.forEach((column) => {
      const hasEntityReadPermission = column.entity
        ? this.checkAction(column.entity, PermissionAction.READ)
        : true;
      if (readPermission === true) {
        if (hasEntityReadPermission) permissableColumns.push(column);
      } else {
        readPermission &&
          hasEntityReadPermission &&
          readPermission?.[column?.field] !== false &&
          permissableColumns.push(column);
      }
    });
    return permissableColumns;
  }

  checkFieldAccess(panelName: PanelNameType, action: Mode, field: string) {
    const actionPermission = this._permissionPanel(panelName)?.[action];
    const readPermission = this._permissionPanel(panelName)?.read;
    // If a permission doesn't exist, assume it is permissable. Only disallow if the permission is false.
    const hasActionPermission = isObject(actionPermission)
      ? actionPermission?.[field]
      : actionPermission;
    const hasReadPermission = isObject(readPermission)
      ? readPermission?.[field]
      : readPermission === true;

    if (hasActionPermission === false && hasReadPermission) return "disable";
    if (
      hasActionPermission === false &&
      (hasReadPermission === false || !hasReadPermission)
    )
      return "hide";

    return "show";
  }
  getValidationSchema(panelName: PanelNameType, action: Mode, schema: any) {
    // eslint-disable-next-line no-nested-ternary
    const schemaFields = schema?.fields
      ? schema?.fields
      : schema?.innerType
        ? schema?.innerType
        : schema;
    if (schemaFields && Object.keys(schemaFields)?.length > 0) {
      for (const [key, value] of Object.entries(schemaFields) as any) {
        // If top level is not accessible, omit it
        if (this.checkFieldAccess(panelName, action, key) !== "show") {
          if (schema?.omit?.fields) {
            schema.fields.omit([key]);
          } else if (schema?.omit) {
            schema.omit([key]);
          }
          if (schema?.fields?.[key]) delete schema.fields[key];
          else if (schema?.[key]) delete schema[key];
        } else if (value?.innerType) {
          value.innerType = this.getValidationSchema(
            panelName,
            action,
            value?.innerType
          );
        } else if (value?.fields) {
          value.fields = this.getValidationSchema(
            panelName,
            action,
            value?.fields
          );
        }
      }
    }
    return schema;
  }
  setRoles = (token: string) => {
    const { roles }: { roles: [] } = jwtDecode(token);

    store.dispatch(setRoles(roles));
    return roles;
  };

  removeRoles = () => {
    store.dispatch(removeRoles());
  };
  removeCurrentUserData = () => {
    store.dispatch(removeCurrentUser());
  };
}

export default new AccessControl();
