export type ComposeUrlType = {
  hostname: string;
  protocol: string;
  port?: number;
  apiPrefix?: string;
};

export const composeUrl = ({
  hostname,
  protocol,
  port,
  apiPrefix,
}: ComposeUrlType) => {
  let url = `${protocol}://${hostname}`;

  if (port) {
    url += `:${port}`;
  }

  if (apiPrefix) {
    url += apiPrefix;
  }
  return url;
};
