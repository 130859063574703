// enum PanelType

export enum PanelNameType {
  BASE = "basePanel",
  ROUTE_GROUP = "routeGroupPanel",
  ROUTE = "routePanel",
  SCHOOL_REGISTRATION = "schoolRegistrationPanel",
  SERVICE = "servicePanel",
  SERVICE_PROVIDER = "serviceProviderPanel",
  SCHOOL = "schoolPanel",
  VEHICLE = "vehiclePanel",
  DRIVER = "driverPanel",
  LEAVE = "leavePanel",
  AGENT = "agentPanel",
  BUS_ATTENDANT = "busAttendantPanel",
  AD_HOC_NOTIFICATIONS = "AdHocNotificationsPanel",
  TRIP = "tripPanel",
  TRIP_LIST = "tripListPanel",
  FLEET = "fleetPanel",
  DATA_BROWSER = "dataBrowserPanel",
  MAP = "mapPanel",
  SPLIT = "splitPanel",
  CASH_OUT_REQUESTS = "cashOutRequestsPanel",
  SAFETY_EVENTS = "safetyEventsPanel",
  GUARDIANS = "guardiansPanel",
}

export enum PanelNameTypeAcl {
  /**
   * NOTE: key correspond to backend subject of the ACL rule and the value corresponds to PanelNameType enum
   */

  // eslint-disable-next-line @typescript-eslint/naming-convention
  "SchoolRegistration" = PanelNameType.SCHOOL_REGISTRATION,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "School" = PanelNameType.SCHOOL,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "LeaveRequest" = PanelNameType.LEAVE,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "Trip" = PanelNameType.TRIP,
  // Trip List is a placeholder to inherit permissions from Trip as a workaround
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "TripList" = PanelNameType.TRIP_LIST,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "AdhocNotification" = PanelNameType.AD_HOC_NOTIFICATIONS,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "Vehicle" = PanelNameType.VEHICLE,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "BusAttendant" = PanelNameType.BUS_ATTENDANT,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "FLEET" = PanelNameType.FLEET,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "Service" = PanelNameType.SERVICE,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "Route" = PanelNameType.ROUTE,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "Driver" = PanelNameType.DRIVER,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "VehicleSafetyEvent" = PanelNameType.SAFETY_EVENTS,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "RouteGroup" = PanelNameType.ROUTE_GROUP,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "PassengerSponsor" = PanelNameType.GUARDIANS,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "CashOutRequest" = PanelNameType.CASH_OUT_REQUESTS,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "Agent" = PanelNameType.AGENT,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "ServiceProvider" = PanelNameType.SERVICE_PROVIDER,
}

export type BasePanelProps = {
  panelId: string;
  tabId?: string;
  isRoot?: boolean;
  deleted?: boolean;
  subscriptions?: string[];
  workspaceId: string | undefined;
};

// export type ExtendedPanelProps = {
//   panelId: string;
//   subEvents?: PanelEvent[];
//   // subscribe?: (panelId: string) => void;
//   // unsubscribe?: (panelId: string) => void;
// };

// Backwards compatibility
export type BasePanelConfig = {
  id: string;
  tabId?: string | "main";
  isRoot?: boolean | false;
  panelType?: string;
  props?: any;
};

export type WorkspaceBasePanelConfig = BasePanelConfig & {
  // TODO: change this to required after deprecating old base panel
  workspaceId: string;
};

export type BasePanelsConfig = {
  [key: string]: BasePanelConfig;
};

// export type PanelEvent = {
//   eventId?: string;
//   date?: string;
//   panelId: string;
//   panelType: PanelNameType;
//   eventType: string;
//   context: string;
//   payload?: any;
// };

// export type PanelsEvents = {
//   [key: string]: PanelEvent;
// };

// export type PanelSubscription = {
//   panelId: string;
//   isActive: boolean;
//   subscribedAt?: string;
//   lastEventId?: string;
// };

// export type PanelsSubscriptions = {
//   [key: string]: {
//     [key: string]: PanelSubscription;
//   };
// };

export type BasePanelState = {
  panels: BasePanelsConfig;
  // panelsEvents: PanelsEvents;
  // subscriptions: PanelsSubscriptions;
};

export type WorkspaceBasePanelState = {
  [key: string]: BasePanelState;
};

export enum SplitDirection {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}
