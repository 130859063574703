/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "~/state/store";

import initialState from "~/modules/devTools/state/initState";

const REDUCER_NAME = "devTools";

export const devToolsSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setIsVehicleTrackingSimulated: (state, action) => {
      state.isVehicleTrackingSimulated = action.payload;
    },
  },
});

export const getIsVehicleTrackingSimulated = (state: RootState) =>
  state?.[REDUCER_NAME]?.isVehicleTrackingSimulated ||
  initialState.isVehicleTrackingSimulated;

export const { setIsVehicleTrackingSimulated } = devToolsSlice.actions;

const reducer = { [REDUCER_NAME]: devToolsSlice.reducer };

export default reducer;
