import { RouteDirectionality, TripStatus } from "~/gql/main/types.generated";

import { SearchAgents } from "~/core/types";

import {
  SearchRouteGroups,
  SearchRoutes,
  SearchSchoolRegistrations,
  SearchSchools,
} from "~/modules/panel-system/trip-panel/types";

export type StateType = {
  filters: {
    countryCode: string | undefined;
    routeGroup: SearchRouteGroups;
    route: SearchRoutes;
    school: SearchSchools;
    schoolRegistration: SearchSchoolRegistrations;
    directionality: "all" | RouteDirectionality;
    status: "all" | TripStatus;
    agent: SearchAgents;
    isLoggedUser: boolean;
  };
};

export const initialState: StateType = {
  filters: {
    // addresses?.[0]?.country?.code ||
    countryCode: undefined,
    routeGroup: [],
    route: [],
    school: [],
    schoolRegistration: [],
    directionality: "all",
    status: "all",
    agent: [],
    isLoggedUser: false,
  },
};
export default initialState;
