import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { getEnvironment } from "~/utils/environment";

import Image from "~/core/components/Image";
import useCopy from "~/core/locale";

import generalError from "~/assets/generalError.svg";

interface Props {
  error: unknown;
  componentStack: string;
  eventId: string;
  resetError(): void;
}

const ErrorContent = ({ error, componentStack, resetError }: Props) => {
  const { translate } = useCopy();
  const theme = useTheme();
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        padding: theme.spacing(10, 4),
      }}
    >
      <Stack spacing={3} alignItems="center">
        <Image
          src={generalError}
          alt="error image"
          objectFit="contain"
          width={440}
          height={280}
        />
        <Typography variant="h6">
          {error?.toString() || translate("error.opsSomethingWentWrong")}
        </Typography>
        {getEnvironment().DEV && (
          <pre
            style={{
              overflowY: "scroll",
              background: theme.palette.grey[100],
              padding: theme.spacing(2, 1),
              maxHeight: "30vh",
            }}
          >
            {componentStack}
          </pre>
        )}
        <Button
          variant="outlined"
          color="secondary"
          onClick={resetError}
          // size="small"
          fullWidth
          sx={{
            padding: (theme) => theme.spacing(1.5, 2.5),
          }}
        >
          {translate("error.tryAgain")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default ErrorContent;
