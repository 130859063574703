/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "~/state/store";

import { Country } from "~/gql/main/types.generated";

import initialState, {
  PanelConfigType,
  PanelFiltersType,
} from "~/core/state/settingsSlice/initState";

import { PanelNameType } from "~/modules/panel-system/base-panel/types";

const REDUCER_NAME = "settings";

export const settingsSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.darkTheme = !state.darkTheme;
    },
    toggleSideMenu: (state) => {
      state.isSideMenuOpen = !state.isSideMenuOpen;
    },
    updatePanelConfig: (
      state,
      action: {
        payload: PanelConfigType & {
          panelType: PanelNameType;
        };
      }
    ) => {
      const { panelType, ...restPayload } = action.payload;

      if (state.panelConfig?.[panelType]) {
        state.panelConfig[panelType] = {
          ...(state.panelConfig?.[panelType] || {}),
          ...restPayload,
          // panelType: action.payload.panelType,
        };
      } else {
        state.panelConfig = {
          ...state.panelConfig,
          [panelType]: {
            ...(state.panelConfig?.[panelType] || {}),
            ...restPayload,
          },
        };
      }
    },
    updatePanelFilters: (
      state,
      action: {
        payload: PanelFiltersType & {
          panelType: PanelNameType;
        };
      }
    ) => {
      const { panelType, ...restPayload } = action.payload;

      if (state.panelFilters?.[panelType]) {
        state.panelFilters[panelType] = {
          ...(state.panelFilters?.[panelType] || {}),
          ...restPayload,
          // panelType: action.payload.panelType,
        };
      } else {
        state.panelFilters = {
          ...state.panelFilters,
          [panelType]: {
            ...(state.panelFilters?.[panelType] || {}),
            ...restPayload,
          },
        };
      }
    },
    resetPanelFilters: (
      state,
      action: {
        payload: PanelFiltersType & {
          panelType: PanelNameType;
        };
      }
    ) => {
      const { panelType, ...restPayload } = action.payload;

      if (state.panelFilters?.[panelType]) {
        state.panelFilters[panelType] = {
          // Keep the filters panel opened even after resetting the values
          filterToggleState: true,
          // panelType: action.payload.panelType,
          ...restPayload,
        };
      } else {
        state.panelFilters = {
          ...state.panelFilters,
          [panelType]: {
            // Keep the filters panel opened even after resetting the values
            filterToggleState: true,
            ...restPayload,
          },
        };
      }
    },
    togglePanelFilters: (
      state,
      action: {
        payload: {
          panelType: PanelNameType;
          isOpen: boolean;
        };
      }
    ) => {
      const { panelType, isOpen } = action.payload;

      if (state.panelFilters?.[panelType]) {
        state.panelFilters[panelType] = {
          ...(state.panelFilters?.[panelType] || {}),
          filterToggleState: isOpen,
        };
      } else {
        state.panelFilters = {
          ...state.panelFilters,
          [panelType]: {
            ...(state.panelFilters?.[panelType] || {}),
            filterToggleState: isOpen,
          },
        };
      }
    },

    resetPanelConfig: (
      state,
      action: {
        payload: PanelConfigType & {
          panelType: PanelNameType;
        };
      }
    ) => {
      const { panelType, ...restPayload } = action.payload;

      if (state.panelConfig?.[panelType]) {
        state.panelConfig[panelType] = {
          ...restPayload,
        };
      } else {
        state.panelConfig = {
          ...state.panelConfig,
          [panelType]: {
            ...restPayload,
          },
        };
      }
    },

    togglePanelColumns: (
      state,
      action: {
        payload: {
          panelType: PanelNameType;
          isOpen: boolean;
        };
      }
    ) => {
      const { panelType, isOpen } = action.payload;

      if (state.panelColumns?.[panelType]) {
        state.panelColumns[panelType] = {
          ...(state.panelColumns?.[panelType] || {}),
          columnToggleState: isOpen,
        };
      } else {
        state.panelColumns = {
          ...state.panelColumns,
          [panelType]: {
            ...(state.panelColumns?.[panelType] || {}),
            columnToggleState: isOpen,
          },
        };
      }
    },
    togglePanelSearch: (
      state,
      action: {
        payload: {
          panelType: PanelNameType;
          isOpen: boolean;
        };
      }
    ) => {
      const { panelType, isOpen } = action.payload;

      if (state.panelFilters?.[panelType]) {
        state.panelFilters[panelType] = {
          ...(state.panelFilters?.[panelType] || {}),
          searchToggleState: isOpen,
        };
      } else {
        state.panelFilters = {
          ...state.panelFilters,
          [panelType]: {
            ...(state.panelFilters?.[panelType] || {}),
            searchToggleState: isOpen,
          },
        };
      }
    },
    togglePanelSearchFocus: (
      state,
      action: {
        payload: {
          panelType: PanelNameType;
          isFocus: boolean;
        };
      }
    ) => {
      const { panelType, isFocus } = action.payload;

      if (state.panelFilters?.[panelType]) {
        state.panelFilters[panelType] = {
          ...(state.panelFilters?.[panelType] || {}),
          searchFocusState: isFocus,
        };
      } else {
        state.panelFilters = {
          ...state.panelFilters,
          [panelType]: {
            ...(state.panelFilters?.[panelType] || {}),
            searchFocusState: isFocus,
          },
        };
      }
    },
    resetMissingPanelFilters: (
      state,
      action: {
        payload: {
          panelType: PanelNameType;
          filters: PanelFiltersType["filters"];
        };
      }
    ) => {
      const { panelType, filters } = action.payload;

      if (state.panelFilters?.[panelType]) {
        state.panelFilters[panelType] = {
          ...(state.panelFilters?.[panelType] || {}),
          filters,
        };
      } else {
        state.panelFilters = {
          ...state.panelFilters,
          [panelType]: {
            ...(state.panelFilters?.[panelType] || {}),
            filters: {
              ...(filters || {}),
              // In case the user has already set some filters, we keep them
              ...(state.panelFilters?.[panelType]?.filters || {}),
            },
          },
        };
      }
    },
    setCountriesData: (state, action) => {
      state.countriesData = {
        countries: (action.payload as Country[]).reduce(
          (acc, value) => ({ ...acc, [value.code]: value }),
          {}
        ),
        timestamp: Date.now(),
      };
    },
  },
});

export const {
  toggleTheme,
  toggleSideMenu,
  togglePanelFilters,
  togglePanelSearch,
  togglePanelSearchFocus,
  updatePanelConfig,
  updatePanelFilters,
  resetPanelFilters,
  resetPanelConfig,
  togglePanelColumns,
  resetMissingPanelFilters,
  setCountriesData,
} = settingsSlice.actions;

export const getPanelConfig = (state: RootState) =>
  state?.[REDUCER_NAME]?.panelConfig;

export const getPanelFilter = (state: RootState) =>
  state?.[REDUCER_NAME]?.panelFilters;

export const getPanelColumn = (state: RootState) =>
  state?.[REDUCER_NAME]?.panelColumns;

export const getCountries = (state: RootState) =>
  state?.[REDUCER_NAME]?.countriesData?.countries || {};

const reducer = { [REDUCER_NAME]: settingsSlice.reducer };

// exporting the reducer here, as we need to add this to the store
export default reducer;
