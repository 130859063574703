import { Component } from "react";
import * as Sentry from "@sentry/react";

import ErrorContent from "~/core/components/ErrorBoundary/ErrorContent";

class ErrorBoundary extends Component<
  { children: any },
  { hasError: boolean; error: any }
> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  componentDidCatch(error: any) {
    return { error };
  }

  render() {
    // const { error } = this.state;
    // if (this.state?.hasError) {
    //   return <ErrorContent error={error.message} reset={reset} />;
    // }
    const { children } = this.props;

    return (
      <Sentry.ErrorBoundary fallback={ErrorContent}>
        {children}
      </Sentry.ErrorBoundary>
    );
  }
}

export default ErrorBoundary;
