import get from "lodash/get";
import set from "lodash/set";

import { getEnvironment } from "~/utils/environment";

export default function validateFormData(
  data: any,
  yupSchema: any,
  callback: (
    isSuccess: boolean,
    error?: any,
    errorsCount?: number,
    errorTabs?: { [key: string]: boolean }
  ) => void,
  initialData?: any
) {
  yupSchema
    .validate(data, { abortEarly: false })
    .then(() => {
      callback(true);
    })
    .catch((validateError: any) => {
      if (getEnvironment().DEV) {
        console.log({ inner: validateError?.inner, validateError });
      }

      const errorObject = {};
      const errorTabs: { [key: string]: boolean } = {};
      if (validateError && validateError?.inner) {
        let errorsCount = 0;
        let lastErrorPath = "";

        for (const error of validateError.inner) {
          const { path, value } = error.params;
          let initialValue;
          if (initialData) {
            initialValue = get(initialData, path);
          }

          if (!initialValue || initialValue !== value) {
            const errorTab: string = getErrorTabName(path, yupSchema);
            if (errorTab && !errorTabs[errorTab]) {
              errorTabs[errorTab] = true;
            }
            const { message } = error;
            set(errorObject, path, message);

            if (
              path !== lastErrorPath &&
              !(path.includes("poi") && lastErrorPath.includes("poi"))
            ) {
              errorsCount++;
              lastErrorPath = path;
            }
          }
        }

        if (errorsCount > 0)
          callback(false, errorObject, errorsCount, errorTabs);
        else callback(true);
      } else {
        callback(false, validateError, 1);
      }
    });
}

const getErrorTabName = (path: string, schema: any) => {
  const schemaPath = transformValidationErrorPathToSchemaPath(path);
  // Deep access schema fields
  const fieldSchemaData = schemaPath.split(".").reduce(
    function (obj, key, index, arr) {
      // some of the array type schema that has conditions (called with when func) and does not have default schema,
      // seems to have its innerType property evaluates to undefined
      // this condition is to abort early if found undefined
      if (obj?.[key] === undefined) {
        // abort
        arr.splice(1);
        return obj;
      }
      return obj?.[key];
    },
    schema?.fields
  );
  // Access schema meta data
  // Note: meta data added when you define your validation schema
  return fieldSchemaData?.spec?.meta?.tab;
};

const transformValidationErrorPathToSchemaPath = (path: string) => {
  const pathParts = path.split(".");
  // Insert "fields." before each part except the last one
  // If the string part is array, [index], add InnerType instead
  const transformedPath = pathParts
    .map((part, index) => {
      if (index < pathParts.length - 1) {
        let newPart = part;
        if (part.includes("[")) {
          newPart = `${part.split("[")[0]}.innerType`;
        }
        return `${newPart}.fields.`;
      } else {
        return part;
      }
    })
    .join("");
  return transformedPath;
};
