import { ReactNode } from "react";
import reactStringReplace from "react-string-replace";

// Capitalize each word in a sentence
export const capitalize = (text: string) => {
  return String(text)
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(" ");
};

// Capitalize first letter
export const capitalizeWord = (string: string) => {
  if (!string) return;
  return string.charAt(0).toUpperCase() + string.slice(1);
};
// lowercase first letter
export const lowerCaseWord = (string: string) => {
  if (!string) return;
  return string.charAt(0).toLowerCase() + string.slice(1);
};

export const hasNumber = (myString: string) => {
  return /\d/.test(myString);
};

// snake_case to camelCase
export const snakeToCamel = (string: string) =>
  string.replace(/([-_]\w)/g, (word) => word[1].toUpperCase());
// camelCase to snake_case
export const camelToSnake = (string: string) =>
  string
    .replace(/[\w]([A-Z])/g, (word) => `${word[0]}_${word[1]}`)
    .toLowerCase();
// space case to camelCase
export const spaceToCamel = (string: string) =>
  string.replace(/( \w)/g, (word) => word[1].toUpperCase());

export const replaceLinksInString = (
  text: string,
  replaceFunction: (match: string, index: number, offset: number) => ReactNode
) => {
  return reactStringReplace(
    text,
    /<%link%>([\s\S]*?)<\/%link%>/g,
    replaceFunction
  );
};

export const padNumber = (value: number | string, size = 5) => {
  // gracefully bail out
  if (!value && value !== 0) return "";

  let num = value.toString();
  while (num.length < size) num = `0${num}`;
  return num;
};

export const getRandomString = (length = 20) => {
  const randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let iterator = 0; iterator < length; iterator++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
};

export const truncate = (source: string | undefined, size: number) => {
  if (!source) return;

  return source.length > size ? `${source.slice(0, size - 1)}…` : source;
};

export const replaceCumulative = (
  source: string,
  find: string[],
  replace: string[]
) => {
  let sourceStr = source;
  find.forEach((findString: string, index: number) => {
    sourceStr = sourceStr.replace(new RegExp(findString, "g"), replace[index]);
  });
  return sourceStr;
};
