// cSpell:ignore assest
import { useCallback, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal, { ModalProps } from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import set from "lodash/set";

import { generateUuid } from "~/utils/generators/generateUuid";
import useDebounceCallback from "~/utils/hooks/useDebounceCallback";
import validateFormData from "~/utils/validation/validate";

import { useAppDispatch, useAppSelector } from "~/state/hooks";

import { PermissionAction } from "~/gql/main/types.generated";

import { Mode } from "~/types/common";

import useAddWorkspaceValidation from "~/core/components/AddWorkspaceModal/useAddWorkspaceValidation";
import Image from "~/core/components/Image";
import SideNavIcons, { navIconsOptions } from "~/core/components/SideNavIcons";
import useCopy from "~/core/locale";
import {
  createWorkspace,
  removeWorkspace,
  selectWorkspacePages,
  updateWorkspace,
} from "~/core/state/workspaceSlice";

import { removeWorkspacePanels } from "~/modules/panel-system/base-panel/slice";
import { removeWorkspaceSplitPanels } from "~/modules/panel-system/split-panel/slice";

import WorkspaceIcon from "~/assets/workspaceIcon.svg";

type AddWorkspaceModalProps = Omit<ModalProps, "onClose" | "children"> & {
  open: boolean;
  editWorkspaceId?: string | null;
  onClose: () => void;
};

const wrapperStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 510,
  bgcolor: "background.paper",
  borderRadius: 2.5,
  boxShadow: 24,
  paddingX: "75px",
  paddingTop: "40px",
  paddingBottom: "45px",
};

const AddWorkspaceModal = ({
  editWorkspaceId,
  open,
  onClose,
  ...props
}: AddWorkspaceModalProps) => {
  const dispatch = useAppDispatch();
  const workspaces = useAppSelector(selectWorkspacePages);

  const { translate } = useCopy();
  const { workSpaceSchema } = useAddWorkspaceValidation();
  const [name, setName] = useState("");
  const [icon, setIcon] = useState<string | null>("Adjust");
  const [mode, setMode] = useState<Mode>(PermissionAction.WRITE);
  const [formValidation, setFormValidation] = useState<any>();

  const validateAt = useDebounceCallback((path) => {
    const newFormValidation = { ...formValidation };
    try {
      workSpaceSchema.validateSyncAt(path, { name, icon });
      set(newFormValidation, path, undefined);
      setFormValidation(newFormValidation);
    } catch (error: { message: string } | any) {
      // console.log(JSON.parse(JSON.stringify(e)));
      set(newFormValidation, path, error?.message);
      setFormValidation(newFormValidation);
    }
  }, 500);

  const onCreateNewWorkspace = useCallback(() => {
    dispatch(
      createWorkspace({
        id: generateUuid(),
        name,
        icon,
      })
    );
    onClose && onClose();
  }, [name, icon, dispatch, onClose]);

  const onRemoveNewWorkspace = useCallback(() => {
    if (!editWorkspaceId) return;

    dispatch(
      removeWorkspace({
        id: editWorkspaceId,
      })
    );
    dispatch(
      removeWorkspacePanels({
        workspaceId: editWorkspaceId,
      })
    );
    dispatch(
      removeWorkspaceSplitPanels({
        workspaceId: editWorkspaceId,
      })
    );
    onClose && onClose();
  }, [editWorkspaceId, dispatch, onClose]);

  const onUpdateNewWorkspace = useCallback(() => {
    if (!editWorkspaceId) return;

    dispatch(
      updateWorkspace({
        id: editWorkspaceId,
        name,
        icon,
      })
    );
    onClose && onClose();
  }, [name, icon, editWorkspaceId, dispatch, onClose]);

  useEffect(() => {
    if (open) {
      setName("");
      setIcon("Adjust");
    }
  }, [open]);

  useEffect(() => {
    if (editWorkspaceId) {
      setName(workspaces?.[editWorkspaceId]?.name);
      setIcon(workspaces?.[editWorkspaceId]?.icon);
      setMode(PermissionAction.UPDATE);
    } else {
      setMode(PermissionAction.WRITE);
    }
  }, [editWorkspaceId, workspaces]);

  const onSubmit = (event: any) => {
    // prevent page refresh
    event.preventDefault();
    validateFormData(
      { name, icon },
      workSpaceSchema,
      (isSuccess: any, error: any) => {
        if (isSuccess) {
          mode === PermissionAction.WRITE
            ? onCreateNewWorkspace()
            : onUpdateNewWorkspace();
        } else {
          setFormValidation(error);
        }
      }
    );
  };

  return (
    <Modal open={open} onClose={onClose} {...props}>
      <Box sx={{ ...wrapperStyles }}>
        <form onSubmit={onSubmit}>
          <Stack direction="column" spacing={4}>
            <Stack direction="column" spacing={2}>
              <Image
                src={WorkspaceIcon}
                alt="warning"
                width={80}
                height={80}
                style={{ width: "100%" }}
              />
              <TextField
                value={name || ""}
                onChange={(event) => setName(event.target.value)}
                onBlur={() => validateAt("name")}
                label={translate("field.workspace.name")}
                variant="outlined"
                sx={{ marginTop: 0.5 }}
                error={!!formValidation?.name}
                helperText={formValidation?.name || ""}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                // onKeyDown={onKeyDown}
              />

              <Autocomplete
                value={icon}
                onChange={(event, newValue) => {
                  setIcon(newValue);
                }}
                options={Object.keys(navIconsOptions)}
                getOptionLabel={(option) => option}
                renderOption={(props, option) => {
                  return (
                    <Box
                      component="li"
                      sx={{ display: "flex", alignItems: "center" }}
                      {...props}
                    >
                      <SideNavIcons option={option} sx={{ marginRight: 1 }} />
                      {option}
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate("field.workspace.icon")}
                    variant="outlined"
                    error={!!formValidation?.icon}
                    helperText={formValidation?.icon || ""}
                  />
                )}
              />

              {/* TODO: add confirmation before deleting */}
              {mode === PermissionAction.UPDATE && (
                <Button
                  onClick={onRemoveNewWorkspace}
                  sx={
                    {
                      // position: "absolute",
                      // top: (theme) => theme.spacing(2),
                      // right: (theme) => theme.spacing(2),
                    }
                  }
                  color="error"
                  variant="text"
                  startIcon={<DeleteIcon />}
                >
                  <>{translate("modal.workspace.button.removeWorkspace")}</>
                  {/* <DeleteIcon fontSize="medium" /> */}
                </Button>
              )}
            </Stack>
            <Stack direction="row" justifyContent="space-evenly">
              <Button
                size="medium"
                className="greyButton"
                sx={{
                  paddingY: "16px",
                  width: "170px",
                  marginRight: "20px",
                }}
                variant="outlined"
                onClick={onClose}
              >
                {translate("modal.workspace.button.cancel")}
              </Button>
              <Button
                type="submit"
                size="medium"
                sx={{
                  backgroundColor: "primary.main",
                  color: "#FFFFF",
                  paddingY: "16px",
                  width: "170px",
                }}
                variant="contained"
                onClick={onSubmit}
              >
                <Typography variant="button">
                  {mode === PermissionAction.WRITE
                    ? translate("modal.workspace.button.createWorkspace")
                    : translate("modal.workspace.button.updateWorkspace")}
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </form>
        {/* name field */}
      </Box>
    </Modal>
  );
};

export default AddWorkspaceModal;
