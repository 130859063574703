import * as Sentry from "@sentry/react";

export const configureSentryUser = (
  email?: string | null,
  id?: string | null
): void => {
  if (email && id) {
    Sentry.setUser({
      email,
      id,
    });
  }
};

export const configureSentryPanelType = (
  panelType: string | undefined
): void => {
  Sentry.setTag("panelType", panelType);
};
