import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/system/Box";

import { useLocalization } from "~/utils/i18n/localization";

import { useAppSelector } from "~/state/hooks";

import theme from "~/styles/theme";

import {
  DRAWER_CLOSED_WIDTH,
  DRAWER_OPENED_WIDTH,
} from "~/core/components/SideNav/SideNav";

const CustomThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const isDarkTheme = useAppSelector((state) => state.settings.darkTheme);
  const isSideMenuOpen = useAppSelector(
    (state) => state.settings.isSideMenuOpen
  );

  const { i18n } = useLocalization();

  const customTheme = theme({
    rtl: i18n?.dir() === "rtl",
    darkTheme: isDarkTheme,
  });

  return (
    <ThemeProvider theme={customTheme}>
      <Box
        sx={{
          "& .notistack-SnackbarContainer": {
            top: `calc(${customTheme.mixins?.toolbar.minHeight}px + 20px)`,
            marginLeft: `${
              isSideMenuOpen ? DRAWER_OPENED_WIDTH : DRAWER_CLOSED_WIDTH
            }px`,
          },
          // Override the styling of notification alerts
          "& .notistack-SnackbarContainer .notistack-MuiContent.triggerAlert": {
            "&  .MuiSvgIcon-root": {
              marginRight: 1,
            },

            "&.notistack-MuiContent-error": {
              color: customTheme.palette.error.main,
              border: `1px solid ${customTheme.palette.error.main}`,
              backgroundColor: customTheme.palette.background.default,
              width: "310px",
              "& .MuiAlert-icon, & .SnackbarCloseIcon": {
                color: customTheme.palette.error.main,
                position: "absolute",
                right: 0,
                top: "8px",
              },
            },
            "&.notistack-MuiContent-info": {
              color: customTheme.palette.secondary.main,
              border: `1px solid ${customTheme.palette.secondary.main}`,
              backgroundColor: customTheme.palette.background.default,
              width: "310px",
              "& .MuiAlert-icon, & .SnackbarCloseIcon": {
                color: customTheme.palette.secondary.main,
                position: "absolute",
                right: 0,
                top: "8px",
              },
            },
          },
          "& .notistack-SnackbarContainer .successNetworkStatusDetection": {
            "& .MuiSnackbar-root .MuiAlert-message": {
              color: customTheme.palette.success.main,
            },
            "& .MuiLinearProgress-root .MuiLinearProgress-bar": {
              backgroundColor: customTheme.palette.success.main,
            },
          },
        }}
      />
      {children}
    </ThemeProvider>
  );
};

export default CustomThemeProvider;
