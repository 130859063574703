import { TabsType } from "src/types";

import { settings } from "~/config/appConfig";

import { PanelNameType } from "~/utils/accessControl/crudAcl";
import getTabsPathDependent from "~/utils/getTabsPathDependant";

import { labels } from "~/modules/panel-system/route-panel/locale";

type TabTitleKeys = keyof typeof labels;

// all the tabs including create and update
const tabs: TabsType<TabTitleKeys> = {
  selection: {
    id: "selection",
    title: "panel.tab.selection",
  },
  optimizer: {
    id: "optimizer",
    title: "panel.tab.optimizer",
  },
  routes: {
    id: "routes",
    title: "panel.tab.routes",
  },
  vehicles: {
    id: "vehicles",
    title: "panel.tab.vehicles",
  },
  busAttendants: {
    id: "busAttendants",
    title: "panel.tab.busAttendants",
  },
  drivers: {
    id: "drivers",
    title: "panel.tab.drivers",
  },
};

export const panelConfig: PanelConfigType = {
  id: PanelNameType.ROUTE,
  name: "panel.title",
  createTabs: [
    tabs.selection,
    tabs.optimizer,
    tabs.routes,
    tabs.vehicles,
    tabs.busAttendants,
    tabs.drivers,
  ],
  updateTabs: [
    tabs.selection,
    tabs.optimizer,
    tabs.routes,
    tabs.vehicles,
    tabs.busAttendants,
    tabs.drivers,
  ],
  settings: {
    populateFilterTimeOnUpdate: false,
    maxForceCreateTripCount: 5,
  },
};

export const routeActionsTabs = getTabsPathDependent(
  panelConfig.createTabs,
  panelConfig.updateTabs
);
export type PanelConfigType = {
  id: PanelNameType;
  name: string;
  createTabs: (typeof tabs)[keyof typeof tabs][];
  updateTabs: (typeof tabs)[keyof typeof tabs][];
  settings: {
    populateFilterTimeOnUpdate: boolean;
    maxForceCreateTripCount: number;
  };
};

export const defaultStartDate = `${settings.defaultSchoolYearStartDate}T00:00:00.000Z`;
export const defaultEndDate = `${settings.defaultSchoolYearEndDate}T00:00:00.000Z`;
