// WARNING: This file is automatically generated. Do not edit.
import * as Types from "../../../../api/gql/main/types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetMyPermissionsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.PermissionWhereInput>;
}>;

export type GetMyPermissionsQuery = {
  __typename?: "Query";
  getMyPermissions: Array<{
    __typename?: "Permission";
    subject: string;
    actions?: Array<Types.PermissionAction> | null;
    id: string;
    name: string;
  }>;
};

export const GetMyPermissionsDocument = gql`
  query GetMyPermissions($filter: PermissionWhereInput) {
    getMyPermissions(filter: $filter) {
      subject
      actions
      id
      name
    }
  }
`;

/**
 * __useGetMyPermissionsQuery__
 *
 * To run a query within a React component, call `useGetMyPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPermissionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetMyPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyPermissionsQuery,
    GetMyPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyPermissionsQuery, GetMyPermissionsQueryVariables>(
    GetMyPermissionsDocument,
    options
  );
}
export function useGetMyPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyPermissionsQuery,
    GetMyPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyPermissionsQuery,
    GetMyPermissionsQueryVariables
  >(GetMyPermissionsDocument, options);
}
export function useGetMyPermissionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMyPermissionsQuery,
    GetMyPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetMyPermissionsQuery,
    GetMyPermissionsQueryVariables
  >(GetMyPermissionsDocument, options);
}
export type GetMyPermissionsQueryHookResult = ReturnType<
  typeof useGetMyPermissionsQuery
>;
export type GetMyPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetMyPermissionsLazyQuery
>;
export type GetMyPermissionsSuspenseQueryHookResult = ReturnType<
  typeof useGetMyPermissionsSuspenseQuery
>;
export type GetMyPermissionsQueryResult = Apollo.QueryResult<
  GetMyPermissionsQuery,
  GetMyPermissionsQueryVariables
>;
