export const lightPalette = {
  background: {
    paper: "#ffffff",
    default: "#ffffff",
    base: "#f6f6f6",
  },
  primary: {
    lightest: "#F7DDD4",
    light: "#E7997E",
    main: "#D85C32",
    dark: "#BA480C",
    darkest: "#2B1108",
  },
  secondary: {
    lightest: "#F7F2FD",
    light: "#AE7AEB",
    main: "#601BB0",
    dark: "#360F63",
    darkest: "#18072C",
  },
  tertiary: {
    lightest: "#F0FEFC",
    light: "#63E3DC",
    main: "#00AEA4",
    dark: "#007A73",
    darkest: "#0B3D3A",
  },
  natural: {
    lightest: "#DDE3EE",
    light: "#CCCCCC",
    main: "#666666",
    dark: "rgba(0, 0, 0, 0.8)",
    darkest: "rgba(0, 0, 0, 0.9)",
  },
  // TODO: change and order the colors/values from lightest to darkest
  chalk: {
    lightest: "#DDE3EE",
    light: "#ADB8CC",
    main: "#F3F5F9",
    dark: "#475266",
    darkest: "#141922",
  },
  error: {
    light: "#F1E7EA",
    main: "#AF313B",
    dark: "#651C25",
    lightest: "rgba(173, 52, 62, 0.1)",
  },
  success: {
    light: "#EDF2EF",
    main: "#009640",
    dark: "#214A24",
  },
  info: {
    lightest: "#e8e5ff",
    light: "#F6F2FC",
    main: "#812FDF",
    dark: "#601BB0",
  },
  warning: {
    light: "#FEFBF3",
    main: "#DAA902",
    dark: "#937725",
  },
};

export const darkPalette = {
  // common: {
  //   black: "#fff",
  //   white: "#3D4548",
  // },
  // background: {
  //   paper: "#212121",
  //   default: "#424242",
  // },
  // primary: {
  //   light: "#cbd2d9",
  //   main: "#52606d",
  //   dark: "#323f4b",
  //   contrastText: "#212121",
  // },
  // success: {
  //   light: "#42ba96",
  //   main: "#42ba96",
  //   dark: "#42ba96",
  //   contrastText: "#fff",
  // },
  // secondary: {
  //   light: "#d3cec4",
  //   main: "#857f72",
  //   dark: "#504a40",
  //   contrastText: "#212121",
  // },
  // warning: {
  //   light: "#f9ba59",
  //   main: "#f8b144",
  //   dark: "#F8A930",
  //   contrastText: "#fff",
  // },
  // error: {
  //   light: "#E9775B",
  //   main: "#E45532",
  //   dark: "#9F3B23",
  //   contrastText: "#fff",
  // },
  // text: {
  //   primary: "#fafafa",
  //   secondary: "#fafafa90",
  //   disabled: "#fafafa60",
  //   hint: "#fafafa50",
  // },
  // action: {
  //   active: "#fafafa90",
  //   hover: "#fafafa80",
  //   hoverOpacity: 0.08,
  //   selected: "#fafafa65",
  //   disabled: "#fafafa70",
  //   disabledBackground: "#fafafa60",
  // },
};
