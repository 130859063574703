/* eslint-disable @typescript-eslint/naming-convention */
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AddHomeIcon from "@mui/icons-material/AddHome";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import AdjustIcon from "@mui/icons-material/Adjust";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";
import AirlineStopsIcon from "@mui/icons-material/AirlineStops";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import BadgeIcon from "@mui/icons-material/Badge";
import BusinessIcon from "@mui/icons-material/Business";
import CommuteIcon from "@mui/icons-material/Commute";
import ComputerIcon from "@mui/icons-material/Computer";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import GarageIcon from "@mui/icons-material/Garage";
import GroupIcon from "@mui/icons-material/Group";
import HomeIcon from "@mui/icons-material/Home";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import RampRightIcon from "@mui/icons-material/RampRight";
import SettingsIcon from "@mui/icons-material/Settings";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { SxProps } from "@mui/material/styles";

export const navIconsOptions: Record<string, React.ElementType> = {
  AccountBox: AccountBoxIcon,
  AddHome: AddHomeIcon,
  AddLocation: AddLocationIcon,
  AddRoad: AddRoadIcon,
  Adjust: AdjustIcon,
  AdUnits: AdUnitsIcon,
  AirlineSeatReclineNormal: AirlineSeatReclineNormalIcon,
  AirlineStops: AirlineStopsIcon,
  AirportShuttle: AirportShuttleIcon,
  Badge: BadgeIcon,
  Business: BusinessIcon,
  Commute: CommuteIcon,
  Computer: ComputerIcon,
  CreditCard: CreditCardIcon,
  DirectionsBus: DirectionsBusIcon,
  DirectionsCar: DirectionsCarIcon,
  Garage: GarageIcon,
  Group: GroupIcon,
  Home: HomeIcon,
  Inventory: InventoryIcon,
  LocationOn: LocationOnIcon,
  RampRight: RampRightIcon,
  Settings: SettingsIcon,
  Person: PersonIcon,
  SupervisorAccount: SupervisorAccountIcon,
  ContactPage: ContactPageIcon,
  EmojiPeople: EmojiPeopleIcon,
};

export default function SideNavIcons({
  option,
  sx,
  showDefault = false,
}: {
  option: string;
  sx?: SxProps;
  showDefault?: boolean;
}) {
  const Icon = navIconsOptions[option] || (showDefault && AdjustIcon);

  if (!Icon) return <></>;

  return <Icon sx={sx} />;
}
