import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import { graphqlMainService, graphqlRealtimeService } from "~/config/appConfig";

import { getEnvironment } from "~/utils/environment";
import { limitDepth } from "~/utils/objects/objectHelpers";
import { composeUrl } from "~/utils/url/composeUrl";

const environment = getEnvironment();

const SENTRY_DSN = environment.NEXT_PUBLIC_SENTRY_DSN;
const ENVIRONMENT =
  (environment.DEV && "local") || environment.NEXT_PUBLIC_SENTRY_ENVIRONMENT;
Sentry.init({
  // Disable Sentry for local development
  enabled: ENVIRONMENT !== "local",

  // Limit the depth of the breadcrumbs to ensure that errors are not rejected by Sentry.
  beforeBreadcrumb(breadcrumb) {
    return limitDepth(breadcrumb, 1);
  },

  dsn:
    SENTRY_DSN ||
    "https://3e916807a5ff47e18d40b68e596200ed@sentry.emushrif.link/5",

  // A number between 0 and 1, controlling the percentage chance a given transaction will be sent to Sentry.
  tracesSampleRate: 0.65,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [
    /^\//,
    composeUrl({
      hostname: graphqlMainService.hostname,
      protocol: graphqlMainService.protocolHttp,
      port: graphqlMainService.port,
      apiPrefix: graphqlMainService.apiPrefix,
    }),
    composeUrl({
      hostname: graphqlRealtimeService.hostname,
      protocol: graphqlRealtimeService.protocolHttp,
      port: graphqlRealtimeService.port,
      apiPrefix: graphqlRealtimeService.apiPrefix,
    }),
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  /* By default Sentry SDKs normalize any context to a depth of 3,
   which in the case of sending Redux state you probably will want to increase that. */
  normalizeDepth: 7,

  // debug: ENVIRONMENT === "local",

  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  release: `${environment.NEXT_PUBLIC_APP_NAME}@${environment.NEXT_PUBLIC_APP_VERSION}`,

  // Tag the release with the environment
  environment: ENVIRONMENT,

  integrations: [
    Sentry.captureConsoleIntegration({
      levels: ["error"],
    }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
});
