import { useEffect } from "react";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";

import { clearSessionData } from "~/utils/accessControl/useLogout";

import Image from "~/core/components/Image";

import GoogleLoginForm from "~/modules/login/chunks/GoogleLoginForm";
import theme from "~/modules/login/theme";

import LoginIllustrations from "~/assets/loginIllustrations.svg";

const Login = () => {
  useEffect(() => {
    clearSessionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: "#FFF0EB", height: "100vh" }}>
        <Box
          sx={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box marginBottom={8}>
            <Image
              src={LoginIllustrations}
              alt="Saar dashboard login"
              width={850}
              height={550}
              sizes="100vw"
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </Box>
          <GoogleLoginForm />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Login;
