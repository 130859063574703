import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";

import { settings } from "~/config/appConfig";

import useCopy from "~/core/locale";

import GreenCheckMark from "~/assets/greenCheckMark.svg";

const NetworkStatusDetection = () => {
  const { translate } = useCopy();
  useEffect(() => {
    window.ononline = () => {
      enqueueSnackbar(
        translate("networkStatusDetection.internetConnectionIsBack"),
        {
          isError: false,
          successIcon: GreenCheckMark,
          className: "successNetworkStatusDetection",
          autoHideDuration: settings.networkConnectedAutoHideDuration,
        }
      );
    };
    window.onoffline = () => {
      enqueueSnackbar(
        translate("networkStatusDetection.noInternetConnection"),
        {
          isError: true,
          autoHideDuration: settings.networkDisconnectedAutoHideDuration,
        }
      );
    };
  });
  return <></>;
};
export default NetworkStatusDetection;
