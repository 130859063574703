import { Navigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import routes from "src/routes/main";

import Login from "~/modules/login";

export default function Page() {
  const isLogin = !!Cookies.get("refreshToken");
  const [searchParams] = useSearchParams();
  const redirectPath = searchParams.get("redirect");

  if (isLogin) {
    return <Navigate to={redirectPath || routes.panels} replace />;
  }

  return <Login />;
}
