import { object, string } from "yup";

const useAddWorkspaceValidation = () => {
  const workSpaceSchema = object({
    name: string().required().label("Workspace Name"),
  });

  return { workSpaceSchema };
};

export default useAddWorkspaceValidation;
