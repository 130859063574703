import { useEffect, useRef, useState } from "react";
import Alert, { alertClasses } from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Paper, { paperClasses } from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { compare } from "compare-versions";
// eslint-disable-next-line import/no-duplicates
import addMilliseconds from "date-fns/addMilliseconds";
// eslint-disable-next-line import/no-duplicates
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
// eslint-disable-next-line import/no-duplicates
import intervalToDuration from "date-fns/intervalToDuration";
// eslint-disable-next-line import/no-duplicates
import millisecondsToMinutes from "date-fns/millisecondsToMinutes";
// eslint-disable-next-line import/no-duplicates
import millisecondsToSeconds from "date-fns/millisecondsToSeconds";

import { getEnvironment } from "~/utils/environment";
import { padNumber } from "~/utils/strings/stringHelpers";

import { useAppSelector } from "~/state/hooks";

import { clientNoAuth } from "~/gql/main/apolloClient";
import { AppType, AppVersionType } from "~/gql/main/types.generated";

import Image from "~/core/components/Image";
import {
  DRAWER_CLOSED_WIDTH,
  DRAWER_OPENED_WIDTH,
} from "~/core/components/SideNav/SideNav";
import { useGetLatestAppVersionLazyQuery } from "~/core/generated/main/misc.generated";
import useCopy from "~/core/locale";

import UpdateIcon from "~/assets/updateIcon.svg";

export const forceUpdateDelay = 1 * 60 * 1000; // in seconds. Wait x seconds before reloading the page
// export const normalUpdateDelay = 1.1 * 60 * 1000; // in seconds. Wait x seconds before reloading the page
export const normalUpdateDelay = 10 * 60 * 1000; // in seconds. Wait x seconds before reloading the page

export default function ForceUpdate() {
  const { translate } = useCopy();

  const isSideMenuOpen = useAppSelector(
    (state) => state.settings.isSideMenuOpen
  );

  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);
  const [isForceUpdate, setIsForceUpdate] = useState(false);
  const [isUpdateLater, setIsUpdateLater] = useState(false);
  const [updateCountdown, setUpdateCountdown] = useState("");
  const [newVersion, setNewVersion] = useState("");

  const counterRef = useRef<ReturnType<typeof setInterval> | undefined>();

  const onRefresh = () => {
    if (typeof window !== "undefined") {
      window.location.reload();
    }
  };

  const onClose = () => {
    setIsUpdateLater(true);
  };

  const [getAppVersion] = useGetLatestAppVersionLazyQuery({
    variables: {
      appTypeInput: {
        appType: AppType.OPS_WEB,
      },
    },
    client: clientNoAuth,
    onCompleted({ getLatestAppVersion }) {
      if (!getLatestAppVersion) return;

      const currentVersion = getEnvironment().NEXT_PUBLIC_APP_VERSION;
      const latestVersion = `${getLatestAppVersion?.major}.${getLatestAppVersion?.minor}.${getLatestAppVersion?.patch}`;

      // Backward compatibility in case the version cannot be parsed
      if (!currentVersion) return;

      setNewVersion(latestVersion);
      const isNewVersion = compare(
        `${currentVersion}`,
        `${latestVersion}`,
        "<"
      );

      if (
        isNewVersion &&
        getLatestAppVersion?.appVersionType !== AppVersionType.SILENT
      ) {
        if (getLatestAppVersion?.appVersionType === AppVersionType.FORCEFUL) {
          setIsForceUpdate(true);
        }
        setIsNewVersionAvailable(true);
        setIsUpdateLater(false);
      } else {
        // Reset the values
        setIsForceUpdate(false);
        setIsNewVersionAvailable(false);
        setIsUpdateLater(false);
      }
    },
    // pollInterval: 1000,
    fetchPolicy: "no-cache",
  });

  const getLatestAppVersion = () => {
    getAppVersion({
      variables: {
        appTypeInput: {
          appType: AppType.OPS_WEB,
        },
      },
    });
  };

  useEffect(() => {
    if (isUpdateLater) {
      const currentTime = new Date();
      const eventTime = addMilliseconds(
        currentTime,
        isForceUpdate ? forceUpdateDelay : normalUpdateDelay
      );

      let duration = differenceInMilliseconds(eventTime, currentTime);

      const interval = 1000;
      if (counterRef.current) clearInterval(counterRef.current);

      counterRef.current = setInterval(() => {
        const currentTime = new Date();
        duration = differenceInMilliseconds(
          addMilliseconds(currentTime, duration - interval),
          currentTime
        );

        if (millisecondsToMinutes(duration) < 1) {
          setUpdateCountdown(
            translate("modal.appVersionUpdate.refreshPageInSeconds", {
              seconds: millisecondsToSeconds(duration),
            })
          );
          if (millisecondsToSeconds(duration) < 1) {
            onRefresh();
          }
        } else {
          const durationTime = intervalToDuration({
            start: currentTime,
            end: addMilliseconds(currentTime, duration),
          });

          const formatted = `${padNumber(
            durationTime.minutes || 0,
            2
          )}:${padNumber(durationTime.seconds || 0, 2)}`;

          setUpdateCountdown(
            translate("modal.appVersionUpdate.refreshPageInMinutes", {
              minutes: formatted,
            })
          );
        }
      }, interval);
    }

    return () => {
      if (counterRef.current) clearInterval(counterRef.current);
    };
  }, [isForceUpdate, isUpdateLater, translate]);

  useEffect(() => {
    if (typeof window === "undefined") return;

    window.addEventListener("focus", getLatestAppVersion);

    return () => {
      window.removeEventListener("focus", getLatestAppVersion);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isNewVersionAvailable && !isUpdateLater}
      >
        <Paper
          sx={{
            borderRadius: 2.5,
            boxShadow: 24,
            paddingX: (theme) => theme.spacing(9),
            paddingTop: (theme) => theme.spacing(5),
            paddingBottom: (theme) => theme.spacing(5),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "25px",
          }}
        >
          <Image src={UpdateIcon} alt="Update Icon" width={80} height={80} />

          <Typography variant="subtitle1" sx={{ color: "text.primary" }}>
            {translate("modal.appVersionUpdate.newVersion")}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: "text.secondary",
              lineHeight: (theme) => theme.typography.subtitle1.lineHeight,
            }}
          >
            {translate("modal.appVersionUpdate.updateMessage", {
              minutes: millisecondsToMinutes(
                isForceUpdate ? forceUpdateDelay : normalUpdateDelay
              ),
              version: newVersion,
            })}
          </Typography>

          <Stack
            direction="row-reverse"
            justifyContent="flex-end"
            spacing={2}
            sx={{
              width: "100%",
            }}
          >
            <Button
              sx={{
                paddingY: (theme) => theme.spacing(1.5),
              }}
              color="inherit"
              variant="outlined"
              onClick={onClose}
              // disabled={isForceUpdate}
              size="large"
              fullWidth
            >
              {millisecondsToMinutes(
                isForceUpdate ? forceUpdateDelay : normalUpdateDelay
              ) < 1
                ? translate("modal.appVersionUpdate.button.later.waitSeconds", {
                    seconds: millisecondsToSeconds(
                      isForceUpdate ? forceUpdateDelay : normalUpdateDelay
                    ),
                  })
                : translate("modal.appVersionUpdate.button.later.waitMinutes", {
                    minutes: millisecondsToMinutes(
                      isForceUpdate ? forceUpdateDelay : normalUpdateDelay
                    ),
                  })}
            </Button>
            <Button
              sx={{
                paddingY: (theme) => theme.spacing(1.5),
              }}
              variant="contained"
              onClick={onRefresh}
              size="large"
              fullWidth
            >
              <Typography variant="button">
                {translate("modal.appVersionUpdate.button.refresh")}
              </Typography>
            </Button>
          </Stack>
        </Paper>
      </Dialog>

      {isUpdateLater && (
        <Snackbar
          open
          sx={{
            marginLeft: `${
              isSideMenuOpen ? DRAWER_OPENED_WIDTH : DRAWER_CLOSED_WIDTH
            }px`,
            [`& .${paperClasses.root}`]: {
              backgroundColor: "background.default",
              color: "#141922",
              alignItems: "center",
              boxShadow: (theme) => theme.shadows[4],
            },
          }}
        >
          <Alert
            // severity="info"
            icon={
              <Image
                src={UpdateIcon}
                alt="Update Icon"
                width={60}
                height={60}
              />
            }
            variant="standard"
            action={
              <Button
                color="primary"
                size="small"
                variant="outlined"
                onClick={onRefresh}
                sx={{
                  marginRight: (theme) => theme.spacing(1.3),
                  paddingY: (theme) => theme.spacing(1.2),
                }}
              >
                {translate("modal.appVersionUpdate.button.refresh")}
              </Button>
            }
            sx={{
              [`& .${alertClasses.icon}`]: {
                height: 60,
              },
            }}
          >
            {updateCountdown || translate("modal.appVersionUpdate.newVersion")}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
