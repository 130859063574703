// WARNING: This file is automatically generated. Do not edit.
import * as Types from "../../../../api/gql/main/types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type LoginWithGoogleMutationVariables = Types.Exact<{
  loginWithGoogleInput: Types.LoginWithGoogleInput;
}>;

export type LoginWithGoogleMutation = {
  __typename?: "Mutation";
  loginWithGoogle: {
    __typename?: "LoginResponse";
    jwtObj: {
      __typename?: "JWTObj";
      accessToken: string;
      refreshToken: string;
    };
  };
};

export const LoginWithGoogleDocument = gql`
  mutation LoginWithGoogle($loginWithGoogleInput: LoginWithGoogleInput!) {
    loginWithGoogle(loginWithGoogleInput: $loginWithGoogleInput) {
      jwtObj {
        accessToken
        refreshToken
      }
    }
  }
`;
export type LoginWithGoogleMutationFn = Apollo.MutationFunction<
  LoginWithGoogleMutation,
  LoginWithGoogleMutationVariables
>;

/**
 * __useLoginWithGoogleMutation__
 *
 * To run a mutation, you first call `useLoginWithGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithGoogleMutation, { data, loading, error }] = useLoginWithGoogleMutation({
 *   variables: {
 *      loginWithGoogleInput: // value for 'loginWithGoogleInput'
 *   },
 * });
 */
export function useLoginWithGoogleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginWithGoogleMutation,
    LoginWithGoogleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LoginWithGoogleMutation,
    LoginWithGoogleMutationVariables
  >(LoginWithGoogleDocument, options);
}
export type LoginWithGoogleMutationHookResult = ReturnType<
  typeof useLoginWithGoogleMutation
>;
export type LoginWithGoogleMutationResult =
  Apollo.MutationResult<LoginWithGoogleMutation>;
export type LoginWithGoogleMutationOptions = Apollo.BaseMutationOptions<
  LoginWithGoogleMutation,
  LoginWithGoogleMutationVariables
>;
