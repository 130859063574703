import { combineReducers } from "redux";

import coreReducer from "~/core/state/coreSlice";
import settingReducer from "~/core/state/settingsSlice";
import workspaceReducer from "~/core/state/workspaceSlice";

import devToolsReducer from "~/modules/devTools/state/slice";
import otpConfigsReducer from "~/modules/login-provider/state/otpConfigsSlice";
import workspaceBasePanelReducer from "~/modules/panel-system/base-panel/slice";
import routePanelReducer from "~/modules/panel-system/route-panel/state/slice";
import workspaceSplitPanelReducer from "~/modules/panel-system/split-panel/slice";
import tripListPanelReducer from "~/modules/panel-system/trip-panel/views/trip-list/state/slice";
import tripPanelReducer from "~/modules/panel-system/trip-panel/views/trip-map/state/slice";

const rootReducer = combineReducers({
  ...settingReducer,
  user: coreReducer,
  ...workspaceReducer,
  ...workspaceBasePanelReducer,
  ...workspaceSplitPanelReducer,
  ...routePanelReducer,
  ...tripPanelReducer,
  ...tripListPanelReducer,
  ...devToolsReducer,
  ...otpConfigsReducer,
});

export default rootReducer;
