import { TabsType } from "src/types";

import { PanelNameType } from "~/utils/accessControl/crudAcl";
import getTabsPathDependent from "~/utils/getTabsPathDependant";

import { labels } from "~/modules/panel-system/fleet-panel/locale";

type TabTitleKeys = keyof typeof labels;

// all the tabs including create and update
const tabs: TabsType<TabTitleKeys> = {};

export const panelConfig: PanelConfigType = {
  id: PanelNameType.FLEET,
  name: "panel.title",
  createTabs: [],
  updateTabs: [],
  settings: {
    maxFiltersShown: 1,
    showRemainingFilters: false,
    vehicleUpdatesPollingInterval: 1000 * 15, // In milliseconds
    mapInternalId: "fleet-panel-map-1",
  },
};

export const fleetActionsTabs = getTabsPathDependent(
  panelConfig.createTabs,
  panelConfig.updateTabs
);

export type PanelConfigType = {
  id: PanelNameType.FLEET;
  name: string;
  createTabs: (typeof tabs)[keyof typeof tabs][];
  updateTabs: (typeof tabs)[keyof typeof tabs][];
  // eslint-disable-next-line @typescript-eslint/ban-types
  settings: {
    mapInternalId: string;
    maxFiltersShown: number;
    showRemainingFilters: boolean;
    vehicleUpdatesPollingInterval: number;
  };
};
