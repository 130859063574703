import { TabsType } from "src/types";

import { PanelNameType } from "~/utils/accessControl/crudAcl";
import getTabsPathDependent from "~/utils/getTabsPathDependant";

import { labels } from "~/modules/panel-system/leave-panel/locale";

type TabTitleKeys = keyof typeof labels;

// all the tabs including create and update
const tabs: TabsType<TabTitleKeys> = {
  studentSelection: {
    id: "studentSelection",
    title: "panel.tab.studentSelection",
  },
  leaveDetails: {
    id: "leaveDetails",
    title: "panel.tab.leaveDetails",
  },
  requestDetails: {
    id: "requestDetails",
    title: "panel.tab.requestDetails",
  },
};

export const panelConfig: PanelConfigType = {
  id: PanelNameType.LEAVE,
  name: "panel.title",
  createTabs: [tabs.studentSelection, tabs.leaveDetails],
  updateTabs: [tabs.requestDetails, tabs.leaveDetails],
};

export const leaveActionsTabs = getTabsPathDependent(
  panelConfig.createTabs,
  panelConfig.updateTabs
);
export type PanelConfigType = {
  id: PanelNameType;
  name: string;
  createTabs: (typeof tabs)[keyof typeof tabs][];
  updateTabs: (typeof tabs)[keyof typeof tabs][];
};

export const leaveSelection = [
  "By parent name/phone number",
  "By student name",
];
