import { PermissionAction } from "~/gql/main/types.generated";

type TabsType = {
  id: string;
  title: string;
};

const getTabsPathDependent = (
  createTabs: TabsType[],
  updateTabs: TabsType[]
) => ({
  [PermissionAction.WRITE]: createTabs.reduce(
    (acc, tab) => ({ ...acc, [tab.id]: true }),
    {}
  ),
  [PermissionAction.UPDATE]: updateTabs.reduce(
    (acc, tab) => ({ ...acc, [tab.id]: true }),
    {}
  ),
});

export default getTabsPathDependent;
