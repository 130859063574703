import Cookies from "js-cookie";

export const setCookie = (
  key: string,
  value: any,
  options?: { expires: Date }
) =>
  Cookies.set(key, value, {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    SameSite: "Lax",
    ...options,
  });

export const getCookie = (key: string) => Cookies.get(key);

export const removeCookie = (key: string) => Cookies.remove(key);
