import { useCallback, useEffect, useRef, useState } from "react";

interface UseScriptOptions {
  src?: string;
  id?: string;
  strategy?: "afterInteractive" | "lazyOnload" | "beforeInteractive" | "worker";
  onLoad?: (e: Event) => void;
  onReady?: () => void;
  onError?: (e: Event) => void;
  defer?: boolean;
  async?: boolean;
  checkOnReadyInterval?: number;
  target?: "head" | "body";
}

const ScriptCache = new Map();
const LoadCache = new Set();

const useInjectScript = ({
  src,
  id,
  strategy = "afterInteractive",
  onLoad,
  onReady,
  onError,
  defer,
  async,
  checkOnReadyInterval,
  target = "body",
}: UseScriptOptions) => {
  const [status, setStatus] = useState<"idle" | "loading" | "ready" | "error">(
    "idle"
  );
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const checkAndCallOnReady = useCallback(() => {
    if (
      onReady &&
      window.google &&
      window.google.accounts &&
      window.google.accounts.id
    ) {
      onReady();
    }
  }, [onReady]);

  useEffect(() => {
    if (!src) {
      return;
    }

    const cacheKey = id || src;

    if (cacheKey && LoadCache.has(cacheKey)) {
      setStatus("ready");
      checkAndCallOnReady();
      return;
    }

    const loadScript = () => {
      if (ScriptCache.has(src)) {
        ScriptCache.get(src).then(
          () => {
            setStatus("ready");
            onLoad?.(new Event("load"));
            checkAndCallOnReady();
          },
          (error: Event) => {
            setStatus("error");
            onError?.(error);
          }
        );
        return;
      }

      const script = document.createElement("script");

      const loadPromise = new Promise<void>((resolve, reject) => {
        script.addEventListener("load", function (event) {
          resolve();
          setStatus("ready");
          onLoad?.(event);
          checkAndCallOnReady();
        });

        script.addEventListener("error", function (event) {
          reject(event);
          setStatus("error");
          onError?.(event);
        });
      });

      script.src = src;
      if (defer) script.defer = true;
      if (async) script.async = true;

      ScriptCache.set(src, loadPromise);

      // cSpell:ignore nscript
      script.setAttribute("data-nscript", strategy);

      if (target === "head") {
        document.head.appendChild(script);
      } else {
        document.body.appendChild(script);
      }

      if (cacheKey) {
        LoadCache.add(cacheKey);
      }
    };

    if (strategy === "afterInteractive") {
      loadScript();
    } else if (strategy === "lazyOnload") {
      window.addEventListener("load", () => {
        requestIdleCallback(() => loadScript());
      });
    } else if (strategy === "beforeInteractive" || strategy === "worker") {
      loadScript();
    }

    // Set up interval to check and call onReady only if checkOnReadyInterval is defined and greater than 0
    if (checkOnReadyInterval && checkOnReadyInterval > 0) {
      intervalRef.current = setInterval(
        checkAndCallOnReady,
        checkOnReadyInterval
      );
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [
    src,
    id,
    strategy,
    onLoad,
    onReady,
    onError,
    defer,
    async,
    checkAndCallOnReady,
    checkOnReadyInterval,
    target,
  ]);

  return status;
};

export default useInjectScript;
