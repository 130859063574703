import { accordionClasses } from "@mui/material/Accordion";
import { accordionSummaryClasses } from "@mui/material/AccordionSummary";
import { alertClasses } from "@mui/material/Alert";
import { buttonClasses } from "@mui/material/Button";
import { buttonBaseClasses } from "@mui/material/ButtonBase";
import { chipClasses } from "@mui/material/Chip";
import { formControlLabelClasses } from "@mui/material/FormControlLabel";
import { formGroupClasses } from "@mui/material/FormGroup";
import { linkClasses } from "@mui/material/Link";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { paperClasses } from "@mui/material/Paper";
import {
  alpha,
  createTheme,
  PaletteOptions,
  Theme,
} from "@mui/material/styles";
import { svgIconClasses } from "@mui/material/SvgIcon";
import { tablePaginationClasses } from "@mui/material/TablePagination";
import { gridClasses } from "@mui/x-data-grid-pro";

import config from "~/config/appConfig";

import { darkPalette, lightPalette } from "~/styles/palette";
import { typography } from "~/styles/typography";

declare module "@mui/material/styles" {
  interface Theme {
    dir?: string;
    direction?: string;
  }

  interface ThemeOptions {
    dir?: string;
    direction?: string;
  }

  interface Palette {
    tertiary?: Palette["primary"];
    natural?: Palette["primary"];
    chalk?: Palette["primary"];
  }

  interface PaletteOptions {
    tertiary?: PaletteOptions["primary"];
    natural?: PaletteOptions["primary"];
    chalk?: PaletteOptions["primary"];
  }

  interface PaletteColor {
    lightest?: string;
    darkest?: string;
  }

  interface SimplePaletteColorOptions {
    lightest?: string;
    darkest?: string;
  }

  interface TypeBackground {
    base?: string;
  }
}

declare module "@mui/material" {
  interface ChipPropsColorOverrides {
    tertiary: true;
  }
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsVariantOverrides {
    disabled: true;
  }
}

// Create a theme instance.
export default function theme({ rtl = false, darkTheme = false }) {
  const palette = darkTheme ? darkPalette : lightPalette;
  const styles = {
    dir: rtl ? "rtl" : "ltr",
    direction: rtl ? "rtl" : "ltr",
    palette: {
      mode: darkTheme ? "dark" : ("light" as PaletteOptions["mode"]),
      ...palette,
    },
    typography,
    components: {
      MuiBackdrop: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            backgroundColor: "rgba(0,0,0,0.1)",
            zIndex: theme.zIndex.drawer + 1,
          }),
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            [`& .${outlinedInputClasses.root}`]: {
              "& fieldset": {
                borderColor: (palette as typeof lightPalette).natural.lightest,
              },
            },
          },
        },
      },
      MuiOutlinedInput: {
        variants: [
          {
            props: { disabled: true },
            style: {
              backgroundColor: alpha(
                (palette as typeof lightPalette).chalk.main,
                1
              ),
              input: {
                cursor: "not-allowed",
              },
            },
          },
        ],
      },

      MuiPaper: {
        styleOverrides: {
          root: {
            [`& .${paperClasses.root}.Mui-expanded`]: {
              margin: 0,
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            [`& .${accordionSummaryClasses.content}.${accordionClasses.expanded}`]:
              {
                margin: theme.spacing(1.5, 0),
              },
            [`& .${buttonBaseClasses.root}.${accordionClasses.expanded}`]: {
              minHeight: 0,
            },
          }),
        },
      },

      MuiButton: {
        styleOverrides: {
          root: { textTransform: "uppercase" as const },
          outlined: {
            "&.greyButton": {
              color: "#52606d",
              borderColor: "rgba(82, 96, 109, 0.5)",

              "&:hover": {
                color: "#52606d",
                borderColor: "rgba(82, 96, 109, 0.5)",
                backgroundColor: "rgba(214, 214, 214, 0.2)",
              },
            },
          },
        },
      },

      MuiModal: {
        styleOverrides: {
          root: {
            width: "100%",
            height: "100%",
            transition: "all 0.2s ease-in-out",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            width: "100%",
            height: "100%",
            transition: "all 0.2s ease-in-out",
          },
        },
      },

      MuiAlert: {
        styleOverrides: {
          // message: ({ ownerState }: { ownerState: any }) => ({
          //   display: "flex",
          //   alignItems: "center",
          //   justifyContent: "space-between",
          //   alignSelf: "center",
          //   flexDirection: ownerState.severity === "success" ? "column" : "row",
          // }),
          // action: ({ ownerState }: { ownerState: any }) => ({
          //   alignItems:
          //     ownerState.severity === "error" ? "center" : "flex-start",
          //   paddingTop: ownerState.severity === "error" ? "0px" : "6px",
          //   alignSelf: "center",
          //   padding: "8px 0",
          // }),
          // override the background colors to avoid lightening the background by MUI
          standardInfo: {
            backgroundColor: (palette as typeof lightPalette).info.light,
            color: (palette as typeof lightPalette).info.dark,
            [`& .${alertClasses.icon}`]: {
              color: (palette as typeof lightPalette).info.dark,
            },
          },
          outlinedInfo: {
            borderColor: (palette as typeof lightPalette).info.main,
            color: (palette as typeof lightPalette).info.main,
            [`& .${alertClasses.icon}`]: {
              color: (palette as typeof lightPalette).info.dark,
            },
          },
          standardSuccess: {
            color: (palette as typeof lightPalette).success.main,
            backgroundColor: (palette as typeof lightPalette).success.light,
            [`& .${alertClasses.icon}`]: {
              color: (palette as typeof lightPalette).success.main,
            },
          },
          standardWarning: {
            color: (palette as typeof lightPalette).warning.main,
            backgroundColor: (palette as typeof lightPalette).warning.light,
            [`& .${alertClasses.icon}`]: {
              color: (palette as typeof lightPalette).warning.main,
            },
          },
          standardError: {
            color: (palette as typeof lightPalette).error.main,
            backgroundColor: (palette as typeof lightPalette).error.light,
            [`& .${alertClasses.icon}`]: {
              color: (palette as typeof lightPalette).error.main,
            },
          },
          // TODO: override other variants because MUI adds opacity to 'light' color which is already light enough
          // outlinedInfo: {
          //   color: (palette as typeof lightPalette).info.main,
          //   // borderColor: (palette as typeof lightPalette).info.light,
          // },
          // infoIconColor: {
          //   color: (palette as typeof lightPalette).info.main,
          // },
        },
      },

      MuiDatePicker: {
        defaultProps: {
          // Override the default date format
          format: config.settings.defaultDateFormat,
        },
      },
      MuiDesktopDatePicker: {
        defaultProps: {
          // Override the default date format
          format: config.settings.defaultDateFormat,
        },
      },
      MuiDateTimePicker: {
        defaultProps: {
          // Override the default date format
          format: config.settings.defaultDateTimeFormat,
          ampm: false,
        },
      },
      MuiDesktopDateTimePicker: {
        defaultProps: {
          // Override the default date format
          format: config.settings.defaultDateTimeFormat,
          ampm: false,
        },
      },
      MuiTimePicker: {
        defaultProps: {
          // Override the default date format
          format: config.settings.defaultTimeFormat,
          ampm: false,
        },
      },
      MuiDesktopTimePicker: {
        defaultProps: {
          // Override the default date format
          format: config.settings.defaultTimeFormat,
          ampm: false,
        },
      },

      EmCountryChip: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            width: "max-content",
            height: "27px",
            backgroundColor: theme.palette.chalk?.main,
            border: "none",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: theme.palette.chalk?.lightest,
            },
            color: theme.palette.chalk?.dark,
            fontSize: typography?.subtitle2?.fontSize,
            lineHeight: typography?.body2?.lineHeight,
            [`& .${chipClasses.avatar}`]: {
              width: 20,
              height: 20,
            },
          }),
        },
      },
      EmStatusChip: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.palette.chalk?.main,
            color: theme.palette.chalk?.dark,
          }),
          filled: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.palette.chalk?.main,
            color: theme.palette.chalk?.dark,
          }),
          outlined: ({ theme }: { theme: Theme }) => ({
            backgroundColor: "transparent",
            borderColor: theme.palette.chalk?.dark,
            color: theme.palette.chalk?.dark,
          }),
          filledPrimary: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.palette.primary?.lightest,
            color: theme.palette.primary?.main,
          }),
          filledSecondary: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.palette.secondary?.lightest,
            color: theme.palette.secondary?.main,
          }),
          filledTertiary: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.palette.tertiary?.lightest,
            color: theme.palette.tertiary?.main,
          }),
          filledSuccess: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.palette.success?.light,
            color: theme.palette.success?.main,
          }),
          filledError: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.palette.error?.light,
            color: theme.palette.error?.main,
          }),
          filledWarning: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.palette.warning?.light,
            color: theme.palette.warning?.main,
          }),
          filledInfo: ({ theme }: { theme: Theme }) => ({
            backgroundColor: theme.palette.info?.light,
            color: theme.palette.info?.main,
          }),
          outlinedPrimary: ({ theme }: { theme: Theme }) => ({
            borderColor: theme.palette.primary?.main,
            color: theme.palette.primary?.main,
          }),
          outlinedSecondary: ({ theme }: { theme: Theme }) => ({
            borderColor: theme.palette.secondary?.main,
            color: theme.palette.secondary?.main,
          }),
          outlinedTertiary: ({ theme }: { theme: Theme }) => ({
            borderColor: theme.palette.tertiary?.main,
            color: theme.palette.tertiary?.main,
          }),
          outlinedSuccess: ({ theme }: { theme: Theme }) => ({
            borderColor: theme.palette.success?.main,
            color: theme.palette.success?.main,
          }),
          outlinedError: ({ theme }: { theme: Theme }) => ({
            borderColor: theme.palette.error?.main,
            color: theme.palette.error?.main,
          }),
          outlinedWarning: ({ theme }: { theme: Theme }) => ({
            borderColor: theme.palette.warning?.main,
            color: theme.palette.warning?.main,
          }),
          outlinedInfo: ({ theme }: { theme: Theme }) => ({
            borderColor: theme.palette.info?.main,
            color: theme.palette.info?.main,
          }),
        },
      },
      EmPanelDataGridBase: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            padding: theme.spacing(2),
            height: () => `calc(100% - 35px)`,
            border: 0,

            "& .EmPanelDataGridBase-loading": {
              margin: 0,
            },

            [`& .${gridClasses.footerContainer}`]: {
              marginBottom: theme.spacing(-2),
              border: 0,

              [`& .${tablePaginationClasses.root}`]: {
                border: 0,
              },
            },

            [`& .${gridClasses.main}`]: {
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: theme.shape.borderRadius,
              height: "100%",
            },

            [`& .${gridClasses.row}.Mui-hovered`]: {
              backgroundColor: "#F5F5F5",
            },
            [`& .${gridClasses.row}.Mui-hovered:nth-of-type(2n)`]: {
              backgroundColor: "#F0F0F0",
            },

            [`& .${gridClasses.row}:nth-of-type(2n)`]: {
              backgroundColor: "#FAFBFD",
            },
            [`& .${gridClasses.row}.Mui-selected:nth-of-type(2n)`]: {
              backgroundColor: alpha(theme.palette.primary.main, 0.2),
            },

            [`& .${gridClasses.cell}`]: {
              fontSize: typography?.body2?.fontSize,
              color: theme.palette.text.primary,
            },
            [`& .${gridClasses.columnHeaderTitle}`]: {
              fontSize: typography?.body2?.fontSize,
              color: theme.palette.text.secondary,
            },

            [`& .${linkClasses.root}`]: {
              color: theme.palette.secondary.main,
              cursor: "pointer",
              textDecoration: "underline",
            },
          }),
        },
      },

      EmGridMenu: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            "& .EmPanelDataGridBase-headerMenuItem .MuiButton-root": {
              color: theme.palette.text.secondary,
            },
          }),
        },
      },

      EmGridMenuItem: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            [`& .${buttonClasses.root}`]: {
              color: theme.palette.text.secondary,
              textTransform: "capitalize" as const,
              fontSize: theme.typography.body2.fontSize,
            },

            [`& .${svgIconClasses.root}`]: {
              fontSize: theme.typography.h6.fontSize,
            },
            minWidth: 150,
          }),
        },
      },

      EmPanelContainer: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            padding: theme.spacing(2),
            height: "100%",
          }),
          noPadding: {
            padding: 0,
          },
        },
      },

      EmBuildingTypesRadioButtons: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            width: "100%",
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),

            [`& .${formGroupClasses.root}`]: {
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              flexWrap: "nowrap",
            },

            [`& .${formControlLabelClasses.root}`]: {
              margin: 0,
              flexBasis: "49%",
              [`& span.${buttonBaseClasses.root}`]: {
                padding: 0,
                width: "100%",
              },
            },
          }),
        },
      },

      EmRadioButtonBox: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            borderRadius: theme.shape.borderRadius,
            width: "100%",
            height: "69px",
            border: "1px solid rgb(59 54 54 / 30%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "input:hover ~ &": {
              backgroundColor: alpha(theme.palette.primary.main as string, 0.1),
            },
          }),
          checked: ({ theme }: { theme: Theme }) => ({
            backgroundColor: alpha(theme.palette.primary.main as string, 0.1),
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.light,
            backgroundImage:
              "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
          }),
        },
      },

      EmRadioButton: {
        styleOverrides: {
          root: () => ({
            "&:hover": {
              backgroundColor: "transparent",
            },
            "& .MuiBox-root": {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            },
          }),
        },
      },

      EmSelectionRadioButtons: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            width: "100%",
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),

            [`& .${formGroupClasses.root}`]: {
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              flexWrap: "nowrap",
            },

            [`& .${formControlLabelClasses.root}`]: {
              margin: 0,
              flexBasis: "49%",
              [`& span.${buttonBaseClasses.root}`]: {
                padding: 0,
                width: "100%",
              },
            },
          }),
        },
      },

      EmSubSectionTitle: {
        styleOverrides: {
          root: ({ theme }: { theme: Theme }) => ({
            fontWeight: theme.typography?.fontWeightBold,
            marginBottom: theme.spacing(2),
          }),
        },
      },
    },
  };
  return createTheme({ ...styles });
}
