import React from "react";
const __pages_import_0__ = React.lazy(() => import("/app/[...not-found].tsx"));
import __pages_import_1__ from "/app/index.tsx";
const __pages_import_2__ = React.lazy(() => import("/app/login/index.tsx"));
const __pages_import_3__ = React.lazy(() => import("/app/login-provider/index.tsx"));
const __pages_import_4__ = React.lazy(() => import("/app/login-provider/verify-otp/index.tsx"));
const __pages_import_5__ = React.lazy(() => import("/app/panels/[pid].tsx"));
const __pages_import_6__ = React.lazy(() => import("/app/panels/index.tsx"));
const __pages_import_7__ = React.lazy(() => import("/app/trip-manifest/index.tsx"));

const routes = [{"caseSensitive":false,"path":"*","element":React.createElement(__pages_import_0__)},{"caseSensitive":false,"path":"/","element":React.createElement(__pages_import_1__)},{"caseSensitive":false,"path":"login","children":[{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_2__)}]},{"caseSensitive":false,"path":"login-provider","children":[{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_3__)},{"caseSensitive":false,"path":"verify-otp","children":[{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_4__)}]}]},{"caseSensitive":false,"path":"panels","children":[{"caseSensitive":false,"path":":pid","element":React.createElement(__pages_import_5__)},{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_6__)}]},{"caseSensitive":false,"path":"trip-manifest","children":[{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_7__)}]}];

export default routes;