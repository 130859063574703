import { VehicleSafetyEvent } from "~/gql/main/types.generated";

// Declaring the types for our state

export enum NotificationAlertFilterTabsTitle {
  ALL = "ALL",
  UNREAD = "UNREAD",
  READ = "READ",
}

export type NotificationAlertTabs = {
  [key in NotificationAlertFilterTabsTitle]: {
    title: NotificationAlertFilterTabsTitle;
    items: VehicleSafetyEvent[];
  };
};

type WorkspaceState = {
  pages: {
    [key: string]: {
      id: string;
      name: string;
      icon: string;
    };
  };
  /** @Deprecated Use `notificationCentre` instead */
  safetyAlertsNotificationCentre?: NotificationAlertTabs;
  notificationCentre: {
    /** @Deprecated Use `readNotifications` to store ids of alerts that are read */
    notifications: NotificationAlertTabs;
    readNotifications: { [key: string]: number };
    shouldShowNotificationAlerts: boolean;
  };
};

const initialState: WorkspaceState = {
  pages: {},
  /** @Deprecated Use `notificationCentre` instead */
  safetyAlertsNotificationCentre: {
    [NotificationAlertFilterTabsTitle.ALL]: {
      title: NotificationAlertFilterTabsTitle.ALL,
      items: [],
    },
    [NotificationAlertFilterTabsTitle.UNREAD]: {
      title: NotificationAlertFilterTabsTitle.UNREAD,
      items: [],
    },
    [NotificationAlertFilterTabsTitle.READ]: {
      title: NotificationAlertFilterTabsTitle.READ,
      items: [],
    },
  },
  notificationCentre: {
    /** @Deprecated Use `readNotifications` to store ids of alerts that are read */
    notifications: {
      [NotificationAlertFilterTabsTitle.ALL]: {
        title: NotificationAlertFilterTabsTitle.ALL,
        items: [],
      },
      [NotificationAlertFilterTabsTitle.UNREAD]: {
        title: NotificationAlertFilterTabsTitle.UNREAD,
        items: [],
      },
      [NotificationAlertFilterTabsTitle.READ]: {
        title: NotificationAlertFilterTabsTitle.READ,
        items: [],
      },
    },
    readNotifications: {},
    shouldShowNotificationAlerts: true, // Adding shouldShowNotificationAlerts directly in the notification centre
  },
};

export default initialState;
