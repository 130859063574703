/* eslint-disable no-param-reassign */
// import redux toolkit
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// import root state type
import { RootState } from "~/state/store";

// import types
// import { PanelNameType } from "~/modules/panel-system/base-panel/types";
import {
  // SplitPanelStateType,
  WorkspaceSplitPanelState,
  WorkspaceSplitPanelStateType,
} from "~/modules/panel-system/split-panel/types";

const REDUCER_NAME = "workspaceSplitPanels";

export const workspaceSplitPanelSlice = createSlice({
  name: "workspaceSplitPanels",
  initialState: {
    // panels: {} as {
    //   [panelId: string]: SplitPanelStateType;
    // },
  } as WorkspaceSplitPanelState,
  reducers: {
    createWorkspaceSplitPanel: (
      state,
      action: PayloadAction<WorkspaceSplitPanelStateType>
    ) => {
      const { workspaceId, panelId, ...restPayload } = action.payload;

      // eslint-disable-next-line no-param-reassign
      state[workspaceId] = {
        ...state[workspaceId],
        [panelId]: {
          ...(state?.[workspaceId]?.[panelId] || {}),
          ...restPayload,
          panelId,
        },
      };
    },
    removeWorkspaceSplitPanel: (
      state,
      action: PayloadAction<WorkspaceSplitPanelStateType>
    ) => {
      const { workspaceId, panelId } = action.payload;
      const workspacePanels = { ...state[workspaceId] };

      if (workspacePanels?.[panelId]) delete workspacePanels[panelId];

      // eslint-disable-next-line no-param-reassign
      state[workspaceId] = workspacePanels;
    },
    updateWorkspaceSplitPanel: (
      state,
      action: PayloadAction<WorkspaceSplitPanelStateType>
    ) => {
      const { workspaceId, panelId, ...restPayload } = action.payload;

      // eslint-disable-next-line no-param-reassign
      state[workspaceId] = {
        ...state[workspaceId],
        [panelId]: {
          ...(state?.[workspaceId]?.[panelId] || {}),
          ...restPayload,
          panelId,
        },
      };
    },

    // manage workspaces
    removeWorkspaceSplitPanels: (
      state,
      action: PayloadAction<Omit<WorkspaceSplitPanelStateType, "panelId">>
    ) => {
      const { workspaceId } = action.payload;
      const workspacePanels = { ...state };

      if (workspacePanels[workspaceId]) delete workspacePanels[workspaceId];

      // console.log("splitPanels", {
      //   state: { ...state },
      //   workspacePanels,
      //   workspaceId,
      // });

      return workspacePanels;
    },
  },
});

export const {
  createWorkspaceSplitPanel,
  removeWorkspaceSplitPanel,
  updateWorkspaceSplitPanel,
  removeWorkspaceSplitPanels,
} = workspaceSplitPanelSlice.actions;

export const selectWorkspaceSplitPanels =
  (workspaceId: string | undefined) => (state: RootState) => {
    if (workspaceId) return state?.[REDUCER_NAME]?.[workspaceId];
    else return {};
  };

const reducer = { [REDUCER_NAME]: workspaceSplitPanelSlice.reducer };

export default reducer;
