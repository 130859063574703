import { TabsType } from "src/types";

import { PanelNameType } from "~/utils/accessControl/crudAcl";
import getTabsPathDependent from "~/utils/getTabsPathDependant";

import { labels } from "~/modules/panel-system/safety-events-panel/locale";

type TabTitleKeys = keyof typeof labels;

// all the tabs including create and update
const tabs: TabsType<TabTitleKeys> = {
  generalInfo: {
    id: "generalInfo",
    title: "panel.tab.generalInfo",
  },
};

export const panelConfig: PanelConfigType = {
  id: PanelNameType.SAFETY_EVENTS,
  name: "panel.title",
  createTabs: [tabs.generalInfo],
  updateTabs: [tabs.generalInfo],
};

export const safetyActionsTabs = getTabsPathDependent(
  panelConfig.createTabs,
  panelConfig.updateTabs
);

export type PanelConfigType = {
  id: PanelNameType;
  name: string;
  createTabs: (typeof tabs)[keyof typeof tabs][];
  updateTabs: (typeof tabs)[keyof typeof tabs][];
};
