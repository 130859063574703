/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "~/state/store";

import initialState, {
  StateType,
} from "~/modules/panel-system/trip-panel/state/initState";

const REDUCER_NAME = "tripListPanel";

export const tripListPanelSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    changeFilterValue: (
      state,
      action: PayloadAction<{
        name: keyof StateType["filters"];
        value: any;
      }>
    ) => {
      const { name, value } = action.payload;
      if (!state.filters) {
        state.filters = initialState.filters;
      } else {
        state.filters = {
          ...state.filters,
          [name]: value,
        };
      }
    },
    // Mainly used as a backward compatibility to ensure all the filters are set
    resetInitialFilters: (state) => {
      if (!state?.filters) {
        state.filters = initialState.filters;
      } else {
        state.filters = {
          ...initialState.filters,
          // In case the user has already set some filters, we keep them
          ...state.filters,
        };
      }
    },
    // clearFilters: (state) => {
    //   // state.filters = initialState.filters;
    //   state.filters = {};
    // },
  },
});

export const getTripFilters = (state: RootState) =>
  state?.[REDUCER_NAME]?.filters || initialState.filters;

export const { changeFilterValue, resetInitialFilters } =
  tripListPanelSlice.actions;

const reducer = { [REDUCER_NAME]: tripListPanelSlice.reducer };

export default reducer;
