const routes = {
  login: "/login",
  loginProvider: "/login-provider",
  verifyOtp: "/login-provider/verify-otp",
  students: "/students",
  panels: "/panels",
  tripManifest: "/trip-manifest",
  flags: "/img/countryFlags",
};

export default routes;
