// WARNING: This file is automatically generated. Do not edit.
import * as Types from "../../../../api/gql/main/types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetBulkServicesWithPriceQueryVariables = Types.Exact<{
  getBulkServicesWithPriceInput: Types.GetBulkServicesWithPriceInput;
}>;

export type GetBulkServicesWithPriceQuery = {
  __typename?: "Query";
  getBulkServicesWithPrice: Array<{
    __typename?: "ServiceWithPrice";
    id: string;
    countryId: string;
    durationInMonths?: number | null;
    name: string;
    nameAr: string;
    photo?: string | null;
    status: Types.Status;
    basePrice: number;
    basePriceWithVat: number;
    basePriceWithVatAndModifiers: number;
    basePriceWithVatAndModifiersAndOffer: number;
    serviceCategoryId?: string | null;
    installmentPlans: Array<{
      __typename?: "InstallmentPlanWithPrice";
      id: string;
      name: string;
      nameAr: string;
      status: Types.Status;
      publishedAt: any;
      expiredAt?: any | null;
      discountPercentage: number;
      planAmount: number;
      depositAmount: number;
      isDefault?: boolean | null;
      installmentAmountBeforePlanDiscount: number;
      paymentLineItems: Array<{
        __typename?: "PaymentLineItemForPricing";
        amount: number;
        isDeposit: boolean;
      }>;
      collectionTimePeriods?: Array<{
        __typename?: "TimePeriod";
        from: any;
        id: string;
        name: string;
        nameAr: string;
        paymentDueDays?: number | null;
        paymentReminderFrequency?: number | null;
        paymentReminderStartDays?: number | null;
        priority: number;
        archivedAt?: any | null;
      }> | null;
    }>;
    category?: {
      __typename?: "ServiceCategory";
      descriptionAr: string;
      description: string;
      id: string;
      name: string;
    } | null;
    country: {
      __typename?: "Country";
      id: string;
      nameAr: string;
      name: string;
      code: string;
    };
    paymentPlansPeriods?: Array<{
      __typename?: "PaymentPlanPeriod";
      id: string;
      groupedInstallmentPlans?: Array<{
        __typename?: "InstallmentPlan";
        id: string;
        isDefault?: boolean | null;
        servicePricing?: {
          __typename?: "ServicePricing";
          currency?: Types.CurrencyCode | null;
          id: string;
          journeyBaseDistanceKM?: number | null;
          journeyBasePrice?: number | null;
          journeyIncrementalDistanceKM?: number | null;
          journeyIncrementalDistancePrice?: number | null;
          multipleKidFactor?: number | null;
          oneWayFactor?: number | null;
          pricePremium?: number | null;
          vat?: number | null;
          deposit?: {
            __typename?: "Deposit";
            id: string;
            depositRefundMaxPenalty?: number | null;
            depositRefundCutoffDays: number;
            depositPercentage: number;
            depositActiveToDays: number;
            depositType: Types.DepositType;
            depositPaymentDate?: any | null;
          } | null;
        } | null;
      }> | null;
    }> | null;
  }>;
};

export type GetServiceWithPriceQueryVariables = Types.Exact<{
  schoolId: Types.Scalars["String"]["input"];
  modifiers: Types.ServicePriceModifiers;
  offerCode?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type GetServiceWithPriceQuery = {
  __typename?: "Query";
  getServiceWithPrice: {
    __typename?: "ServiceWithPrice";
    id: string;
    countryId: string;
    durationInMonths?: number | null;
    name: string;
    nameAr: string;
    photo?: string | null;
    status: Types.Status;
    basePrice: number;
    basePriceWithVat: number;
    basePriceWithVatAndModifiers: number;
    basePriceWithVatAndModifiersAndOffer: number;
    serviceCategoryId?: string | null;
    installmentPlans: Array<{
      __typename?: "InstallmentPlanWithPrice";
      id: string;
      name: string;
      nameAr: string;
      status: Types.Status;
      publishedAt: any;
      expiredAt?: any | null;
      discountPercentage: number;
      planAmount: number;
      depositAmount: number;
      installmentAmountBeforePlanDiscount: number;
      paymentLineItems: Array<{
        __typename?: "PaymentLineItemForPricing";
        amount: number;
        isDeposit: boolean;
      }>;
      collectionTimePeriods?: Array<{
        __typename?: "TimePeriod";
        from: any;
        id: string;
        name: string;
        nameAr: string;
        paymentDueDays?: number | null;
        paymentReminderFrequency?: number | null;
        paymentReminderStartDays?: number | null;
        priority: number;
        archivedAt?: any | null;
      }> | null;
    }>;
    category?: {
      __typename?: "ServiceCategory";
      descriptionAr: string;
      description: string;
      id: string;
      name: string;
    } | null;
    country: {
      __typename?: "Country";
      id: string;
      nameAr: string;
      name: string;
      code: string;
    };
    paymentPlansPeriods?: Array<{
      __typename?: "PaymentPlanPeriod";
      id: string;
      groupedInstallmentPlans?: Array<{
        __typename?: "InstallmentPlan";
        id: string;
        servicePricing?: {
          __typename?: "ServicePricing";
          currency?: Types.CurrencyCode | null;
          id: string;
          journeyBaseDistanceKM?: number | null;
          journeyBasePrice?: number | null;
          journeyIncrementalDistanceKM?: number | null;
          journeyIncrementalDistancePrice?: number | null;
          multipleKidFactor?: number | null;
          oneWayFactor?: number | null;
          pricePremium?: number | null;
          vat?: number | null;
          deposit?: {
            __typename?: "Deposit";
            id: string;
            depositRefundMaxPenalty?: number | null;
            depositRefundCutoffDays: number;
            depositPercentage: number;
            depositActiveToDays: number;
            depositActiveFromDays: number;
          } | null;
        } | null;
      }> | null;
    }> | null;
  };
};

export type UploadFileMutationVariables = Types.Exact<{
  file: Types.Scalars["Upload"]["input"];
}>;

export type UploadFileMutation = {
  __typename?: "Mutation";
  uploadFile: {
    __typename?: "UploadedFileResponse";
    eTag: string;
    key: string;
    url: string;
  };
};

export type GetLatestAppVersionQueryVariables = Types.Exact<{
  appTypeInput: Types.AppTypeInput;
}>;

export type GetLatestAppVersionQuery = {
  __typename?: "Query";
  getLatestAppVersion: {
    __typename?: "AppVersionPayload";
    appType: Types.AppType;
    appVersionType: Types.AppVersionType;
    id: string;
    major: number;
    minor: number;
    patch: number;
  };
};

export type IsPhoneNumberValidForWebQueryVariables = Types.Exact<{
  phoneNumber: Types.Scalars["String"]["input"];
}>;

export type IsPhoneNumberValidForWebQuery = {
  __typename?: "Query";
  isPhoneNumberValidForWeb: Array<{
    __typename?: "ValidationResponse";
    errCode?: string | null;
    field?: string | null;
    isValid?: boolean | null;
    message?: string | null;
  }>;
};

export type IsEmailValidForWebQueryVariables = Types.Exact<{
  email: Types.Scalars["String"]["input"];
}>;

export type IsEmailValidForWebQuery = {
  __typename?: "Query";
  isEmailValidForWeb: Array<{
    __typename?: "ValidationResponse";
    errCode?: string | null;
    field?: string | null;
    isValid?: boolean | null;
    message?: string | null;
  }>;
};

export type IsAgentPhoneNumberExistForWebQueryVariables = Types.Exact<{
  phoneNumber: Types.Scalars["String"]["input"];
}>;

export type IsAgentPhoneNumberExistForWebQuery = {
  __typename?: "Query";
  isAgentPhoneNumberExistForWeb: {
    __typename?: "ValidationResponse";
    errCode?: string | null;
    field?: string | null;
    isValid?: boolean | null;
    message?: string | null;
  };
};

export type IsSponsorPhoneNumberExistForWebQueryVariables = Types.Exact<{
  phoneNumber: Types.Scalars["String"]["input"];
}>;

export type IsSponsorPhoneNumberExistForWebQuery = {
  __typename?: "Query";
  isSponsorPhoneNumberExistForWeb: {
    __typename?: "ValidationResponse";
    errCode?: string | null;
    field?: string | null;
    message?: string | null;
    isValid?: boolean | null;
    statusCode?: number | null;
  };
};

export type IsDriverPhoneNumberExistForWebQueryVariables = Types.Exact<{
  phoneNumber: Types.Scalars["String"]["input"];
}>;

export type IsDriverPhoneNumberExistForWebQuery = {
  __typename?: "Query";
  isDriverPhoneNumberExistForWeb: {
    __typename?: "ValidationResponse";
    errCode?: string | null;
    field?: string | null;
    message?: string | null;
    isValid?: boolean | null;
    statusCode?: number | null;
  };
};

export type IsBusAttendancePhoneNumberExistForWebQueryVariables = Types.Exact<{
  phoneNumber: Types.Scalars["String"]["input"];
}>;

export type IsBusAttendancePhoneNumberExistForWebQuery = {
  __typename?: "Query";
  isBusAttendancePhoneNumberExistForWeb: {
    __typename?: "ValidationResponse";
    errCode?: string | null;
    field?: string | null;
    message?: string | null;
    isValid?: boolean | null;
    statusCode?: number | null;
  };
};

export type GetSchoolServiceDefinitionsQueryVariables = Types.Exact<{
  schoolIds:
    | Array<Types.Scalars["String"]["input"]>
    | Types.Scalars["String"]["input"];
}>;

export type GetSchoolServiceDefinitionsQuery = {
  __typename?: "Query";
  getSchoolServiceDefinitions: Array<{
    __typename?: "SchoolWithServiceDefinitions";
    schoolId: string;
    serviceDefinitions: Array<{
      __typename?: "Service";
      id: string;
      serviceAcademicYear: number;
    }>;
  }>;
};

export const GetBulkServicesWithPriceDocument = gql`
  query getBulkServicesWithPrice(
    $getBulkServicesWithPriceInput: GetBulkServicesWithPriceInput!
  ) {
    getBulkServicesWithPrice(
      getBulkServicesWithPriceInput: $getBulkServicesWithPriceInput
    ) {
      id
      countryId
      durationInMonths
      name
      nameAr
      photo
      status
      basePrice
      basePriceWithVat
      basePriceWithVatAndModifiers
      basePriceWithVatAndModifiersAndOffer
      installmentPlans {
        id
        name
        nameAr
        status
        publishedAt
        expiredAt
        discountPercentage
        planAmount
        depositAmount
        isDefault
        paymentLineItems {
          amount
          isDeposit
        }
        installmentAmountBeforePlanDiscount
        collectionTimePeriods {
          from
          id
          name
          nameAr
          paymentDueDays
          paymentReminderFrequency
          paymentReminderStartDays
          priority
          archivedAt
        }
      }
      serviceCategoryId
      category {
        descriptionAr
        description
        id
        name
      }
      country {
        id
        nameAr
        name
        code
      }
      paymentPlansPeriods {
        id
        groupedInstallmentPlans {
          id
          isDefault
          servicePricing {
            currency
            id
            journeyBaseDistanceKM
            journeyBasePrice
            journeyIncrementalDistanceKM
            journeyIncrementalDistancePrice
            multipleKidFactor
            oneWayFactor
            pricePremium
            vat
            deposit {
              id
              depositRefundMaxPenalty
              depositRefundCutoffDays
              depositPercentage
              depositActiveToDays
              depositType
              depositPaymentDate
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetBulkServicesWithPriceQuery__
 *
 * To run a query within a React component, call `useGetBulkServicesWithPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBulkServicesWithPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBulkServicesWithPriceQuery({
 *   variables: {
 *      getBulkServicesWithPriceInput: // value for 'getBulkServicesWithPriceInput'
 *   },
 * });
 */
export function useGetBulkServicesWithPriceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBulkServicesWithPriceQuery,
    GetBulkServicesWithPriceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBulkServicesWithPriceQuery,
    GetBulkServicesWithPriceQueryVariables
  >(GetBulkServicesWithPriceDocument, options);
}
export function useGetBulkServicesWithPriceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBulkServicesWithPriceQuery,
    GetBulkServicesWithPriceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBulkServicesWithPriceQuery,
    GetBulkServicesWithPriceQueryVariables
  >(GetBulkServicesWithPriceDocument, options);
}
export function useGetBulkServicesWithPriceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetBulkServicesWithPriceQuery,
    GetBulkServicesWithPriceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetBulkServicesWithPriceQuery,
    GetBulkServicesWithPriceQueryVariables
  >(GetBulkServicesWithPriceDocument, options);
}
export type GetBulkServicesWithPriceQueryHookResult = ReturnType<
  typeof useGetBulkServicesWithPriceQuery
>;
export type GetBulkServicesWithPriceLazyQueryHookResult = ReturnType<
  typeof useGetBulkServicesWithPriceLazyQuery
>;
export type GetBulkServicesWithPriceSuspenseQueryHookResult = ReturnType<
  typeof useGetBulkServicesWithPriceSuspenseQuery
>;
export type GetBulkServicesWithPriceQueryResult = Apollo.QueryResult<
  GetBulkServicesWithPriceQuery,
  GetBulkServicesWithPriceQueryVariables
>;
export const GetServiceWithPriceDocument = gql`
  query getServiceWithPrice(
    $schoolId: String!
    $modifiers: ServicePriceModifiers!
    $offerCode: String
  ) {
    getServiceWithPrice(
      schoolId: $schoolId
      modifiers: $modifiers
      offerCode: $offerCode
    ) {
      id
      countryId
      durationInMonths
      name
      nameAr
      photo
      status
      basePrice
      basePriceWithVat
      basePriceWithVatAndModifiers
      basePriceWithVatAndModifiersAndOffer
      installmentPlans {
        id
        name
        nameAr
        status
        publishedAt
        expiredAt
        discountPercentage
        planAmount
        depositAmount
        paymentLineItems {
          amount
          isDeposit
        }
        installmentAmountBeforePlanDiscount
        collectionTimePeriods {
          from
          id
          name
          nameAr
          paymentDueDays
          paymentReminderFrequency
          paymentReminderStartDays
          priority
          archivedAt
        }
      }
      serviceCategoryId
      category {
        descriptionAr
        description
        id
        name
      }
      country {
        id
        nameAr
        name
        code
      }
      paymentPlansPeriods {
        id
        groupedInstallmentPlans {
          id
          servicePricing {
            currency
            id
            journeyBaseDistanceKM
            journeyBasePrice
            journeyIncrementalDistanceKM
            journeyIncrementalDistancePrice
            multipleKidFactor
            oneWayFactor
            pricePremium
            vat
            deposit {
              id
              depositRefundMaxPenalty
              depositRefundCutoffDays
              depositPercentage
              depositActiveToDays
              depositActiveFromDays
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetServiceWithPriceQuery__
 *
 * To run a query within a React component, call `useGetServiceWithPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServiceWithPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServiceWithPriceQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *      modifiers: // value for 'modifiers'
 *      offerCode: // value for 'offerCode'
 *   },
 * });
 */
export function useGetServiceWithPriceQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetServiceWithPriceQuery,
    GetServiceWithPriceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetServiceWithPriceQuery,
    GetServiceWithPriceQueryVariables
  >(GetServiceWithPriceDocument, options);
}
export function useGetServiceWithPriceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServiceWithPriceQuery,
    GetServiceWithPriceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetServiceWithPriceQuery,
    GetServiceWithPriceQueryVariables
  >(GetServiceWithPriceDocument, options);
}
export function useGetServiceWithPriceSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetServiceWithPriceQuery,
    GetServiceWithPriceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetServiceWithPriceQuery,
    GetServiceWithPriceQueryVariables
  >(GetServiceWithPriceDocument, options);
}
export type GetServiceWithPriceQueryHookResult = ReturnType<
  typeof useGetServiceWithPriceQuery
>;
export type GetServiceWithPriceLazyQueryHookResult = ReturnType<
  typeof useGetServiceWithPriceLazyQuery
>;
export type GetServiceWithPriceSuspenseQueryHookResult = ReturnType<
  typeof useGetServiceWithPriceSuspenseQuery
>;
export type GetServiceWithPriceQueryResult = Apollo.QueryResult<
  GetServiceWithPriceQuery,
  GetServiceWithPriceQueryVariables
>;
export const UploadFileDocument = gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(file: $file) {
      eTag
      key
      url
    }
  }
`;
export type UploadFileMutationFn = Apollo.MutationFunction<
  UploadFileMutation,
  UploadFileMutationVariables
>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadFileMutation,
    UploadFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(
    UploadFileDocument,
    options
  );
}
export type UploadFileMutationHookResult = ReturnType<
  typeof useUploadFileMutation
>;
export type UploadFileMutationResult =
  Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<
  UploadFileMutation,
  UploadFileMutationVariables
>;
export const GetLatestAppVersionDocument = gql`
  query GetLatestAppVersion($appTypeInput: AppTypeInput!) {
    getLatestAppVersion(appTypeInput: $appTypeInput) {
      appType
      appVersionType
      id
      major
      minor
      patch
    }
  }
`;

/**
 * __useGetLatestAppVersionQuery__
 *
 * To run a query within a React component, call `useGetLatestAppVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestAppVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestAppVersionQuery({
 *   variables: {
 *      appTypeInput: // value for 'appTypeInput'
 *   },
 * });
 */
export function useGetLatestAppVersionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLatestAppVersionQuery,
    GetLatestAppVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLatestAppVersionQuery,
    GetLatestAppVersionQueryVariables
  >(GetLatestAppVersionDocument, options);
}
export function useGetLatestAppVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestAppVersionQuery,
    GetLatestAppVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestAppVersionQuery,
    GetLatestAppVersionQueryVariables
  >(GetLatestAppVersionDocument, options);
}
export function useGetLatestAppVersionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetLatestAppVersionQuery,
    GetLatestAppVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetLatestAppVersionQuery,
    GetLatestAppVersionQueryVariables
  >(GetLatestAppVersionDocument, options);
}
export type GetLatestAppVersionQueryHookResult = ReturnType<
  typeof useGetLatestAppVersionQuery
>;
export type GetLatestAppVersionLazyQueryHookResult = ReturnType<
  typeof useGetLatestAppVersionLazyQuery
>;
export type GetLatestAppVersionSuspenseQueryHookResult = ReturnType<
  typeof useGetLatestAppVersionSuspenseQuery
>;
export type GetLatestAppVersionQueryResult = Apollo.QueryResult<
  GetLatestAppVersionQuery,
  GetLatestAppVersionQueryVariables
>;
export const IsPhoneNumberValidForWebDocument = gql`
  query IsPhoneNumberValidForWeb($phoneNumber: String!) {
    isPhoneNumberValidForWeb(phoneNumber: $phoneNumber) {
      errCode
      field
      isValid
      message
    }
  }
`;

/**
 * __useIsPhoneNumberValidForWebQuery__
 *
 * To run a query within a React component, call `useIsPhoneNumberValidForWebQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsPhoneNumberValidForWebQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsPhoneNumberValidForWebQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useIsPhoneNumberValidForWebQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsPhoneNumberValidForWebQuery,
    IsPhoneNumberValidForWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IsPhoneNumberValidForWebQuery,
    IsPhoneNumberValidForWebQueryVariables
  >(IsPhoneNumberValidForWebDocument, options);
}
export function useIsPhoneNumberValidForWebLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsPhoneNumberValidForWebQuery,
    IsPhoneNumberValidForWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IsPhoneNumberValidForWebQuery,
    IsPhoneNumberValidForWebQueryVariables
  >(IsPhoneNumberValidForWebDocument, options);
}
export function useIsPhoneNumberValidForWebSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IsPhoneNumberValidForWebQuery,
    IsPhoneNumberValidForWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    IsPhoneNumberValidForWebQuery,
    IsPhoneNumberValidForWebQueryVariables
  >(IsPhoneNumberValidForWebDocument, options);
}
export type IsPhoneNumberValidForWebQueryHookResult = ReturnType<
  typeof useIsPhoneNumberValidForWebQuery
>;
export type IsPhoneNumberValidForWebLazyQueryHookResult = ReturnType<
  typeof useIsPhoneNumberValidForWebLazyQuery
>;
export type IsPhoneNumberValidForWebSuspenseQueryHookResult = ReturnType<
  typeof useIsPhoneNumberValidForWebSuspenseQuery
>;
export type IsPhoneNumberValidForWebQueryResult = Apollo.QueryResult<
  IsPhoneNumberValidForWebQuery,
  IsPhoneNumberValidForWebQueryVariables
>;
export const IsEmailValidForWebDocument = gql`
  query IsEmailValidForWeb($email: String!) {
    isEmailValidForWeb(email: $email) {
      errCode
      field
      isValid
      message
    }
  }
`;

/**
 * __useIsEmailValidForWebQuery__
 *
 * To run a query within a React component, call `useIsEmailValidForWebQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEmailValidForWebQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEmailValidForWebQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useIsEmailValidForWebQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsEmailValidForWebQuery,
    IsEmailValidForWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IsEmailValidForWebQuery,
    IsEmailValidForWebQueryVariables
  >(IsEmailValidForWebDocument, options);
}
export function useIsEmailValidForWebLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsEmailValidForWebQuery,
    IsEmailValidForWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IsEmailValidForWebQuery,
    IsEmailValidForWebQueryVariables
  >(IsEmailValidForWebDocument, options);
}
export function useIsEmailValidForWebSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IsEmailValidForWebQuery,
    IsEmailValidForWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    IsEmailValidForWebQuery,
    IsEmailValidForWebQueryVariables
  >(IsEmailValidForWebDocument, options);
}
export type IsEmailValidForWebQueryHookResult = ReturnType<
  typeof useIsEmailValidForWebQuery
>;
export type IsEmailValidForWebLazyQueryHookResult = ReturnType<
  typeof useIsEmailValidForWebLazyQuery
>;
export type IsEmailValidForWebSuspenseQueryHookResult = ReturnType<
  typeof useIsEmailValidForWebSuspenseQuery
>;
export type IsEmailValidForWebQueryResult = Apollo.QueryResult<
  IsEmailValidForWebQuery,
  IsEmailValidForWebQueryVariables
>;
export const IsAgentPhoneNumberExistForWebDocument = gql`
  query IsAgentPhoneNumberExistForWeb($phoneNumber: String!) {
    isAgentPhoneNumberExistForWeb(phoneNumber: $phoneNumber) {
      errCode
      field
      isValid
      message
    }
  }
`;

/**
 * __useIsAgentPhoneNumberExistForWebQuery__
 *
 * To run a query within a React component, call `useIsAgentPhoneNumberExistForWebQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsAgentPhoneNumberExistForWebQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsAgentPhoneNumberExistForWebQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useIsAgentPhoneNumberExistForWebQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsAgentPhoneNumberExistForWebQuery,
    IsAgentPhoneNumberExistForWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IsAgentPhoneNumberExistForWebQuery,
    IsAgentPhoneNumberExistForWebQueryVariables
  >(IsAgentPhoneNumberExistForWebDocument, options);
}
export function useIsAgentPhoneNumberExistForWebLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsAgentPhoneNumberExistForWebQuery,
    IsAgentPhoneNumberExistForWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IsAgentPhoneNumberExistForWebQuery,
    IsAgentPhoneNumberExistForWebQueryVariables
  >(IsAgentPhoneNumberExistForWebDocument, options);
}
export function useIsAgentPhoneNumberExistForWebSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IsAgentPhoneNumberExistForWebQuery,
    IsAgentPhoneNumberExistForWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    IsAgentPhoneNumberExistForWebQuery,
    IsAgentPhoneNumberExistForWebQueryVariables
  >(IsAgentPhoneNumberExistForWebDocument, options);
}
export type IsAgentPhoneNumberExistForWebQueryHookResult = ReturnType<
  typeof useIsAgentPhoneNumberExistForWebQuery
>;
export type IsAgentPhoneNumberExistForWebLazyQueryHookResult = ReturnType<
  typeof useIsAgentPhoneNumberExistForWebLazyQuery
>;
export type IsAgentPhoneNumberExistForWebSuspenseQueryHookResult = ReturnType<
  typeof useIsAgentPhoneNumberExistForWebSuspenseQuery
>;
export type IsAgentPhoneNumberExistForWebQueryResult = Apollo.QueryResult<
  IsAgentPhoneNumberExistForWebQuery,
  IsAgentPhoneNumberExistForWebQueryVariables
>;
export const IsSponsorPhoneNumberExistForWebDocument = gql`
  query IsSponsorPhoneNumberExistForWeb($phoneNumber: String!) {
    isSponsorPhoneNumberExistForWeb(phoneNumber: $phoneNumber) {
      errCode
      field
      message
      isValid
      statusCode
    }
  }
`;

/**
 * __useIsSponsorPhoneNumberExistForWebQuery__
 *
 * To run a query within a React component, call `useIsSponsorPhoneNumberExistForWebQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsSponsorPhoneNumberExistForWebQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsSponsorPhoneNumberExistForWebQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useIsSponsorPhoneNumberExistForWebQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsSponsorPhoneNumberExistForWebQuery,
    IsSponsorPhoneNumberExistForWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IsSponsorPhoneNumberExistForWebQuery,
    IsSponsorPhoneNumberExistForWebQueryVariables
  >(IsSponsorPhoneNumberExistForWebDocument, options);
}
export function useIsSponsorPhoneNumberExistForWebLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsSponsorPhoneNumberExistForWebQuery,
    IsSponsorPhoneNumberExistForWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IsSponsorPhoneNumberExistForWebQuery,
    IsSponsorPhoneNumberExistForWebQueryVariables
  >(IsSponsorPhoneNumberExistForWebDocument, options);
}
export function useIsSponsorPhoneNumberExistForWebSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IsSponsorPhoneNumberExistForWebQuery,
    IsSponsorPhoneNumberExistForWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    IsSponsorPhoneNumberExistForWebQuery,
    IsSponsorPhoneNumberExistForWebQueryVariables
  >(IsSponsorPhoneNumberExistForWebDocument, options);
}
export type IsSponsorPhoneNumberExistForWebQueryHookResult = ReturnType<
  typeof useIsSponsorPhoneNumberExistForWebQuery
>;
export type IsSponsorPhoneNumberExistForWebLazyQueryHookResult = ReturnType<
  typeof useIsSponsorPhoneNumberExistForWebLazyQuery
>;
export type IsSponsorPhoneNumberExistForWebSuspenseQueryHookResult = ReturnType<
  typeof useIsSponsorPhoneNumberExistForWebSuspenseQuery
>;
export type IsSponsorPhoneNumberExistForWebQueryResult = Apollo.QueryResult<
  IsSponsorPhoneNumberExistForWebQuery,
  IsSponsorPhoneNumberExistForWebQueryVariables
>;
export const IsDriverPhoneNumberExistForWebDocument = gql`
  query IsDriverPhoneNumberExistForWeb($phoneNumber: String!) {
    isDriverPhoneNumberExistForWeb(phoneNumber: $phoneNumber) {
      errCode
      field
      message
      isValid
      statusCode
    }
  }
`;

/**
 * __useIsDriverPhoneNumberExistForWebQuery__
 *
 * To run a query within a React component, call `useIsDriverPhoneNumberExistForWebQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsDriverPhoneNumberExistForWebQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsDriverPhoneNumberExistForWebQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useIsDriverPhoneNumberExistForWebQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsDriverPhoneNumberExistForWebQuery,
    IsDriverPhoneNumberExistForWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IsDriverPhoneNumberExistForWebQuery,
    IsDriverPhoneNumberExistForWebQueryVariables
  >(IsDriverPhoneNumberExistForWebDocument, options);
}
export function useIsDriverPhoneNumberExistForWebLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsDriverPhoneNumberExistForWebQuery,
    IsDriverPhoneNumberExistForWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IsDriverPhoneNumberExistForWebQuery,
    IsDriverPhoneNumberExistForWebQueryVariables
  >(IsDriverPhoneNumberExistForWebDocument, options);
}
export function useIsDriverPhoneNumberExistForWebSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IsDriverPhoneNumberExistForWebQuery,
    IsDriverPhoneNumberExistForWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    IsDriverPhoneNumberExistForWebQuery,
    IsDriverPhoneNumberExistForWebQueryVariables
  >(IsDriverPhoneNumberExistForWebDocument, options);
}
export type IsDriverPhoneNumberExistForWebQueryHookResult = ReturnType<
  typeof useIsDriverPhoneNumberExistForWebQuery
>;
export type IsDriverPhoneNumberExistForWebLazyQueryHookResult = ReturnType<
  typeof useIsDriverPhoneNumberExistForWebLazyQuery
>;
export type IsDriverPhoneNumberExistForWebSuspenseQueryHookResult = ReturnType<
  typeof useIsDriverPhoneNumberExistForWebSuspenseQuery
>;
export type IsDriverPhoneNumberExistForWebQueryResult = Apollo.QueryResult<
  IsDriverPhoneNumberExistForWebQuery,
  IsDriverPhoneNumberExistForWebQueryVariables
>;
export const IsBusAttendancePhoneNumberExistForWebDocument = gql`
  query IsBusAttendancePhoneNumberExistForWeb($phoneNumber: String!) {
    isBusAttendancePhoneNumberExistForWeb(phoneNumber: $phoneNumber) {
      errCode
      field
      message
      isValid
      statusCode
    }
  }
`;

/**
 * __useIsBusAttendancePhoneNumberExistForWebQuery__
 *
 * To run a query within a React component, call `useIsBusAttendancePhoneNumberExistForWebQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsBusAttendancePhoneNumberExistForWebQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsBusAttendancePhoneNumberExistForWebQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useIsBusAttendancePhoneNumberExistForWebQuery(
  baseOptions: Apollo.QueryHookOptions<
    IsBusAttendancePhoneNumberExistForWebQuery,
    IsBusAttendancePhoneNumberExistForWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    IsBusAttendancePhoneNumberExistForWebQuery,
    IsBusAttendancePhoneNumberExistForWebQueryVariables
  >(IsBusAttendancePhoneNumberExistForWebDocument, options);
}
export function useIsBusAttendancePhoneNumberExistForWebLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsBusAttendancePhoneNumberExistForWebQuery,
    IsBusAttendancePhoneNumberExistForWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    IsBusAttendancePhoneNumberExistForWebQuery,
    IsBusAttendancePhoneNumberExistForWebQueryVariables
  >(IsBusAttendancePhoneNumberExistForWebDocument, options);
}
export function useIsBusAttendancePhoneNumberExistForWebSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IsBusAttendancePhoneNumberExistForWebQuery,
    IsBusAttendancePhoneNumberExistForWebQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    IsBusAttendancePhoneNumberExistForWebQuery,
    IsBusAttendancePhoneNumberExistForWebQueryVariables
  >(IsBusAttendancePhoneNumberExistForWebDocument, options);
}
export type IsBusAttendancePhoneNumberExistForWebQueryHookResult = ReturnType<
  typeof useIsBusAttendancePhoneNumberExistForWebQuery
>;
export type IsBusAttendancePhoneNumberExistForWebLazyQueryHookResult =
  ReturnType<typeof useIsBusAttendancePhoneNumberExistForWebLazyQuery>;
export type IsBusAttendancePhoneNumberExistForWebSuspenseQueryHookResult =
  ReturnType<typeof useIsBusAttendancePhoneNumberExistForWebSuspenseQuery>;
export type IsBusAttendancePhoneNumberExistForWebQueryResult =
  Apollo.QueryResult<
    IsBusAttendancePhoneNumberExistForWebQuery,
    IsBusAttendancePhoneNumberExistForWebQueryVariables
  >;
export const GetSchoolServiceDefinitionsDocument = gql`
  query getSchoolServiceDefinitions($schoolIds: [String!]!) {
    getSchoolServiceDefinitions(schoolIds: $schoolIds) {
      schoolId
      serviceDefinitions {
        id
        serviceAcademicYear
      }
    }
  }
`;

/**
 * __useGetSchoolServiceDefinitionsQuery__
 *
 * To run a query within a React component, call `useGetSchoolServiceDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSchoolServiceDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSchoolServiceDefinitionsQuery({
 *   variables: {
 *      schoolIds: // value for 'schoolIds'
 *   },
 * });
 */
export function useGetSchoolServiceDefinitionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSchoolServiceDefinitionsQuery,
    GetSchoolServiceDefinitionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSchoolServiceDefinitionsQuery,
    GetSchoolServiceDefinitionsQueryVariables
  >(GetSchoolServiceDefinitionsDocument, options);
}
export function useGetSchoolServiceDefinitionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSchoolServiceDefinitionsQuery,
    GetSchoolServiceDefinitionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSchoolServiceDefinitionsQuery,
    GetSchoolServiceDefinitionsQueryVariables
  >(GetSchoolServiceDefinitionsDocument, options);
}
export function useGetSchoolServiceDefinitionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSchoolServiceDefinitionsQuery,
    GetSchoolServiceDefinitionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetSchoolServiceDefinitionsQuery,
    GetSchoolServiceDefinitionsQueryVariables
  >(GetSchoolServiceDefinitionsDocument, options);
}
export type GetSchoolServiceDefinitionsQueryHookResult = ReturnType<
  typeof useGetSchoolServiceDefinitionsQuery
>;
export type GetSchoolServiceDefinitionsLazyQueryHookResult = ReturnType<
  typeof useGetSchoolServiceDefinitionsLazyQuery
>;
export type GetSchoolServiceDefinitionsSuspenseQueryHookResult = ReturnType<
  typeof useGetSchoolServiceDefinitionsSuspenseQuery
>;
export type GetSchoolServiceDefinitionsQueryResult = Apollo.QueryResult<
  GetSchoolServiceDefinitionsQuery,
  GetSchoolServiceDefinitionsQueryVariables
>;
