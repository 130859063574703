/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import {
  initialState,
  RouteState,
} from "~/modules/panel-system/route-panel/state/initState";

const REDUCER_NAME = "routePanel";

export const routePanelSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setExistingRouteOptimizer: (state, action) => {
      state.existingRouteOptimizer = action.payload;
    },
    setRouteOptimizationConfigUpdated: (state, action) => {
      state.isRouteOptimizationConfigUpdated = action.payload;
    },
  },
});

export const getExistingRouteOptimizer = (state: RouteState) =>
  state?.existingRouteOptimizer;

export const { setExistingRouteOptimizer, setRouteOptimizationConfigUpdated } =
  routePanelSlice.actions;

const reducer = { [REDUCER_NAME]: routePanelSlice.reducer };

export default reducer;
