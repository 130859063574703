import { AppDispatch } from "~/state/store";

import { WhoAmIQuery } from "~/core/generated/main/get-one.generated";
import { setCurrentUser, setRoles } from "~/core/state/coreSlice";

export const setCurrentUserData = (
  dispatch: AppDispatch,
  data: WhoAmIQuery
) => {
  // filter out __typename
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
  const { __typename, roles, ...whoAmI } = data.whoAmI;
  const roleNames = roles?.map(({ role }) => role.name) || [];
  dispatch(setCurrentUser({ ...whoAmI, roles: roleNames }));
  dispatch(setRoles(roleNames));
};
