import { PanelNameType } from "~/utils/accessControl/crudAcl";

import { PermissionAction } from "~/gql/main/types.generated";

import { agentActionsTabs } from "~/modules/panel-system/agent-panel/config";
import { busAttendantActionsTabs } from "~/modules/panel-system/bus-attendant-panel/config";
import { cashOutActionsTabs } from "~/modules/panel-system/cash-out-request-panel/config";
import { driverActionsTabs } from "~/modules/panel-system/driver-panel/config";
import { fleetActionsTabs } from "~/modules/panel-system/fleet-panel/config";
import { guardiansActionsTabs } from "~/modules/panel-system/guardians-panel/config";
import { leaveActionsTabs } from "~/modules/panel-system/leave-panel/config";
import { routeActionsTabs } from "~/modules/panel-system/route-panel/config";
import { safetyActionsTabs } from "~/modules/panel-system/safety-events-panel/config";
import { schoolActionsTabs } from "~/modules/panel-system/school-panel/config";
import { schoolRegistrationActionsTabs } from "~/modules/panel-system/school-registration-panel/config";
import { serviceActionsTabs } from "~/modules/panel-system/service-panel/config";
import { serviceProviderActionsTabs } from "~/modules/panel-system/service-provider-panel/config";
import { tripActionsTabs } from "~/modules/panel-system/trip-panel/config";
import { vehicleActionsTabs } from "~/modules/panel-system/vehicle-panel/config";

type MutationAction = PermissionAction.WRITE | PermissionAction.UPDATE;

export type FieldsPathPermissionType = {
  [key in PanelNameType]?: {
    [field: string]: string;
  };
};
export type PermissionsPerPanelAction = {
  [key in PanelNameType]?: {
    [key in MutationAction]?: {
      [tab: string]: boolean;
    };
  };
};

export const panelTabsDependentFields: PermissionsPerPanelAction = {
  [PanelNameType.AGENT]: agentActionsTabs,
  [PanelNameType.SCHOOL_REGISTRATION]: schoolRegistrationActionsTabs,
  [PanelNameType.LEAVE]: leaveActionsTabs,
  [PanelNameType.SCHOOL]: schoolActionsTabs,
  [PanelNameType.VEHICLE]: vehicleActionsTabs,
  [PanelNameType.SERVICE_PROVIDER]: serviceProviderActionsTabs,
  [PanelNameType.SERVICE]: serviceActionsTabs,
  [PanelNameType.DRIVER]: driverActionsTabs,
  [PanelNameType.GUARDIANS]: guardiansActionsTabs,
  [PanelNameType.TRIP]: tripActionsTabs,
  [PanelNameType.FLEET]: fleetActionsTabs,
  [PanelNameType.CASH_OUT_REQUESTS]: cashOutActionsTabs,
  [PanelNameType.BUS_ATTENDANT]: busAttendantActionsTabs,
  [PanelNameType.SAFETY_EVENTS]: safetyActionsTabs,
  [PanelNameType.ROUTE]: routeActionsTabs,
};
