import { emuhwService } from "~/config/appConfig";

import { conditionalEnvironment, getEnvironment } from "~/utils/environment";
import { composeUrl } from "~/utils/url/composeUrl";

const routes = {
  opsDashboard:
    conditionalEnvironment({
      local: "https://admin.emushrif.link",
      development: "https://admin.emushrif.link",
      uat: "https://admin.emushrif.click",
      production: "https://admin.emushrif.com",
    }) || "https://admin.emushrif.com",
  googleMaps: "https://maps.google.com",
  googleAccounts: "https://accounts.google.com",
  googleApisGlob: "https://*.googleapis.com",
  googleUserContentGlob: "https://*.googleusercontent.com",
  googleStaticGlob: "https://*.gstatic.com",
  googleFontsStatic: "https://fonts.gstatic.com",
  sentryGlob: "https://*.ingest.sentry.io",
  googleMapsStatic: "https://maps.gstatic.com",
  awsS3Bucket: `${getEnvironment().NEXT_PUBLIC_S3_IMAGES_BUCKET}.${
    getEnvironment().NEXT_PUBLIC_S3_IMAGES_HOST
  }.${getEnvironment().NEXT_PUBLIC_AMAZON_HOST}`,
  emHwServiceHttp: composeUrl({
    hostname: emuhwService.hostname,
    protocol: emuhwService.protocolHttp,
    port: emuhwService.port,
  }),
  emHwServiceWs: composeUrl({
    hostname: emuhwService.hostname,
    protocol: emuhwService.protocolWs,
    port: emuhwService.port,
  }),
};

export default routes;
