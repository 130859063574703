import { TabsType } from "src/types";

import { featureFlags } from "~/config/appConfig";

import { PanelNameType } from "~/utils/accessControl/crudAcl";
import getTabsPathDependent from "~/utils/getTabsPathDependant";

import { labels } from "~/modules/panel-system/bus-attendant-panel/locale";

type TabTitleKeys = keyof typeof labels;

// all the tabs including create and update
const tabs: TabsType<TabTitleKeys> = {
  generalInfo: {
    id: "generalInfo",
    title: "panel.tab.generalInfo",
  },
  bankDetails: {
    id: "bankDetails",
    title: "panel.tab.bankDetails",
  },
  status: {
    id: "status",
    title: "panel.tab.smartTagStatus",
  },
  compliance: {
    id: "compliance",
    title: "panel.tab.compliance",
  },
  hoursWorked: {
    id: "hoursWorked",
    title: "panel.tab.hoursWorked",
  },
};

export const panelConfig: PanelConfigType = {
  id: PanelNameType.BUS_ATTENDANT,
  name: "panel.title",
  createTabs: [
    tabs.generalInfo,
    ...(featureFlags.busAttendantPanel.pncRequirementsBasicDetails
      ? [tabs.bankDetails]
      : []),
    tabs.status,
    ...(featureFlags.busAttendantPanel.compliance ? [tabs.compliance] : []),
    ...(featureFlags.busAttendantPanel.hoursWorked ? [tabs.hoursWorked] : []),
  ],
  updateTabs: [
    tabs.generalInfo,
    ...(featureFlags.busAttendantPanel.pncRequirementsBasicDetails
      ? [tabs.bankDetails]
      : []),
    tabs.status,
    ...(featureFlags.busAttendantPanel.compliance ? [tabs.compliance] : []),
    ...(featureFlags.busAttendantPanel.hoursWorked ? [tabs.hoursWorked] : []),
  ],
};

export const busAttendantActionsTabs = getTabsPathDependent(
  panelConfig.createTabs,
  panelConfig.updateTabs
);

export type PanelConfigType = {
  id: PanelNameType;
  name: string;
  createTabs: (typeof tabs)[keyof typeof tabs][];
  updateTabs: (typeof tabs)[keyof typeof tabs][];
};
