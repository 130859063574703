interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  objectFit?: "contain" | "cover" | "fill" | "none" | "scale-down";
}

export interface StaticImageData {
  src: string;
  height: number;
  width: number;
  blurDataURL?: string;
  blurWidth?: number;
  blurHeight?: number;
}

// TODO: improve this
const Image: React.FC<ImageProps> = ({
  src,
  alt,
  width,
  height,
  objectFit = "cover",
  ...props
}) => {
  return (
    <img
      src={src}
      alt={alt}
      width={width}
      height={height}
      style={{ objectFit }}
      // {...(priority ? { fetchPriority: "high" } : {})}
      {...props}
    />
  );
};

export default Image;
