import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Addchart";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import MuiDrawer, { drawerClasses } from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { getEnvironment } from "~/utils/environment";

import {
  // useAppDispatch,
  useAppSelector,
} from "~/state/hooks";

import routes from "~/routes/main";

import AddWorkspaceModal from "~/core/components/AddWorkspaceModal";
import Image from "~/core/components/Image";
import SideNavIcons from "~/core/components/SideNavIcons";
import useCopy from "~/core/locale";
import {
  // createWorkspace,
  selectWorkspacePages,
} from "~/core/state/workspaceSlice";
import { utilityObject } from "~/core/styles/utilities";

import LOGO from "~/assets/logo/SaarLogo.svg";

export const DRAWER_OPENED_WIDTH = 240;
export const DRAWER_CLOSED_WIDTH = 72;

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_OPENED_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: DRAWER_CLOSED_WIDTH,
  // [theme.breakpoints.up("sm")]: {
  //   width: DRAWER_CLOSED_WIDTH,
  // },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: DRAWER_OPENED_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    [`.${drawerClasses.paper}`]: openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    [`.${drawerClasses.paper}`]: closedMixin(theme),
  }),
}));

const SideNavList = styled(ListItem)(({ theme }) => ({
  marginBottom: theme.spacing(),
}));

const SideNavHead = styled("div")(() => ({
  marginTop: "32px",
  width: "100%",
  ...utilityObject.columnFlexCenter,
}));

export const SideNav = (props: any) => {
  const { isDrawerOpen } = props;
  // const router = useRouter();
  // const pathName = usePathname();
  const location = useLocation();
  const navigate = useNavigate();
  const pathName = location.pathname;
  // const dispatch = useAppDispatch();
  const workspaces = useAppSelector(selectWorkspacePages);

  const [isAddWorkspaceModalOpen, setIsAddWorkspaceModalOpen] = useState(false);
  const [editableWorkspaceId, setEditableWorkspaceId] = useState<string | null>(
    ""
  );

  const onEditNewWorkspace = (id: string) => () => {
    setEditableWorkspaceId(id);
    setIsAddWorkspaceModalOpen(true);
  };

  const onAddNewWorkspace = () => {
    // event.preventDefault();
    setIsAddWorkspaceModalOpen(true);
    setEditableWorkspaceId("");
  };

  const onCloseAddWorkspaceModal = () => {
    setIsAddWorkspaceModalOpen(false);
    setEditableWorkspaceId("");
  };

  const { translate } = useCopy();

  return (
    <>
      <Drawer
        variant="permanent"
        open={isDrawerOpen}
        sx={{
          overflow: "hidden",
        }}
      >
        <SideNavHead>
          <Image
            src={LOGO}
            width={isDrawerOpen ? 100 : 70}
            height={isDrawerOpen ? 100 : 70}
            style={{ objectFit: "contain" }}
            alt="Saar logo"
          />
        </SideNavHead>
        <List
          sx={{
            overflowY: "auto",
            flex: 1,
          }}
        >
          {workspaces &&
            Object.values(workspaces).map((workspace) => {
              const pageSlug = `${routes.panels}/${workspace.id}`;

              return (
                <SideNavList
                  key={workspace.id}
                  sx={{
                    color: pathName === pageSlug ? "primary.main" : "",
                    "&:hover .edit-workspace-button": {
                      display: "flex",
                    },
                  }}
                  disablePadding
                  secondaryAction={
                    isDrawerOpen && (
                      <IconButton
                        className="edit-workspace-button"
                        edge="end"
                        aria-label="edit"
                        onClick={onEditNewWorkspace(workspace?.id)}
                        sx={{
                          display: "none",
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    )
                  }
                >
                  <ListItemButton
                    selected={pathName === pageSlug}
                    onClick={() => {
                      navigate(pageSlug);
                    }}
                  >
                    <ListItemIcon>
                      <SideNavIcons option={workspace.icon} />
                    </ListItemIcon>
                    <ListItemText primary={workspace.name} />
                  </ListItemButton>
                </SideNavList>
              );
            })}

          <Divider />
          <SideNavList
            disablePadding
            sx={{
              color: "primary.main",
            }}
          >
            <ListItemButton onClick={onAddNewWorkspace}>
              <ListItemIcon
                sx={{
                  color: "secondary.main",
                }}
              >
                <AddIcon />
              </ListItemIcon>
              <ListItemText
                sx={{
                  color: "secondary.main",
                }}
                // sx={{ marginLeft: 3 }}
                primary={translate("menu.button.createWorkspace")}
              />
            </ListItemButton>
          </SideNavList>
        </List>

        <List
          sx={{
            padding: (theme) => theme.spacing(1, 0),
          }}
        >
          <ListItem>
            <Typography variant="caption" color="textSecondary">
              {getEnvironment().NEXT_PUBLIC_APP_VERSION}
            </Typography>
          </ListItem>
        </List>
      </Drawer>

      <AddWorkspaceModal
        open={isAddWorkspaceModalOpen}
        onClose={onCloseAddWorkspaceModal}
        editWorkspaceId={editableWorkspaceId}
      />
    </>
  );
};
export type SideNavProps = {
  menu: Array<any>;
  expandSideNav: boolean;
};
