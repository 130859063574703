import { TabsType } from "src/types";

import { PanelNameType } from "~/utils/accessControl/crudAcl";
import getTabsPathDependent from "~/utils/getTabsPathDependant";

import { labels } from "~/modules/panel-system/service-provider-panel/locale";

type TabTitleKeys = keyof typeof labels;

// all the tabs including create and update
const tabs: TabsType<TabTitleKeys> = {
  generalInfo: {
    id: "generalInfo",
    title: "panel.tab.generalInfo",
  },
  // bankDetails: {
  //   id: "bankDetails",
  //   title: "panel.tab.bankDetails",
  // },
  status: {
    id: "status",
    title: "panel.tab.status",
  },
  focalPoint: {
    id: "focalPoint",
    title: "panel.tab.focalPoint",
  },
};

export const panelConfig: PanelConfigType = {
  id: PanelNameType.SERVICE_PROVIDER,
  name: "panel.title",
  createTabs: [tabs.generalInfo, tabs.status, tabs.focalPoint],
  updateTabs: [tabs.generalInfo, tabs.status, tabs.focalPoint],
};

export const serviceProviderActionsTabs = getTabsPathDependent(
  panelConfig.createTabs,
  panelConfig.updateTabs
);
export type PanelConfigType = {
  id: PanelNameType;
  name: string;
  createTabs: (typeof tabs)[keyof typeof tabs][];
  updateTabs: (typeof tabs)[keyof typeof tabs][];
};
