import { ApolloClient, ApolloLink, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { SentryLink } from "apollo-link-sentry";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { createUploadLink } from "apollo-upload-client";
import { differenceInSeconds } from "date-fns";
import { CloseCode, createClient } from "graphql-ws";

import tokenExpiration from "~/utils/accessControl/tokenExpiration";
import { logout } from "~/utils/accessControl/useLogout";
import { getEnvironment } from "~/utils/environment";
import { getCookie, setCookie } from "~/utils/storage/cookiesHelpers";

import { store } from "~/state/store";

import { removeAccessToken, setAccessToken } from "~/core/state/coreSlice";

const isDev = getEnvironment().DEV;

// indicates that the server closed the connection because of
// an auth problem. it indicates that the token should refresh
let shouldRefreshToken = false,
  // the socket close timeout due to token expiry
  tokenExpiryTimeout: ReturnType<typeof setTimeout> | undefined = undefined;

export const cache = new InMemoryCache({
  resultCaching: false,
});

export const clientFactory = ({
  httpUrl,
  wsUrl,
  disableAuth = false,
  authUrl,
  connectToDevTools = false,
}: {
  httpUrl: string;
  wsUrl: string;
  disableAuth?: boolean;
  authUrl?: string;
  connectToDevTools?: boolean;
}) => {
  // const httpLink = createHttpLink({
  //   uri: httpUrl,
  // });
  const httpLink = createUploadLink({
    uri: httpUrl,
    headers: {
      /**
       * Needed for CSRF protection on Apollo Server
       * More info: https://www.apollographql.com/docs/apollo-server/security/cors/#graphql-upload
       */
      // eslint-disable-next-line @typescript-eslint/naming-convention
      "apollo-require-preflight": "true",
    },
  });

  const refreshCurrentToken = async () => {
    const refreshToken = getCookie("refreshToken");

    // Use authUrl if it is provided, otherwise use httpUrl
    const refreshTokenMutation = await fetch(authUrl ?? httpUrl, {
      method: "POST",
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */
        "content-type": "application/json",
      },
      body: JSON.stringify({
        query: `
          mutation RefreshToken($refreshTokenInput: RefreshTokenInput!) {
            refreshToken(refreshTokenInput: $refreshTokenInput) {
              accessToken,
              refreshToken
            }
          }
          `,
        variables: {
          refreshTokenInput: {
            refreshToken,
          },
        },
      }),
    });
    const response = refreshTokenMutation.json();
    return response;
  };

  const storeAccessToken = (accessToken = "") => {
    if (accessToken) {
      store.dispatch(setAccessToken(accessToken));
    } else {
      clearAccessToken();
    }
  };

  const getAccessToken = () => {
    const state = store.getState();
    const { accessToken } = state.user?.currentUser || {};

    return accessToken;
  };

  const getCurrentTokenExpiresIn = () => {
    const expiryDate = tokenExpiration(getAccessToken());
    // console.log("socket expiry", {
    //   expiryDate,
    //   today: new Date(),
    //   inSeconds: differenceInSeconds(expiryDate, new Date()),
    // });
    const expiryInSeconds = differenceInSeconds(expiryDate, new Date());
    return expiryInSeconds;
  };

  const authLink = setContext(async (___, { headers }) => {
    // get the authentication token from local storage if it exists
    const state = store.getState();
    // Get token from redux store
    const token = state?.user?.currentUser?.accessToken;
    const authHeader = {
      ...headers,
    };
    if (token) {
      authHeader.authorization = `Bearer ${token}`;
    }
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...authHeader,
      },
    };
  });

  const wsLink =
    typeof window !== "undefined"
      ? new GraphQLWsLink(
          createClient({
            // url: "ws://localhost:7878/graphql",
            url: wsUrl,
            connectionParams: async () => {
              if (shouldRefreshToken) {
                // console.log("socket get refresh token");
                // refresh the token because it is no longer valid
                const response = await refreshCurrentToken();

                const { accessToken, refreshToken } =
                  response?.data?.refreshToken || {};

                if (accessToken) {
                  const refreshExp = tokenExpiration(refreshToken);
                  setCookie("refreshToken", refreshToken, {
                    expires: refreshExp,
                  });

                  storeAccessToken(accessToken);
                }

                // and reset the flag to avoid refreshing too many times
                shouldRefreshToken = false;
              }
              return { Authorization: `Bearer ${getAccessToken()}` };
            },
            on: {
              connected: (socket) => {
                // clear timeout on every connect for debouncing the expiry
                clearTimeout(tokenExpiryTimeout);

                const tokenExpiryInSeconds = getCurrentTokenExpiresIn();

                // console.log("socket connected", {
                //   expiresIn: tokenExpiryInSeconds,
                //   socket,
                // });

                // set a token expiry timeout for closing the socket
                // with an `4403: Forbidden` close event indicating
                // that the token expired. the `closed` event listener below
                // will set the token refresh flag to true
                tokenExpiryTimeout = setTimeout(() => {
                  // console.log("socket token expired");
                  if ((socket as WebSocket).readyState === WebSocket.OPEN)
                    (socket as WebSocket).close(
                      CloseCode.Forbidden,
                      "Forbidden"
                    );
                }, tokenExpiryInSeconds);
              },
              closed: (event) => {
                // console.log("socket closed");
                // if closed with the `4403: Forbidden` close event
                // the client or the server is communicating that the token
                // is no longer valid and should be therefore refreshed
                if ((event as CloseEvent).code === CloseCode.Forbidden)
                  shouldRefreshToken = true;
              },
            },
          })
        )
      : null;

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink =
    typeof window !== "undefined" && wsLink !== null
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query);
            return (
              definition.kind === "OperationDefinition" &&
              definition.operation === "subscription"
            );
          },
          wsLink,
          httpLink
        )
      : httpLink;

  const refreshTokenLink = new TokenRefreshLink({
    accessTokenField: "accessToken",
    isTokenValidOrUndefined: async () => {
      // if (isDev) console.log("=== 0 is valid access token");

      const accessToken = getAccessToken();

      if (!accessToken) return false;

      try {
        const accessExp = tokenExpiration(accessToken);
        if (new Date() >= accessExp) return false;
        return true;
      } catch {
        return false;
      }
    },
    fetchAccessToken: async () => {
      // console.log("=== 1 fetch access token");
      const refreshToken = getCookie("refreshToken");
      if (!refreshToken) return endSession();
      else {
        return await refreshCurrentToken();
      }
    },
    handleFetch: (accessToken) => {
      // if (isDev) console.log("=== 2 handle access token", accessToken);

      storeAccessToken(accessToken);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleResponse: (operation, accessTokenField) => (response: any) => {
      // if (isDev)
      // console.log("=== 3 handle response", {
      //   operation,
      //   accessTokenField,
      //   response,
      // });

      const unauthorizedErrorCode = 401;

      if (
        !response?.data ||
        response?.errors?.[0]?.extensions?.statusCode === unauthorizedErrorCode
      ) {
        return endSession();
      } else {
        const { accessToken, refreshToken } =
          response?.data?.refreshToken || {};
        if (accessToken) {
          const refreshExp = tokenExpiration(refreshToken);
          setCookie("refreshToken", refreshToken, { expires: refreshExp });
          return { accessToken };
        } else {
          return clearAccessToken();
        }
      }
    },
    handleError: (error) => {
      if (isDev) {
        console.warn("Your refresh token is invalid. Try to re-login");
        console.log(error);
      }
      endSession();
    },
  });

  return new ApolloClient({
    link: ApolloLink.from([
      new SentryLink({
        uri: httpUrl,
        shouldHandleOperation: undefined,
        setTransaction: false,
        attachBreadcrumbs: {
          includeQuery: true,
          includeVariables: true,
          includeFetchResult: true,
          includeError: true,
        },
      }),
      ...(disableAuth ? [] : [refreshTokenLink]),
      onError(({ graphQLErrors, networkError }) => {
        if (isDev) {
          // TODO: Custom error handler here.
          if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) =>
              console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
              )
            );
          if (networkError) console.log(`[Network error]: ${networkError}`);
        }
      }),
      authLink,
      splitLink,
    ]),
    cache,
    credentials: "include",
    connectToDevTools,
  });
};

const clearAccessToken = () => {
  store.dispatch(removeAccessToken());
};

const endSession = () => {
  logout(store.dispatch);
};
