export const getEnvironment = () => {
  return import.meta.env || process.env;
};

export const isLocal = getEnvironment().DEV;
export const isDev =
  getEnvironment().NEXT_PUBLIC_SENTRY_ENVIRONMENT === "development" ||
  getEnvironment().NEXT_PUBLIC_SENTRY_ENVIRONMENT === "local";
export const isProduction =
  getEnvironment().NEXT_PUBLIC_SENTRY_ENVIRONMENT === "production";
export const isUAT = getEnvironment().NEXT_PUBLIC_SENTRY_ENVIRONMENT === "uat";

type ConditionalEnvironmentType = {
  local?: any;
  development?: any;
  uat?: any;
  production?: any;
};

export const conditionalEnvironment = ({
  local,
  development,
  uat,
  production,
}: ConditionalEnvironmentType) => {
  if (isLocal && local) {
    return local;
  } else if (isDev && development) {
    return development;
  } else if (isUAT && uat) {
    return uat;
  } else if (isProduction && production) {
    return production;
  } else {
    return;
  }
};
