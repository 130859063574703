import { TabsType } from "src/types";

import { PanelNameType } from "~/utils/accessControl/crudAcl";
import getTabsPathDependent from "~/utils/getTabsPathDependant";

import { labels } from "~/modules/panel-system/cash-out-request-panel/locale";

type TabTitleKeys = keyof typeof labels;

// all the tabs including create and update
const tabs: TabsType<TabTitleKeys> = {
  customerInfo: {
    id: "customerInfo",
    title: "panel.tab.customerInfo",
  },
  requestInfo: {
    id: "requestInfo",
    title: "panel.tab.requestInfo",
  },
};

export const panelConfig: PanelConfigType = {
  id: PanelNameType.CASH_OUT_REQUESTS,
  name: "panel.title",
  createTabs: [tabs.customerInfo, tabs.requestInfo],
  updateTabs: [tabs.customerInfo, tabs.requestInfo],
  settings: {
    maxBulkUpdateCount: 5,
  },
};
export const cashOutActionsTabs = getTabsPathDependent(
  panelConfig.createTabs,
  panelConfig.updateTabs
);
export type PanelConfigType = {
  id: PanelNameType;
  name: string;
  createTabs: (typeof tabs)[keyof typeof tabs][];
  updateTabs: (typeof tabs)[keyof typeof tabs][];
  settings: {
    maxBulkUpdateCount: number;
  };
};
