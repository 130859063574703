import { useRef } from "react";

import { getEnvironment } from "~/utils/environment";
import useInjectScript from "~/utils/hooks/useInjectScript";

import routes from "~/routes/external";

interface GoogleSignInButtonProps {
  setGoogleClientIdHandler: (googleClientId: string | undefined) => void;
}

const googleOauthClientId = getEnvironment().NEXT_PUBLIC_GOOGLE_CLIENT_ID || "";

export default function GoogleSignInButton({
  setGoogleClientIdHandler,
}: GoogleSignInButtonProps): JSX.Element {
  const divRef = useRef<HTMLDivElement>(null);

  const handleGoogleSignInReady = () => {
    window.google?.accounts.id.initialize({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_id: googleOauthClientId,
      callback: (response) => setGoogleClientIdHandler(response.credential),
    });
    window.google?.accounts.id.renderButton(
      divRef.current,
      { theme: "outline", size: "large" } // customization attributes
    );
    window.google?.accounts.id.prompt(); // also display the One Tap dialog
  };

  useInjectScript({
    src: `${routes.googleAccounts}/gsi/client`,
    strategy: "afterInteractive",
    defer: true,
    async: true,
    onReady: handleGoogleSignInReady,
    checkOnReadyInterval: 500, // Check every 500ms. Remove or set to 0 to disable rechecking.
    target: "head", // Load the script in the <head> instead of <body>
  });

  return <div ref={divRef} />;
}
