// declaring the types for our state
import { Country, CountryCode } from "~/gql/main/types.generated";

import { PanelNameType } from "~/modules/panel-system/base-panel/types";

export type PanelConfigType = {
  sortColumns?: {
    field: string;
    sort: "asc" | "desc" | null | undefined;
  }[];
  columnsOrder?: string[];
  widthColumns?: Record<string, number | undefined>;
  pinnedColumns?: {
    left?: string[];
    right?: string[];
  };
  visibleColumns?: Record<string, boolean>;
};

export type PanelFiltersType = {
  filters?: Record<string, any>;
  search?: string;
  filterToggleState?: boolean;
  columnToggleState?: boolean;
  searchToggleState?: boolean;
  searchFocusState?: boolean;
};

export type PanelColumnsType = {
  columns?: any;
  columnToggleState?: boolean;
};

export type Countries = {
  [key in CountryCode]?: Country;
};

export type CountriesData = {
  countries: Countries;
  timestamp: number | undefined;
};

type State = {
  darkTheme: boolean;
  isSideMenuOpen: boolean;
  panelConfig: {
    [key in PanelNameType]?: PanelConfigType;
  };
  panelFilters: {
    [key in PanelNameType]?: PanelFiltersType;
  };
  panelColumns: {
    [key in PanelNameType]?: PanelColumnsType;
  };
  countriesData: CountriesData;
};
const initialState: State = {
  darkTheme: false,
  isSideMenuOpen: true,
  panelConfig: {},
  panelFilters: {},
  panelColumns: {},
  countriesData: {
    countries: {},
    timestamp: undefined,
  },
};
export default initialState;
