import { format, isDate } from "date-fns";
import { InitOptions } from "i18next";

import appConfig from "~/config/appConfig";

import { getEnvironment } from "~/utils/environment";

export const locales = { en: "en", ar: "ar" };

export const fallbackLng = locales.en;
export const languages = [fallbackLng, locales.ar];
// eslint-disable-next-line @typescript-eslint/naming-convention
export const defaultNS = getEnvironment().DEV ? "dev" : "core";
export const cookieName = "i18next";

// eslint-disable-next-line id-length
export const getOptions = (lng = fallbackLng, ns = defaultNS): InitOptions => {
  return {
    // debug: getEnvironment().DEV,
    supportedLngs: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    // eslint-disable-next-line id-length
    ns,
    interpolation: {
      /**
       * React escapes all special characters by design to prevent XSS attacks.
       *
       * @see https://github.com/i18next/i18next/issues/1112#issuecomment-429026798
       */
      escapeValue: false,
      format(value, dateFormat) {
        if (isDate(value)) {
          return format(
            value,
            dateFormat || appConfig.settings.defaultDateFormat
          );
        }
        return value;
      },
    },
  };
};
