export const removeItemStartWith = (
  obj: Record<string, unknown>,
  key: string
) => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((item) => {
    if (item.startsWith(key)) {
      delete newObj[item];
    }
  });
  return newObj;
};

// Helper function to limit the depth of an object (recursive)
export const limitDepth = (obj: any, depth: number) => {
  if (depth <= 0 || typeof obj !== "object") {
    return null;
  }
  const result: any = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      result[key] =
        typeof obj[key] === "object"
          ? limitDepth(obj[key], depth - 1)
          : obj[key];
    }
  }

  return result;
};

// Recursively searches for a key in an object (or array)
// and changes its value to the specified new value. If there is no value specified it removes the key
/* 
  @param obj - The object/array to search
  @param key - The key to search for
  @param value - The new value to set
*/
export const deepChangeValue = function (obj: any, key: string, value?: any) {
  // used arguments to check if there is a value passed to account for the case where the value passed as undefined
  const isValueParamPassed = arguments.length === 3;
  const newObj = Array.isArray(obj) ? [...obj] : { ...obj };
  if (typeof newObj === "object" && newObj !== null) {
    for (const prop in newObj) {
      if (
        typeof newObj[prop] === "object" &&
        newObj[prop] !== null &&
        prop !== key
      ) {
        const newObjProp = isValueParamPassed
          ? deepChangeValue(newObj[prop], key, value)
          : deepChangeValue(newObj[prop], key);
        newObj[prop] = newObjProp;
      } else {
        if (prop === key) {
          isValueParamPassed ? (newObj[key] = value) : delete newObj[prop];
        }
      }
    }
  }
  return newObj;
};
