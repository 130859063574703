// import {
//   getItemLocal,
//   removeItemLocal,
//   setItemLocal,
// } from "~/utils/storageHelpers";

import isObject from "lodash/isObject";

import { PermissionsTabs } from "~/utils/accessControl/userPermissions";

import { store } from "~/state/store";

import { Mode } from "~/types/common";

import {
  getAclPermissionsTabs,
  removeAclPermissionsTabs,
  setAclPermissionsTabs,
} from "~/core/state/coreSlice";

import { PanelNameType } from "~/modules/panel-system/base-panel/types";

export { PanelNameType } from "~/modules/panel-system/base-panel/types";

/** @deprecated  use PermissionsTabs */
export type CreateUpdateTabsType = {
  [key in PanelNameType]?: {
    create?: {
      [key: string]: boolean;
    };
    update?: {
      [key: string]: boolean;
    };
  };
};

class AccessControlCreateUpdateTabs {
  // initial mimic permission from BE
  setPermissions(permissions: PermissionsTabs) {
    if (typeof window === "undefined") return;
    store.dispatch(setAclPermissionsTabs(permissions));
    // return setItemLocal("PermissionsTabs", JSON.stringify(permissions));
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private getPermissions(): PermissionsTabs | undefined {
    // if (process.env.NODE_ENV === "development") {
    //   return this.CreateUpdateTabs;
    // }

    return getAclPermissionsTabs(store.getState());

    // return JSON.parse(getItemLocal("PermissionsTabs") as string);
  }

  removePermission() {
    if (typeof window === "undefined") return;
    store.dispatch(removeAclPermissionsTabs());
    // return removeItemLocal("PermissionsTabs");
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private permissionPanel(panelName: PanelNameType) {
    return this.getPermissions()?.[panelName];
  }

  checkPermission(
    panelName: PanelNameType,
    action: Mode,
    tab: string
  ): boolean {
    const actionPermission = this.permissionPanel(panelName)?.[action];
    return (
      (isObject(actionPermission) ? actionPermission[tab] : actionPermission) ||
      false
    );
  }
}

export default new AccessControlCreateUpdateTabs();
